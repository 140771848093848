import { Grid, Paper, TextareaAutosize } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import utils from '../../../../common/utils';
import * as constants from '../../../../common/constants';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '80%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '30px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginLeft: '60px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  heading: {
    marginTop: '20px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const steps = [
  'Tenant Configuration',
  'RAG Configuration',
  'LLM Configuration',
  'Safety',
  'Configure Prompts',
  'Telemetry Configuration'
];
const TenantTextFields = [
  {
    id: 1,
    label: 'Tenant Name',
    name: 'tenantName',
    readOnly: false
  },
  {
    id: 2,
    label: 'Tenant Key',
    name: 'tenantKey',
    readOnly: true
  },
  {
    id: 3,
    label: 'Tenant Id',
    name: 'tenantId',
    readOnly: true
  }
];
const INITIAL_STATE = {
  tenantId: utils.uuid(),
  tenantKey: utils.uuid(),
  tenantName: '',
  tenantDescription: '',
  keyContacts: '',
  llmProvider: '',
  ragValue: '',
  baseConfigApiEndPoint: [],
  vectorDbValue: '',
  ragApiEndPoint: '',
  contentFilteringEndPoint: 'Azure',
  stopWords: '',
  prompt: [],
  endPointConfig: 'Mixpanel',
  secretManagerKeys: []
};
const TenantConfigureDialog = (props) => {
  const { openDialog, classes, handleClose } = props;
  const [inputValue, setInputValue] = useState(INITIAL_STATE);
  const [showBasePointBox, setShowBasePointBox] = useState(false);
  const [showRAGEndPointBox, setShowRAGEndPointBox] = useState(false);
  const [showRAGBox, setShowRAGBox] = useState(false);
  const [promptsData, setPromptsData] = useState(ColumnConfig.promptConfig);
  const [newFields, setNewFields] = useState([{ baseConfigApiValue: '', configType: '', keyName: '' }]);
  const [ragSecretManagerKeys, setRagSecretManagerKeys] = useState([{ secretManagerKeyName: '' }]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handlePromptChange = (event) => {
    const {
      target: { name, value }
    } = event;
    const role = name.split('_')[1];
    setPromptsData(
      [...promptsData].map((v) => {
        if (v.role === role) {
          return {
            ...v,
            role,
            content: value
          };
        }
        return v;
      })
    );
  };

  const handleAddRagSecretManagerKeys = () => {
    setRagSecretManagerKeys([...ragSecretManagerKeys, { secretManagerKeyName: '' }]);
  };

  const handleDeleteRagSecretManagerKeys = (index) => {
    const newArray = [...ragSecretManagerKeys];
    newArray.splice(index, 1);
    setRagSecretManagerKeys(newArray);
  };

  const handleRagSecretMangerKeysInputChange = (event, index) => {
    const { name, value } = event.target;
    const onChangeValue = [...ragSecretManagerKeys];
    onChangeValue[index][name] = value;
    setRagSecretManagerKeys(onChangeValue);
  };

  const handleAddNewFields = () => {
    setNewFields([...newFields, { baseConfigApiValue: '', configType: '' }]);
  };

  const handleDeleteFields = (index) => {
    const newArray = [...newFields];
    newArray.splice(index, 1);
    setNewFields(newArray);
  };

  const handleBaseConfigInputChange = (event, index) => {
    const { name, value } = event.target;
    const onChangeValue = [...newFields];
    onChangeValue[index][name] = value;
    setNewFields(onChangeValue);
  };

  const renderTenantDetails = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: '25px' }}>
        <Grid container direction="row" style={{ justifyContent: 'center', margin: '10px' }}>
          <Grid item xs={10} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
              {TenantTextFields.map((item, index) => {
                return (
                  <TextField
                    id={index}
                    label={item.label}
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name={item.name}
                    variant="outlined"
                    onChange={handleInputChange}
                    value={
                      /* eslint-disable */
                      item.name === 'tenantKey'
                        ? inputValue.tenantKey
                        : item.name === 'tenantId'
                        ? inputValue.tenantId
                        : inputValue.tenantName
                      /* eslint-disable */
                    }
                    disabled={item.readOnly}
                    required
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={10} direction="row" />
          <Grid item xs={10} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
              <TextField
                name="tenantDescription"
                label="Tenant Description"
                multiline
                rows={4}
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ marginLeft: '0px', marginRight: '10px', marginTop: '30px' }}
                onChange={handleInputChange}
                value={inputValue.tenantDescription}
                required
              />
              <TextField
                name="keyContacts"
                label="Key Contacts"
                multiline
                rows={4}
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ marginLeft: '0px', marginRight: '10px', marginTop: '30px' }}
                onChange={handleInputChange}
                value={inputValue.keyContacts}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={10} direction="row" />
        </Grid>
      </Grid>
    );
  };

  const renderRAGConfigurations = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid container direction="row" style={{ justifyContent: 'center' }}>
          <Grid item xs={10} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'center', p: '0px 32px', margin: '20px' }}>
              <FormControl>
                <FormLabel>Do you require Retrieval Augmented Generate (RAG) based implementation?</FormLabel>
                <RadioGroup name="ragValue" value={inputValue.ragValue} onChange={handleInputChange}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <br />
                {showRAGBox && (
                  <>
                    <FormLabel>Do have you your own Vector Database and Retrieval Endpoints?</FormLabel>
                    <RadioGroup name="vectorDbValue" value={inputValue.vectorDbValue} onChange={handleInputChange}>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    <br />
                    {showRAGEndPointBox && (
                      <>
                        <TextField
                          label="RAG API Endpoint"
                          margin="dense"
                          name="ragApiEndPoint"
                          value={inputValue.ragApiEndPoint}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          {ragSecretManagerKeys.map((field, index) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                              <TextField
                                label={`Secret Manager Key Name ${index + 1}`}
                                margin="dense"
                                name="secretManagerKeyName"
                                value={field.secretManagerKeyName}
                                onChange={(event) => handleRagSecretMangerKeysInputChange(event, index)}
                                variant="outlined"
                                style={{ width: '100%' }}
                              />
                              {index === ragSecretManagerKeys.length - 1 && (
                                <div style={{ display: 'flex' }}>
                                  <TooltipCustom title="Add New Key">
                                    <IconButton onClick={() => handleAddRagSecretManagerKeys()} size="large">
                                      <AddIcon />
                                    </IconButton>
                                  </TooltipCustom>
                                </div>
                              )}
                              {ragSecretManagerKeys.length > 1 && (
                                <div style={{ display: 'flex' }}>
                                  <TooltipCustom title="Delete Key">
                                    <IconButton onClick={() => handleDeleteRagSecretManagerKeys(index)} size="large">
                                      <DeleteIcon />
                                    </IconButton>
                                  </TooltipCustom>
                                </div>
                              )}
                            </div>
                          ))}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderLLMConfigurations = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid container direction="row" style={{ justifyContent: 'center' }}>
          <Grid item xs={10} direction="row">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: '0px 32px',
                marginTop: '10px',
                flexDirection: 'column'
              }}
            >
              <Grid item xs={12} style={{ textAlign: 'center', margin: '10px' }}>
                <FormControl style={{ width: '60%' }}>
                  <InputLabel id="llmProvider">LLM Provider</InputLabel>
                  <Select
                    labelId="llmProvider"
                    name="llmProvider"
                    onChange={handleInputChange}
                    value={inputValue.llmProvider}
                    label="LLM Provider"
                  >
                    <MenuItem value="Azure Open AI">Azure Open AI</MenuItem>
                    <MenuItem value="Gemini">Gemini</MenuItem>
                    <MenuItem value="OpenAI">OpenAI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {showBasePointBox && (
                <>
                  <Grid item xs={12} style={{ textAlign: 'center', margin: '10px' }}>
                    {newFields.map((field, index) => (
                      <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                        <FormControl style={{ width: '20%', display: 'flex', margin: '8px' }}>
                          <InputLabel id="configType">Type</InputLabel>
                          <Select
                            labelId="configType"
                            name="configType"
                            onChange={(event) => handleBaseConfigInputChange(event, index)}
                            value={field.configType}
                            label="Type"
                          >
                            <MenuItem value={constants.SUMMARY}>{constants.SUMMARY}</MenuItem>
                            <MenuItem value={constants.EXPLAIN}>{constants.EXPLAIN}</MenuItem>
                            <MenuItem value={constants.QUIZ}>{constants.QUIZ}</MenuItem>
                            <MenuItem value={constants.PROBLEM_SOLVE_TYPE}>{constants.PROBLEM_SOLVE_TYPE}</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Base Configurations API Endpoint"
                          style={{ minWidth: '50%' }}
                          margin="dense"
                          name="baseConfigApiValue"
                          variant="outlined"
                          value={field.baseConfigApiValue}
                          onChange={(event) => handleBaseConfigInputChange(event, index)}
                          required
                        />
                        <TextField
                          label="Key Name"
                          style={{ minWidth: '20%', margin: '8px' }}
                          margin="dense"
                          name="keyName"
                          variant="outlined"
                          value={field.keyName}
                          onChange={(event) => handleBaseConfigInputChange(event, index)}
                          required
                        />
                        {index === newFields.length - 1 && (
                          <div style={{ display: 'flex' }}>
                            <TooltipCustom title="Add New Field">
                              <IconButton onClick={() => handleAddNewFields()} size="large">
                                <AddIcon />
                              </IconButton>
                            </TooltipCustom>
                          </div>
                        )}
                        {newFields.length > 1 && (
                          <div style={{ display: 'flex' }}>
                            <TooltipCustom title="Delete Field">
                              <IconButton onClick={() => handleDeleteFields(index)} size="large">
                                <DeleteIcon />
                              </IconButton>
                            </TooltipCustom>
                          </div>
                        )}
                      </div>
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSafetyDetails = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid container direction="row" style={{ justifyContent: 'center', margin: '30px' }}>
          <Grid item xs={10} direction="row">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                p: '0px 32px',
                marginTop: '10px',
                flexDirection: 'column'
              }}
            >
              <Grid item xs={12} style={{ textAlign: 'center', margin: '10px' }}>
                <TextField
                  label="Configure Content Filtering endpoint"
                  style={{ width: '80%' }}
                  margin="dense"
                  name="contentFilteringEndPoint"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={inputValue.contentFilteringEndPoint}
                  required
                />
              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'center', margin: '10px' }}>
                <Grid item xs={12} className={classes.labelColumns}>
                  <InputLabel className={classes.subLable}>Stop Words</InputLabel>
                </Grid>
                <Grid item xs={12} className={classes.gridColumns}>
                  <TextareaAutosize
                    className={classes.textAreaSize}
                    name="stopWords"
                    variant="outlined"
                    minRows={6}
                    maxRows={12}
                    onChange={handleInputChange}
                    value={inputValue.stopWords}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderPromptsConfiguration = () => {
    return (
      <Grid container style={{ justifyContent: 'center', margin: '10px' }}>
        <Grid item xs={10}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              p: '0px 32px',
              flexDirection: 'column'
            }}
          >
            {promptsData.map((item, index) => {
              return (
                <Grid item xs={12} style={{ justifyContent: 'center', margin: '10px' }}>
                  <Grid item xs={12} className={classes.labelColumns}>
                    <InputLabel id={index} className={classes.subLable}>
                      Role: {item.role}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} className={classes.gridColumns}>
                    <TextareaAutosize
                      className={classes.textAreaSize}
                      id={index}
                      name={`promptEnd_${item.role}`}
                      variant="outlined"
                      aria-label="maximum height"
                      minRows={6}
                      maxRows={12}
                      onChange={(e) => handlePromptChange(e)}
                      value={item.content}
                      required
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderTelemetryConfiguration = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid container direction="row" style={{ justifyContent: 'center', margin: '30px' }}>
          <Grid item xs={10} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: '0px 32px', marginTop: '10px' }}>
              <TextField
                label="Endpoint configurations"
                style={{ minWidth: '50%' }}
                margin="dense"
                name="endPointConfig"
                variant="outlined"
                onChange={handleInputChange}
                value={inputValue.endPointConfig}
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const defaultSteps = [
    {
      id: 1,
      step: <>{renderTenantDetails()}</>
    },
    {
      id: 2,
      step: <>{renderRAGConfigurations()}</>
    },
    {
      id: 3,
      step: <>{renderLLMConfigurations()}</>
    },
    {
      id: 4,
      step: <>{renderSafetyDetails()}</>
    },
    {
      id: 5,
      step: <>{renderPromptsConfiguration()}</>
    },
    {
      id: 6,
      step: <>{renderTelemetryConfiguration()}</>
    }
  ];

  useEffect(() => {
    if (!_.isEmpty(inputValue.llmProvider)) {
      setShowBasePointBox(true);
    }
    if (inputValue.ragValue === 'yes') {
      setShowRAGBox(true);
    }
    if (inputValue.ragValue === 'no') {
      setShowRAGBox(false);
    }
    if (inputValue.vectorDbValue === 'no') {
      setShowRAGEndPointBox(false);
      setInputValue({ ...inputValue, ragApiEndPoint: '' });
      setRagSecretManagerKeys([{ secretManagerKeyName: '' }]);
    }
    if (inputValue.vectorDbValue === 'yes') {
      setShowRAGEndPointBox(true);
    }
  }, [inputValue.llmProvider, inputValue.ragValue, inputValue.vectorDbValue]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (activeStep === steps.length - 1) {
      setInputValue({
        ...inputValue,
        baseConfigApiEndPoint: [...newFields],
        prompt: [...promptsData],
        secretManagerKeys: [...ragSecretManagerKeys]
      });
      handleClose();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setInputValue(INITIAL_STATE);
  };

  return (
    <>
      <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
        <div className={classes.flexBorder}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ flexDirection: 'column' }}>
              <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' }}>
                <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                  <Tooltip title="Close">
                    <CloseIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </Grid>
          </Box>
          <Box>
            <Grid item xs={16}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                Configure New Tenant
              </Typography>
            </Grid>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button variant="contained" size="small" onClick={handleReset}>
                    Reset
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Paper style={{ margin: '10px' }}>
                  <Box sx={{ width: '100%' }}>
                    {defaultSteps &&
                      defaultSteps.filter((item) => item.id === activeStep + 1).map((item) => <>{item.step}</>)}
                  </Box>
                </Paper>
                <div className={classes.flexContainerBottom}>
                  <div className={classes.ButtonClass}>
                    <Stack direction="row" spacing={2}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          variant="contained"
                          size="small"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                          <Button variant="contained" size="small" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                          </Button>
                        )}

                        <Button variant="contained" size="small" onClick={handleNext}>
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                      </Box>
                    </Stack>
                  </div>
                </div>
              </>
            )}
          </Box>
          <br />
        </div>
      </Dialog>
    </>
  );
};

TenantConfigureDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(TenantConfigureDialog);

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const trendingTopicsData = types.model('trendingTopicsData', {
  userInput: types.maybeNull(types.string)
});

const TrendingTopics = types
  .model('TrendingTopics', {
    data: types.optional(types.array(trendingTopicsData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_TRENDING_TOPICS_MODEL_DATA, {
        payload
      });
    },
    setResponse(response) {
      const { data } = response;
      applySnapshot(self, { data });
      Framework.getEventManager().publish(constants.SET_TRENDING_TOPICS_MODEL_DATA);
    }
  }));

export default TrendingTopics;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Framework, { shapes, LoadingHandler } from '@greenville/framework';
import { styled } from '@mui/material/styles';
import {
  Radio,
  Paper,
  Box,
  RadioGroup,
  FormControlLabel,
  FormControl,
  withStyles,
  Typography
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Loader from '../../../../../common/Loader';
import ColumnConfig from '../../../config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import McqSearch from '../../../models/McqSearch';
import ButtonComponent from '../../../common/components/ButtonComponent';
import { getDarkTheme } from '../../../ChatGptTheme';
import CustomTable from '../../../common/components/CustomTable';

const commonTheme = getDarkTheme();

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: commonTheme.palette.primary.main
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #333333'
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: commonTheme.palette.primary.main
    }
  }
});

const styles = () => ({
  tableCustomWidth: {
    width: '2px'
  },
  tableDateWidth: {
    width: '190px'
  },
  tableBookWidth: {
    width: '25%'
  },
  tableTitleWidth: {
    width: '25%'
  },
  tableFeedWidth: {
    width: '140px'
  },
  tableReasonWidth: {
    width: '200px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableTypeWidth: {
    width: '25%'
  },
  tableUserWidth: {
    width: '160px'
  },
  tableUserIdWidth: {
    width: '25%'
  },
  tableFilterDateWidth: {
    width: '190px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterFeedWidth: {
    width: '150px'
  },
  tableFilterReasonWidth: {
    width: '180px'
  },
  tableFilterTooltipWidth: {
    width: '100px'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  tableTypeText: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  radioButton: {
    textAlign: 'center'
  },
  root: {
    width: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  tableTextAlongWidth: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: 160
  },
  columnWidth: {
    width: 200
  },
  contentText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  headingClass: {
    padding: '15px',
    display: 'flex'
  },
  searchBarClass: {
    width: '25vw'
  },
  searchHeaders: {
    paddingLeft: '10vw'
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  }
});

function TitleMcqSearch(props) {
  const { classes, mcqSearch, BookSearchService } = props;
  const [rows, setRows] = useState([]);
  const [viewType, setViewType] = useState('mcq');
  const [searchField, setSearchField] = useState('');
  const handleSearch = () => {
    const payload = searchField ? { searchField } : '';
    mcqSearch.fetch(payload);
  };
  useEffect(() => {
    Framework.getEventManager().on(constants.SET_MCQ_SEARCH_DATA, () => {
      const { data } = mcqSearch;
      if (data.length > 0) {
        setRows(data);
      }
    });
  }, []);

  const handleOnclick = (row) => {
    const { bookId } = row;
    Framework.redirectTo(`/aiadmin/aistudytools/${viewType}/view/${bookId}`);
  };

  const handleChange = (event) => {
    setViewType(event.target.value);
  };

  const getColumnConfig = () => {
    return ColumnConfig.AIStudyContentColumnNew();
  };

  return (
    <>
      {/* <Paper style={{ overflow: 'hidden' }}> */}
      <Paper style={{ backgroundColor: '#f8f6fa', border: '2px solid #333', overflow: 'hidden' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px',
            gap: '10px',
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Box>
            {/* <Typography className={classes.headingClass}>Title Search</Typography> */}
            <CssTextField
              label="Title Name, Author, BookId"
              variant="outlined"
              onChange={(e) => setSearchField(e.target.value)}
              className={classes.searchBarClass}
              value={searchField}
              inputProps={{
                style: {
                  padding: '8px',
                  color: 'black'
                }
              }}
              InputLabelProps={{
                style: {
                  top: '-7px',
                  fontSize: '14px'
                }
              }}
              margin="dense"
            />
          </Box>
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
            <ThemeProvider theme={themes}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="mcq"
                    name="radio-buttons-group"
                    value={viewType}
                    onChange={handleChange}
                    defaultChecked
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControlLabel
                      value="mcq"
                      control={
                        <Radio
                          color="primary"
                          style={{ color: commonTheme.palette.background.darkGray }}
                          inputProps={{ '&:hover': { backgrounColor: 'yellow' } }}
                        />
                      }
                      label="Quiz"
                    />
                    <FormControlLabel
                      value="summary"
                      control={<Radio color="primary" style={{ color: commonTheme.palette.background.darkGray }} />}
                      label="Summary"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <ButtonComponent classes={classes} handleClick={handleSearch} />
              </Box>
            </ThemeProvider>
          </Box>
        </Box>
      </Paper>

      {/* </Paper> */}
      <br />
      <LoadingHandler
        loading={BookSearchService.isPending}
        loadingContent={<Loader loaderStatusResponse loaderStatus />}
        content={
          rows && rows.length > 0 ? (
            <CustomTable columns={getColumnConfig()} data={rows} onRowClick={handleOnclick} enableRoundedHeader />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                mt: 2
              }}
            >
              <Typography variant="h5" color="textSecondary">
                Please search to fetch data...
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
}

TitleMcqSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  mcqSearch: shapes.modelOf(McqSearch).isRequired,
  BookSearchService: shapes.state.isRequired
};

export default withStyles(styles)(inject('mcqSearch', 'BookSearchService')(observer(TitleMcqSearch)));

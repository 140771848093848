import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

function CustomButton({ label, onClick, color, variant, toolTiptext, disabled, style }) {
  return (
    <Button style={style} onClick={onClick} color={color} variant={variant} title={toolTiptext} disabled={disabled}>
      {label}
    </Button>
  );
}

// PropType validation
CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'inherit']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  toolTiptext: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

// Default props
CustomButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  disabled: false,
  style: {}
};

export default CustomButton;

import { FormControlLabel, FormGroup, Grid, Paper, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../../common/constants';
import utils from '../../../../common/utils';
import MathJaxWrapper from '../../common/components/MathJaxWrapper';

const styles = () => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  headingItems: {
    border: '1px solid grey',
    padding: '10px'
  },
  aiStudyToolcard: {
    height: '475px',
    overflowY: 'auto !important'
  },
  promptCard: {
    overflowY: 'auto !important',
    maxHeight: '235px',
    minHeight: '235px'
  },
  inputCard: {
    overflowY: 'auto',
    minHeight: '145px',
    maxHeight: '145px'
  },
  minimizedCard: {
    overflowY: 'auto !important',
    height: '150px'
  }
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

// /* eslint-disable */
const EffectivenessDialogComponent = (props) => {
  const { openChat, handleClose, dataValue, modalIndex, dataLength, handleModalChange, classes } = props;

  const [checked, setChecked] = useState({
    aiStudyTools: false
  });
  const [aiStudyRawResponse, setAIStudyRawResponse] = useState('');

  useEffect(() => {
    if (!_.isEmpty(dataValue.output)) {
      if (checked.aiStudyTools) {
        setAIStudyRawResponse(JSON.stringify(dataValue.output, null, 2).slice(1, -1));
      }
    }
  }, [checked]);

  const highlightRow = (item, key) => {
    let styleVal = { bgColor: 'transparent', textColor: '#6a7070' };
    if (item !== null) {
      if (item < 3 || (key === constants.F1_SCORE_NAME && item !== 1)) {
        styleVal = { bgColor: '#FF0000', textColor: 'black' };
      }
      if (item >= 3 && item < 5) {
        styleVal = { bgColor: ' #FFA500', textColor: 'black' };
      }
      if (item === 5 || (key === constants.F1_SCORE_NAME && item === 1)) {
        styleVal = { bgColor: '#5fb65f', textColor: 'black' };
      }
    }
    return styleVal;
  };

  const returnFeedbackData = (data) => {
    const feedbackTags = data.map((item) => {
      const filterValue = Object.fromEntries(
        Object.entries(item).filter(([key, value]) => value !== null && key !== null)
      );
      return Object.keys(filterValue).map((key) => {
        const backGroundStyle =
          typeof filterValue[key] === 'number' ? { backgroundColor: highlightRow(filterValue[key], key).bgColor } : {};
        return (
          <div style={{ width: '10%' }}>
            <div
              style={{
                fontWeight: 'bold',
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'center',
                paddingBottom: '10px'
              }}
            >
              {key !== constants.F1_SCORE_NAME && `${key.includes('_') ? key.split('_')[1] : key} `}
              {key === constants.F1_SCORE_NAME && `${key.split('_').join(' ')} `}
            </div>

            <div
              style={{
                ...backGroundStyle,
                textAlign: 'center',
                height: '40px',
                lineHeight: '35px',
                border: '1px solid #f6f6f6'
              }}
            >
              <div>
                {typeof filterValue[key] === 'number' ? (
                  `  ${filterValue[key]}`
                ) : (
                  /* eslint-disable */
                  <div dangerouslySetInnerHTML={{ __html: filterValue[key] }} />
                  /* eslint-disable */
                )}
              </div>
            </div>
          </div>
        );
      });
    });
    return feedbackTags;
  };

  const parseAttributes = (attributes) => {
    const attrs = [];
    const regex = /(\w+)="([^"]+)"/g;
    let match;
    // eslint-disable-next-line
    while ((match = regex.exec(attributes)) !== null) {
      attrs.push(` ${match[1]}="${match[2]}"`);
    }
    return attrs.join('');
  };

  const renderLinksVideos = (text) => {
    const linkRegex = /<a href="([^"]+)"([^>]*)>(.*?)<\/a>/g;
    const videoTagRegex = /<video([^>]+)>/g;
    const imgTagRegex = /<img[^>]*>/g;
    const iframeRegex = /<iframe[^>]*>/g;
    let finalRespStr = text;
    if (linkRegex.test(finalRespStr)) {
      finalRespStr = finalRespStr?.replace(linkRegex, (match, url, attributes, linkText) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer"${parseAttributes(
          attributes
        )}>${linkText}</a>`;
      });
    }

    if (imgTagRegex.test(finalRespStr)) {
      finalRespStr = finalRespStr?.replace(imgTagRegex, (imgTag) => {
        if (!imgTag.includes('width=')) {
          return imgTag?.replace('<img', '<img style="width: 100%;"');
        }
        return imgTag;
      });
    }

    if (iframeRegex.test(finalRespStr)) {
      finalRespStr = finalRespStr?.replace(iframeRegex, (iframeTag) => {
        if (!iframeTag.includes('width=')) {
          return iframeTag?.replace(
            '<iframe',
            '<iframe allowfullscreen style="width: 100%; height: 500px; border: 1px solid;"'
          );
        }
        return iframeTag;
      });
    }

    if (videoTagRegex.test(finalRespStr)) {
      return finalRespStr?.replace(videoTagRegex, (match) => {
        const hasStyle = /style="[^"]+"/.test(match);
        const hasControls = /controls/.test(match);
        let enhancedTag = match;
        if (!hasStyle) {
          enhancedTag = enhancedTag?.replace('<video', '<video style="width: 100%;"');
        }
        if (!hasControls) {
          enhancedTag = enhancedTag?.replace('<video', '<video controls');
        }
        return enhancedTag;
      });
    }
    return finalRespStr;
  };

  const renderMathJaxResponse = (response) => {
    const regesXmlns = /<math([^>]*)>/g;
    const res = utils.containsMathML(response)
      ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
      : response;

    return (
      <MathJaxWrapper>
        {/* eslint-disable */}
        <span dangerouslySetInnerHTML={{ __html: renderLinksVideos(res) }} />
        {/* eslint-disable */}
      </MathJaxWrapper>
    );
  };

  const checkMathJax = (mathJaxData) => {
    let response = mathJaxData;
    if (!_.isEmpty(mathJaxData)) {
      if (utils.containsMarkDownText(mathJaxData)) {
        response = utils.convertToMarkedDownString(mathJaxData);
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\'))
        return renderMathJaxResponse(response);
    }

    return (
      <>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: renderLinksVideos(response) }} />
      </>
    );
  };

  const handleNavigationChange = (nav) => {
    handleModalChange(nav);
    setChecked({ ...checked, aiStudyTools: false });
  };

  const handleToggleCheck = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const renderRawResponse = (paramVal) => {
    if (paramVal === constants.AI_STUDY_TOOLS_RESPONSE) {
      return (
        <FormGroup display="inline" spacing={0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControlLabel
            control={<Switch name="aiStudyTools" checked={checked.aiStudyTools} onChange={handleToggleCheck} />}
            label="Raw Response"
            style={{ alignItems: 'center', padding: '0px' }}
          />
        </FormGroup>
      );
    }
    return <></>;
  };

  return (
    <>
      <Dialog fullScreen open={openChat} onClose={handleClose}>
        <Box sx={{ p: 2 }}>
          <Paper elevation={0}>
            <Grid container spacing={0} padding={0}>
              <Grid item xs={11}>
                <Stack
                  display="inline"
                  spacing={0}
                  justifyContent="center"
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Button disabled={modalIndex === 0} onClick={() => handleNavigationChange('prev')} size="large">
                    <ChevronLeftIcon />
                  </Button>
                  <span color="secondary">{modalIndex + 1}</span> /<span color="secondary"> {dataLength}</span>
                  <Button disabled={modalIndex + 1 === dataLength} onClick={() => handleNavigationChange('next')}>
                    <ChevronRightIcon />
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Typography align="right" variant="subtitle1">
                  <Box display="inline" sx={{ pl: 4 }}>
                    <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                      <Tooltip title="Close">
                        <CloseIcon />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </Typography>
              </Grid>
              {dataValue && dataValue.feedbacks && (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  {returnFeedbackData(dataValue.feedbacks)}
                </div>
              )}
            </Grid>
            <br />
            {dataValue && (
              <Grid container spacing={2} style={{ minWidth: 800 }}>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <Item>
                      <Card style={{ overflowY: 'auto', minHeight: '210px', maxHeight: '210px' }}>
                        <CardHeader title="User Input/Command" style={{ background: '#34282808' }} align="left" />
                        <CardContent style={{ overflowY: 'auto' }}>
                          <Typography sx={{ textAlign: 'left' }} variant="body1">
                            {dataValue.input}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Item>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <Item>
                      <Card style={{ overflowY: 'auto', minHeight: '210px', maxHeight: '210px' }}>
                        <CardHeader
                          title={constants.CHAT_PROMPT_CONTENT}
                          style={{ background: '#34282808' }}
                          align="left"
                        />
                        <CardContent style={{ overflowY: 'auto' }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: 'left' }}
                            dangerouslySetInnerHTML={{ __html: dataValue.prompt }}
                          />
                        </CardContent>
                      </Card>
                    </Item>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Card className={classes.aiStudyToolcard}>
                      <CardHeader
                        style={{ background: '#34282808' }}
                        title="AI Study Tool Response"
                        align="left"
                        action={renderRawResponse(constants.AI_STUDY_TOOLS_RESPONSE)}
                      />
                      {!checked.aiStudyTools ? (
                        <CardContent>
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {checkMathJax(dataValue?.output)}
                          </Typography>
                        </CardContent>
                      ) : (
                        <CardContent>
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {aiStudyRawResponse}
                          </Typography>
                        </CardContent>
                      )}
                    </Card>
                  </Item>
                </Grid>
                <br />
              </Grid>
            )}
          </Paper>
        </Box>
      </Dialog>
    </>
  );
};

EffectivenessDialogComponent.defaultProps = {
  modalIndex: '',
  dataValue: {},
  dataLength: 0
};

EffectivenessDialogComponent.propTypes = {
  openChat: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataValue: PropTypes.object,
  dataLength: PropTypes.number,
  modalIndex: PropTypes.string,
  handleModalChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(observer(EffectivenessDialogComponent));

import { makeStyles } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import AllAssessments from './AllAssessments';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: '80%' }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles({
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  },
  tabTitle: {
    textTransform: 'capitalize !important',
    minHeight: '55px !important'
  }
});

const PVSAssesment = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" align="center" gutterBottom spacing={9} style={{ p: 5, mt: 5 }}>
          Customization Manager
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 600 }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            className={classes.tabTitle}
            icon={<FolderIcon />}
            iconPosition="start"
            label="All Assessments"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tabTitle}
            icon={<AssessmentIcon />}
            iconPosition="start"
            label="My Assessments"
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tabTitle}
            icon={<FolderSharedIcon />}
            iconPosition="start"
            label="Shared With Me"
            {...a11yProps(2)}
          />
          <Tab
            className={classes.tabTitle}
            icon={<FileCopyIcon />}
            iconPosition="start"
            label="Duplication Status"
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AllAssessments />
        </TabPanel>
        <TabPanel value={value} index={1}>
          My Assessments
        </TabPanel>
        <TabPanel value={value} index={2}>
          Shared With Me
        </TabPanel>
        <TabPanel value={value} index={3}>
          Duplication Status
        </TabPanel>
      </Box>
    </>
  );
};

// PVSAssesment.propTypes = {};

export default PVSAssesment;

import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import * as constants from '../../../../common/constants';
import { AIADMIN_PRIMARY_COLOR } from '../../ChatGptTheme';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: AIADMIN_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: AIADMIN_PRIMARY_COLOR
      },
      current: {
        color: AIADMIN_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: AIADMIN_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: AIADMIN_PRIMARY_COLOR
        }
      },
      current: {
        color: AIADMIN_PRIMARY_COLOR
      }
    }
  }
});

function CustomDatePicker({ handleDateChange, value, label, dataKey, disabled }) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          format={constants.MM_DD_YYYY_FORMAT}
          autoOk
          size="small"
          variant="inline"
          style={{ minWidth: '32%', marginTop: 'inherit' }}
          inputVariant="outlined"
          disableFuture
          value={value}
          label={label}
          onChange={(date) => handleDateChange(date, dataKey)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          disabled={disabled}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

CustomDatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};
CustomDatePicker.defaultProps = {
  disabled: false
};

export default CustomDatePicker;

import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import utils from '../../../../common/utils';
import { WorkFlowDetails } from './ChatBotData';
import ChatBotDialog from './ChatBotDialog';

const styles = () => ({
  mainDiv: {
    display: 'flex'
  },
  subDiv: {
    margin: '8px',
    width: '23%',
    minHeight: '30vh',
    overflow: 'auto',
    height: '70vh'
  },
  subSecondDiv: {
    margin: '8px',
    width: '75%',
    minHeight: '40vh',
    border: '1px solid',
    padding: '15px 15px'
  },
  itemList: {
    minHeight: '100px',
    display: 'flex',
    flexWrap: 'wrap',
    background: '#f1f0f0',
    minWidth: '205px',
    borderRadius: '5px',
    padding: '12px 15px',
    marginRight: '45px'
  },
  title: {
    color: '#000000', // '#10957d',
    background: 'rgba(16, 149, 125, 0.15)',
    padding: '5px 10px',
    borderRadius: '5px',
    alignSelf: 'flex-start',
    width: '100%'
  },
  secondaryDiv: {
    display: 'flex',
    border: '1px solid',
    width: '100%',
    height: '20vh'
  },
  contentDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  workflowTitles: {
    width: '60%',
    border: '1px solid #c7c5c5',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    boxShadow: '5px 5px #e5e5e5'
  },
  titledesc: {
    display: 'flex',
    border: '1px solid #cae6ef',
    width: '70%',
    justifyContent: 'center',
    margin: '10px',
    height: 'auto',
    borderRadius: '10px 1px',
    wordBreak: 'break-word',
    color: '#10957d',
    padding: '5px',
    fontSize: '14px',
    background: 'rgba(16, 149, 125, 0.15)'
  },
  messageBox: {
    display: 'flex',
    fontWeight: '600',
    width: '100%',
    justifyContent: 'center',
    borderBottom: '1px solid #c7c5c5',
    alignItems: 'center',
    height: '35px'
  },
  addDetails: {
    display: 'flex',
    height: '50px',
    width: '45%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    color: '#858383',
    margin: '10px',
    border: '1px dashed grey',
    cursor: 'pointer'
  },
  emptyFlow: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #c1bdbd',
    height: '100px',
    color: '#7b7878'
  }
});

const ChatBotConfig = (props) => {
  const { classes } = props;
  const [columns, setColumns] = useState({});
  const [taskDetails, setTaskDetails] = useState({});
  const [contentBox, setContentBox] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);

  useEffect(() => {
    setColumns(WorkFlowDetails);
  }, []);

  const onSelectChip = (task, columnId) => {
    setSelectedChips((prevSelected) => [
      ...prevSelected,
      { id: utils.uuid(), task, column: columnId, description: '' }
    ]);
  };

  const handleOpen = (item) => {
    setTaskDetails(item);
    setContentBox(true);
    setDescription('');
  };

  const handleRemove = (e, item) => {
    setSelectedChips((prevSelected) => prevSelected.filter((val) => val.id !== item.id));
    e.stopPropagation();
  };

  const handleClose = () => {
    setContentBox(false);
  };

  const saveDescription = () => {
    const updatedItems = selectedChips.map((item) => (item.id === taskDetails.id ? { ...item, description } : item));
    setSelectedChips(updatedItems);
    handleClose();
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <>
      <Box>
        <Typography variant="h5" align="center" gutterBottom spacing={9}>
          AI ChatBot Builder - Workflow (By Tenant)
        </Typography>
      </Box>
      <div className={classes.mainDiv}>
        <div className={classes.subDiv}>
          {Object.entries(columns).map(([columnId, column]) => {
            return (
              <>
                <div className={classes.itemList}>
                  {/* eslint-disable  */}
                  <div
                    className={classes.title}
                    style={{
                      background:
                        column.title === 'Integration Library'
                          ? '#fffdbc'
                          : column.title === 'Building Blocks'
                          ? '#c4ebf8'
                          : '#fae1e1'
                    }}
                  >
                    {column.title}
                  </div>
                  {/* eslint-disable  */}
                  {column.items.map((item, index) => (
                    <Stack
                      direction={item.title === 'Building Blocks' ? 'column' : 'row'}
                      spacing={1}
                      style={{ padding: '5px', height: '28px' }}
                    >
                      <Chip
                        style={{ height: '25px' }}
                        key={index}
                        label={item.task}
                        onClick={() => onSelectChip(item.task, columnId)}
                        // icon={selectedChips.some((val) => val.id === item.id) ? <CheckCircleIcon /> : <CircleIcon />}
                        // color={selectedChips.some((val) => val.id === item.id) ? 'primary' : 'default'}
                        variant="outlined"
                      />
                    </Stack>
                  ))}
                </div>
              </>
            );
          })}
        </div>

        <div className={classes.subSecondDiv}>
          <div className={classes.contentDiv}>
            {selectedChips.length > 0 &&
              selectedChips.map((item, index) => {
                return (
                  <>
                    <div className={classes.workflowTitles}>
                      <div className={classes.messageBox}>
                        {item.task}
                        <span>
                          <IconButton onClick={(e) => handleRemove(e, item)}>
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </span>
                      </div>
                      {/* eslint-disable  */}
                      {item.description !== '' ? (
                        <div className={classes.titledesc} onClick={() => handleOpen(item)}>
                          {item.description}
                        </div>
                      ) : (
                        <div className={classes.addDetails} onClick={() => handleOpen(item)}>
                          Click Here to Add Details...
                        </div>
                      )}
                      {/* eslint-disable  */}
                    </div>
                    {index < selectedChips.length - 1 ? (
                      <>
                        <hr width="1" size="20" style={{ margin: '0 auto' }} />
                        <div style={{ margin: '-12px' }}>
                          <KeyboardArrowDownIcon />
                        </div>
                        <hr width="1" size="20" style={{ margin: '0 auto' }} />
                      </>
                    ) : (
                      <>
                        <hr width="1" size="20" style={{ margin: '0 auto' }} />
                        <div style={{ margin: '-12px' }}>
                          <KeyboardArrowDownIcon />
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            {selectedChips.length === 0 && (
              <div className={classes.emptyFlow}>
                <KeyboardBackspaceIcon fontSize="large" />
                Click on the chips to create the workflow
              </div>
            )}
          </div>
        </div>
      </div>
      <ChatBotDialog
        open={contentBox}
        onClose={handleClose}
        taskDetails={taskDetails}
        saveDescription={saveDescription}
        description={description}
        handleChange={handleChange}
      />
    </>
  );
};

ChatBotConfig.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChatBotConfig);

import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Box, Card, Typography } from '@material-ui/core';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import CustomLoader from '../../common/components/CustomLoader';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import CommonSearchModel from '../../models/CommonSearchModel';
import McqSearch from '../../models/McqSearch';
import DynamicTrendsComponent from './DynamicTrendsComponent';
import DynamicDataGrid from './dataGrid/DynamicDataGrid';
import './dynamicComponent.css';
import { getDarkTheme } from '../../ChatGptTheme';
import ObserveSearchComponent from './ObserveSearchComponent';

const styles = () => ({
  datePicker: {
    // width: '49%',
    marginTop: 'inherit'
  }
});

@inject('commonSearchUtility', 'commonSearchUtilityStatus', 'mcqSearch')
@observer
class DynamicComponent extends React.PureComponent {
  static propTypes = {
    // classes: PropTypes.object.isRequired,
    commonSearchUtility: shapes.modelOf(CommonSearchModel).isRequired,
    commonSearchUtilityStatus: shapes.state.isRequired,
    mcqSearch: shapes.modelOf(McqSearch).isRequired,
    history: PropTypes.object.isRequired,
    selectedTabValue: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    const { commonSearchUtility } = props;
    this.defaultSearch = {
      bookId: '',
      userId: '',
      email: '',
      startDateTime: '',
      endDateTime: '',
      type: '',
      startDate: '',
      endDate: ''
    };
    this.state = {
      newRequest: true,
      searchFields: {},
      searchedDataResponse: commonSearchUtility,
      endDisabled: true,
      // loaderStatus: true,
      isbookIdRequired: false,
      bookList: [],
      inputValue: '',
      tokenValue: '',
      tenantId: '',
      tenantName: '',
      tenantTitle: '',
      showDateFields: false,
      showOutline: '1D',
      clusterState: 'cluster'
    };
  }

  componentDidMount = () => {
    const { commonSearchUtility } = this.props;
    this.setTenantDetails();
    // if (mcqSearch && mcqSearch.data.length > 0) {
    //   this.setState({
    //     bookList: mcqSearch.data
    //   });
    // }
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.GET_COMMON_SEARCH_UTILITY_RESPONSE, () => {
      const { selectedTabValue } = this.props;
      const { newRequest, searchedDataResponse, bookList, tenantName } = this.state;
      const { data } = commonSearchUtility;
      // this.setState({
      //   listData: listData.push(JSON.stringify(toJS(commonSearchUtility.data)))
      // });
      if (commonSearchUtility) {
        let combineData = [];
        if (selectedTabValue === 1) {
          const { summary, quiz, discuss } = commonSearchUtility;
          const summaryData = summary && summary.length > 0 ? summary : [];
          const quizData = quiz && quiz.length > 0 && quiz ? quiz : [];
          const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
          combineData = [...summaryData, ...quizData, ...discussData];
        } else {
          combineData = [...data];
        }
        bookList.forEach((item) => {
          const alteredValue = item.bookId;
          if (!alteredValue) return;
          combineData.forEach((dataValue, index) => {
            if (dataValue.bookId === alteredValue) {
              combineData[index].title = item.title;
            }
          });
        });
        let finalData;
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        // TODO: Below condition for POC Only
        if (selectedTabValue === 0 || selectedTabValue === 1) {
          if (tenantName === 'pvs') {
            finalData = combineDatatoJS?.filter(
              (item) =>
                ['pvs-c9bc464d-bf64-44b7-9067-84644db44eea', 'pvs-22706eae-864a-4467-9df9-f803ff8170c4'].includes(
                  item.bookId
                ) && ['summary', 'answer', 'quiz_mcq'].includes(item.type)
            );
          } else if (tenantName === 'servicenow') {
            finalData = combineDatatoJS?.filter(
              (item) => ['cf426e7b1bd209508f22337f034bcbe8'].includes(item.bookId) && ['answer'].includes(item.type)
            );
          } else if (tenantName === 'aistudytools') {
            finalData = [...combineDatatoJS];
          } else {
            finalData = combineDatatoJS?.filter(
              (item) =>
                ['rplus-60ff996c0e429e21ee07cce2'].includes(item.bookId) &&
                ['summary', 'answer', 'quiz_mcq'].includes(item.type)
            );
          }
        } else {
          finalData = combineDatatoJS;
        }
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = finalData.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState({ searchedDataResponse: sortedDate });
        } else {
          const combineDataGpt = [...searchedDataResponse, ...finalData];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState(() => ({ searchedDataResponse: sortedDate }));
        }
      }
    });
    /* eslint-enable camelcase */
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mcqSearch, selectedTabValue } = this.props;
    const { tokenValue } = this.state;
    if (prevState.tokenValue !== tokenValue && mcqSearch && mcqSearch.data.length === 0) {
      this.getBookData();
    }
    if (prevProps.selectedTabValue !== selectedTabValue) {
      this.handleReset();
    }
  };

  setTenantDetails = () => {
    const { history } = this.props;
    const selectedTabName = history.location.pathname.split('/');
    ChatgptConfig.tenantData.forEach((item) => {
      if (item.name === selectedTabName[2]) {
        this.setState({
          tenantId: item.tenantId,
          tenantName: item.name,
          bookList: item.titles,
          tenantTitle: item.title
        });
      }
    });
  };

  getBookData = async () => {
    const { tokenValue, tenantName } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('mcqSearch').setResponse(response.data);
      if (tenantName === 'aistudytools') {
        this.setState({
          bookList: response.data.data
        });
      }
    }
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged },
      isbookIdRequired: false
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const { selectedTabValue } = this.props;
    const fieldChanged = {};
    fieldChanged[name] =
      selectedTabValue === 2
        ? moment(date).format(constants.YYYY_MM_DD_FORMAT)
        : moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDateTime || payload.startDate) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleReset = () => {
    const { commonSearchUtility } = this.props;
    this.setState({
      endDisabled: true,
      isbookIdRequired: false,
      searchFields: {
        ...this.defaultSearch
      },
      inputValue: '',
      showOutline: '1D',
      clusterState: 'cluster',
      searchedDataResponse: []
    });
    commonSearchUtility.resetStoreValues();
  };

  handleSearch = (customPayload) => {
    const { searchFields, tenantId, tenantName, clusterState } = this.state;
    const { commonSearchUtility, selectedTabValue } = this.props;
    let payload = '';

    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }
    if (tenantName === 'aistudytools') {
      const type = searchFields?.type ? searchFields?.type : 'summary';
      const startDateTime = searchFields?.startDateTime ? searchFields.startDateTime : '';
      const endDateTime = searchFields?.endDateTime ? searchFields.endDateTime : '';
      const userId = searchFields?.userId ? searchFields.userId : '';
      const email = searchFields?.email ? searchFields.email : '';
      const bookId = searchFields?.bookId ? searchFields.bookId : '';
      payload = { startDateTime, endDateTime, type, email, userId, bookId, selectedTabValue };
    } else {
      payload = { ...searchFields, ...customPayload };
      if (selectedTabValue === 2) {
        let endDateChanged = new Date();
        endDateChanged = moment(endDateChanged).format(constants.YYYY_MM_DD_FORMAT);

        if ((payload && payload.startDate) || (payload && payload.bookId)) {
          if (!payload.endDate && payload.startDate) {
            payload.endDate = endDateChanged;
            this.setState({
              searchFields: { ...searchFields, endDate: endDateChanged }
            });
          }
        } else {
          payload.endDate = endDateChanged;
          payload.startDate = endDateChanged;
          this.setState({
            searchFields: { ...searchFields, startDate: endDateChanged, endDate: endDateChanged }
          });
        }
      }
      if (
        payload &&
        (payload.bookId || payload.email || payload.userId || payload.type || payload.startDate || payload.endDate)
      ) {
        payload.tenantId = tenantId;
        payload.tenantName = tenantName;
        payload.selectedTabValue = selectedTabValue;
        if (selectedTabValue === 2) payload.clusterState = clusterState;
      }
    }
    // TODO: Below condition for POC Only
    // if (selectedTabValue === 0 || selectedTabValue === 1) { commented as of now
    //   payload.startDateTime = '06/01/2024';
    // }

    if (payload) {
      commonSearchUtility.fetch(payload);
    } else {
      this.setState({ isbookIdRequired: true });
    }
  };

  handleAscending = (dir, value) => {
    const { searchedDataResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...searchedDataResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...searchedDataResponse,
        searchedDataResponse: sorted
      });
    } else {
      const sorted = [...searchedDataResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...searchedDataResponse,
        searchedDataResponse: sorted
      });
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        bookId: newValue !== null ? newValue.bookId : ''
      },
      isbookIdRequired: false
    });
  };

  handleSelectChange = (e) => {
    const { commonSearchUtility } = this.props;
    this.setState({
      clusterState: e.target.value
    });
    commonSearchUtility.resetStoreValues();
  };

  handleDateclicker = (event, value) => {
    const { searchFields } = this.state;
    const daysvalue = event.target.innerText;
    let startDate = new Date();
    let endDate = new Date();
    switch (daysvalue) {
      case '1d':
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '3d':
        startDate.setDate(startDate.getDate() - 2);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '1w':
        startDate.setDate(startDate.getDate() - 6);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      default:
    }
    startDate = moment(startDate).format(constants.YYYY_MM_DD_FORMAT);
    endDate = moment(endDate).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, startDate, endDate }
    });
  };

  trendsDataFetch = (value) => {
    const { showDateFields } = this.state;
    if (value === 'custom') {
      this.setState({
        showOutline: value,
        showDateFields: !showDateFields
      });
    }
  };

  render() {
    const {
      // classes,
      commonSearchUtilityStatus,
      selectedTabValue,
      commonSearchUtility: { isNextPageKey }
    } = this.props;
    const {
      searchFields,
      endDisabled,
      searchedDataResponse,
      // loaderStatus,
      bookList,
      isbookIdRequired,
      inputValue,
      tokenValue,
      showDateFields,
      clusterState,
      showOutline,
      tenantName,
      tenantTitle
    } = this.state;

    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }
    const theme = getDarkTheme();
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Card
          style={{
            paddingLeft: '16px',
            backgroundColor: '#f8f6fa',
            border: `2px solid ${theme.palette.border.darkBlue}`,
            alignContent: 'center'
          }}
        >
          <Box className="flexDisplay">
            {/* <Box sx={{ p: 2 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                {constants.CHAT_CRITERIA_TEXT}
              </Typography>
            </Box> */}
            {selectedTabValue === 2 ? (
              <DynamicTrendsComponent
                showDateFields={showDateFields}
                searchFields={searchFields}
                endDisabled={endDisabled}
                showOutline={showOutline}
                clusterState={clusterState}
                bookList={bookList}
                inputValue={inputValue}
                handleSelectChange={this.handleSelectChange}
                trendsDataFetch={this.trendsDataFetch}
                handleDateclicker={this.handleDateclicker}
                onInputChange={this.onInputChange}
                onAutoCompleteChange={this.onAutoCompleteChange}
                handleDateChange={this.handleDateChange}
                tenantName={tenantName}
              />
            ) : (
              <ObserveSearchComponent
                searchFields={searchFields}
                endDisabled={endDisabled}
                bookList={bookList}
                inputValue={inputValue}
                onInputChange={this.onInputChange}
                onAutoCompleteChange={this.onAutoCompleteChange}
                handleDateChange={this.handleDateChange}
                handleChange={this.handleChange}
                tenantName={tenantName}
                isbookIdRequired={isbookIdRequired}
                handleSearch={this.handleSearch}
              />
            )}
          </Box>
        </Card>
        <LoadingHandler
          loading={commonSearchUtilityStatus.isPending}
          isError={
            commonSearchUtilityStatus.isError &&
            commonSearchUtilityStatus.error &&
            (commonSearchUtilityStatus.error.status === 401 || commonSearchUtilityStatus.error.status === 500)
          }
          content={
            searchedDataResponse && searchedDataResponse.length > 0 ? (
              <Box sx={{ my: 2 }}>
                <DynamicDataGrid
                  selectedTab={selectedTabValue}
                  gridData={searchedDataResponse}
                  handleSearch={this.handleSearch}
                  handleAscending={this.handleAscending}
                  isNextPageKey={isNextPageKey}
                  clusterState={clusterState}
                  tenantName={tenantName}
                  tenantTitle={tenantTitle}
                />
              </Box>
            ) : (
              // Display the generic message initially if no search is performed
              !commonSearchUtilityStatus.isPending && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                    mt: 2
                  }}
                >
                  <Typography variant="h5" color="textSecondary">
                    Please search to fetch data...
                  </Typography>
                </Box>
              )
            )
          }
          loadingContent={<CustomLoader />}
          // loadingContent={<Loader loaderStatusResponse loaderStatus={loaderStatus} />}
          errorContent={<StateErrorDisplay error={commonSearchUtilityStatus.error} showDetails />}
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(DynamicComponent);

import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  mainContainer: {
    textAlign: 'end',
    marginBottom: '15px'
  },
  exportCsvButton: {
    backgroundColor: '#1A202C !important',
    color: 'white !important',
    cursor: 'pointer',
    height: '30px',
    border: 'none',
    borderRadius: '10px',
    width: '10%',
    textTransform: 'uppercase'
  }
});

const ExportToCSV = (props) => {
  const classes = useStyles();
  const { data, fileTitle, headers } = props;
  const escapeCSVValue = (value) => {
    // Convert to string (in case it's a number or boolean)
    let str =
      value === null || value === undefined || (Array.isArray(value) && value.length === 0) ? '' : String(value);
    // Handle commas, newlines, and quotes
    if (str.includes('"')) {
      // Escape double quotes by doubling them
      str = str.replace(/"/g, '""');
    }
    if (str.includes(',') || str.includes('\n') || str.includes('\r')) {
      // Enclose value in double quotes if it contains comma, newline, or carriage return
      str = `"${str}"`;
    }
    return str.trim();
  };

  // Function to convert JSON to CSV
  const jsonToCSV = (jsonData, customHeaders) => {
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
      throw new Error('Invalid JSON data');
    }
    if (!Array.isArray(customHeaders) || customHeaders.length === 0) {
      throw new Error('Invalid headers provided');
    }
    const csvHeaders = customHeaders
      .map((header) => escapeCSVValue(header.name)) // Use `name` for display
      .join(',');
    const csvRows = jsonData.map((row) => {
      return customHeaders
        .map((header) => escapeCSVValue(row[header.key] !== undefined ? row[header.key] : '')) // Use `key` for accessing data
        .join(',');
    });
    const csv = [csvHeaders, ...csvRows].join('\n');
    return csv;
  };

  // Function to handle the CSV download
  const handleDownload = () => {
    const csv = jsonToCSV(data, headers);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileTitle);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.mainContainer}>
      <Button
        className={classes.exportCsvButton}
        color="primary"
        variant="outlined"
        size="small"
        onClick={handleDownload}
      >
        Export CSV
      </Button>
    </div>
  );
};

ExportToCSV.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  fileTitle: PropTypes.string
};

ExportToCSV.defaultProps = {
  headers: [],
  data: [],
  fileTitle: ''
};

export default ExportToCSV;

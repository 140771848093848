import Framework, { shapes } from '@greenville/framework';
import { Grid, Paper, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../../common/constants';
import Toc from '../../models/Toc';
import AssessmentLoader from './AssessmentLoader';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '80%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '60px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginLeft: '60px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  heading: {
    marginTop: '20px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  editQues: {
    fontSize: '1rem',
    fontWeight: 600,
    width: '100%'
  },
  optionBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px'
  },
  editRadioText: {
    marginTop: '20px !important',
    marginBottom: '0px !important'
  },
  feedbackBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  bottomBox: {
    display: 'flex',
    width: '100%',
    marginTop: '20px'
  },
  textfieldBox: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
    justifyContent: 'start'
  },
  feedbackText: {
    display: 'flex',
    alignItems: 'start',
    paddingTop: '8px'
  },
  previewQues: {
    fontSize: '1rem',
    fontWeight: 600,
    height: '35px',
    lineHeight: '32px'
  },
  previewRadioLabel: {
    paddingTop: '18px'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AssessmentDialog = (props) => {
  const { openDialog, handleClose, classes, chatgptTocUtility, history } = props;
  const [loading, setLoading] = useState(true);
  const [tip, setTip] = useState('Loading, please wait...');
  const [value, setValue] = useState('');
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [questionData, setQuestionData] = useState([]);

  useEffect(() => {
    const fetchMcqData = async () => {
      await chatgptTocUtility.fetch({
        bookId: 'pvs-22706eae-864a-4467-9df9-f803ff8170c4',
        sourceId: history.location.state.sourceId
      });
      Framework.getEventManager().on(constants.SET_CHATGPT_TOC_DATA, () => {
        const { data } = chatgptTocUtility;
        if (data && data.length > 0) {
          setQuestionData(data);
        } else {
          setQuestionData([]);
        }
        setLoading(false);
      });
    };
    fetchMcqData();
    // const timer = setTimeout(() => {
    //   setLoading(false);
    // }, 8000);
    // Update the tip dynamically
    const tips = ['Fetching data...', 'Just a moment...', 'Almost there...', 'Hang tight!'];
    let index = 0;
    const tipInterval = setInterval(() => {
      setTip(tips[index]);
      index = (index + 1) % tips.length;
    }, 2000);
    return () => {
      clearInterval(tipInterval);
      // clearTimeout(timer);
    };
  }, []);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleEdit = () => {
    setIsPreviewMode(false);
  };

  const handlePreview = () => {
    setIsPreviewMode(true);
  };

  const handleOptionChange = (event, questionVal, index) => {
    const updatedData = questionData.map((item) => {
      if (item.questionId === questionVal.questionId) {
        const updatedQuestionOptions = {
          ...item.question,
          options: item.question.options.map((option) => {
            if (option.id === index + 1) {
              return { ...option, option_text: event.target.value };
            }
            return option;
          })
        };
        return {
          ...item,
          question: updatedQuestionOptions
        };
      }
      return item;
    });

    setQuestionData(updatedData);
  };

  const handleQuestionChange = (event) => {
    const questionText = questionData.map((ques) => {
      if (ques.questionId === event.target.name) {
        return {
          ...ques,
          question: {
            ...ques.question,
            question_text: event.target.value
          }
        };
      }
      return ques;
    });

    setQuestionData(questionText);
  };

  const renderMcqData = () => {
    return (
      <Box>
        {isPreviewMode ? (
          <Paper style={{ padding: '20px' }}>
            {questionData.length > 0 &&
              questionData.map((val, index) => (
                <>
                  <Typography className={classes.previewQues} variant="body2" color="text.primary">
                    &nbsp;<b>{index + 1}</b> : {val.question.question_text}
                  </Typography>
                  <Box className={classes.optionBox}>
                    {val.question.options.map((optionItem) => {
                      return optionItem ? (
                        <>
                          <FormControl component="fieldset">
                            <RadioGroup value={value} onChange={(e) => handleRadioChange(e, index)}>
                              <FormControlLabel
                                value={optionItem.option_text}
                                control={<Radio color="primary" />}
                                label={optionItem.option_text}
                                className={classes.previewRadioLabel}
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      ) : null;
                    })}
                  </Box>
                  <div className={classes.bottomBox}>
                    <div>
                      <TextField label="Points" variant="outlined" size="small" />
                    </div>
                    <div className={classes.feedbackBox}>
                      <Typography variant="caption" className={classes.feedbackText}>
                        Provide Feedback
                      </Typography>
                      <Box style={{ marginRight: '25px' }}>
                        <IconButton>
                          <ThumbUpIcon />
                        </IconButton>
                        <IconButton>
                          <ThumbDownIcon />
                        </IconButton>
                      </Box>
                    </div>
                  </div>
                  <br />
                  <Divider />
                </>
              ))}
          </Paper>
        ) : (
          <Paper style={{ padding: '20px' }}>
            {questionData.length > 0 &&
              questionData.map((val, index) => (
                <>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      label={<b>Question {index + 1}</b>}
                      className={classes.editQues}
                      variant="outlined"
                      size="small"
                      value={val.question.question_text}
                      name={val.questionId}
                      onChange={(e) => handleQuestionChange(e)}
                      style={{ width: '95%' }}
                    />
                    <IconButton aria-label="delete">
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </div>
                  <Box className={classes.optionBox}>
                    {val.question.options.map((optionItem, subIndex) => {
                      return optionItem ? (
                        <>
                          <TextField
                            label={`Option ${subIndex + 1}`}
                            className={classes.editRadioText}
                            variant="outlined"
                            size="small"
                            value={optionItem.option_text}
                            name={optionItem.id}
                            onChange={(e) => handleOptionChange(e, val, subIndex)}
                            style={{ width: '90%' }}
                          />
                        </>
                      ) : null;
                    })}
                  </Box>
                  <div className={classes.bottomBox}>
                    <div>
                      <TextField label="Points" variant="outlined" size="small" />
                    </div>
                    <div className={classes.feedbackBox}>
                      <Typography variant="caption" className={classes.feedbackText}>
                        Provide Feedback
                      </Typography>
                      <Box style={{ marginRight: '25px' }}>
                        <IconButton>
                          <ThumbUpIcon />
                        </IconButton>
                        <IconButton>
                          <ThumbDownIcon />
                        </IconButton>
                      </Box>
                    </div>
                  </div>
                  <br />
                  <Divider />
                  <br />
                </>
              ))}
          </Paper>
        )}
        {questionData.length === 0 && (
          <Grid item style={{ padding: '10%' }}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              Data Not Found.
            </Typography>
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
      <div className={classes.flexBorder}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} style={{ flexDirection: 'column' }}>
            <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' }}>
              <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </Box>
            {!loading && questionData.length > 0 && (
              <Box display="flex" style={{ justifyContent: 'flex-start', marginLeft: '25px' }}>
                <Stack direction="row" spacing={2}>
                  <Button
                    key="edit"
                    variant={isPreviewMode ? 'outlined' : 'contained'}
                    size="small"
                    endIcon={<EditIcon />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                  <Button
                    key="preview"
                    variant={isPreviewMode ? 'contained' : 'outlined'}
                    size="small"
                    endIcon={<VisibilityIcon />}
                    onClick={handlePreview}
                  >
                    Preview
                  </Button>
                </Stack>
              </Box>
            )}
          </Grid>
        </Box>
        <Box>
          <Grid item xs={16}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              Assessment Questions
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ width: '100%' }}>{loading ? <AssessmentLoader tip={tip} /> : <div>{renderMcqData()}</div>}</Box>
      </div>
    </Dialog>
  );
};

AssessmentDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  chatgptTocUtility: shapes.modelOf(Toc).isRequired
};

export default withStyles(styles)(observer(inject('chatgptTocUtility')(AssessmentDialog)));

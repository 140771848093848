import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import TenantConfigureDialog from './TenantConfigureDialog';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  }
});

const mockData = [
  {
    tenantName: 'AI Study tools',
    tenantId: '32e81240-7457-11ed-89c4-a117ea7cad6a',
    tenantKey: '44950e21-7c68-49b8-bddd-9c454723a3eb',
    description: 'AI Study Tools Chat Bot'
  },
  {
    tenantName: 'Channels',
    tenantId: 'a7167a3e-2538-4069-8f63-ef761f75b332',
    tenantKey: 'dc3312ef-6327-4578-8321-aa23a97f649d',
    description: 'Channels Bot'
  },
  {
    tenantName: 'Pearson Virtual Schools',
    tenantId: '7408ccc0-86ac-40e5-9d6d-9e1eae93900c',
    tenantKey: 'f917690b-f60f-4f77-93c8-2ef8eacf5d3e',
    description: 'Virtual Schools Bot'
  }
];

const TenantListView = () => {
  const classes = useStyles();
  const [tenantData, setTenantData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    setTenantData(mockData);
  }, []);
  const onColumnClick = () => {};

  const onRowClick = () => {};

  const handleConfigureNewTenant = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Paper style={{ padding: '10px' }}>
        <Grid container spacing={2} style={{ flexDirection: 'column' }}>
          <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '30px' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleConfigureNewTenant}
              className={classes.button}
            >
              Configure New Tenant
            </Button>
          </Box>
        </Grid>
        <Box sx={{ p: 1 }}>
          <Typography variant="h5" align="center" gutterBottom spacing={9}>
            Tenants
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <TableComponent
            columns={ColumnConfig.TenantsDataGridColumn}
            data={tenantData}
            onColumnClick={onColumnClick}
            order="ASC"
            onRowClick={onRowClick}
            directionValue=""
            userEmailDetails=""
            onMouseEnter={() => {}}
          />
        </Box>
      </Paper>
      {openDialog && <TenantConfigureDialog openDialog={openDialog} handleClose={handleClose} />}
    </>
  );
};

export default TenantListView;

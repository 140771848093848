import { Box, Button, Grid } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../../../../common/constants';

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const SearchButtonComponent = (props) => {
  const { handleSearch, handleReset, classes } = props;
  return (
    <Box>
      <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
        <ThemeProvider theme={themes}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleSearch()}
            className={classes.buttonStyle}
          >
            {constants.CHAT_SEARCH}
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleReset()}
            className={classes.buttonStyle}
          >
            {constants.CHAT_RESET}
          </Button>
        </ThemeProvider>
      </Grid>
      <br />
    </Box>
  );
};

SearchButtonComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired
};

export default withStyles(styles)(SearchButtonComponent);

import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { Rating } from '@mui/material';
import { shapes } from '@greenville/framework';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { inject } from 'mobx-react';
import Toc from '../../models/Toc';
import * as constants from '../../../../common/constants';
import { getDarkTheme } from '../../ChatGptTheme';

const commonTheme = getDarkTheme();

const styles = () => ({
  flexNoSpace: {
    display: 'flex',
    alignItems: 'center',
    color: 'black'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid grey',
    padding: '10px'
  },
  mainContainer: {
    border: '1px solid grey',
    padding: '15px',
    borderRadius: '4px',
    margin: '15px'
  },
  mainPadding: {
    padding: '15px'
  },
  headings: {
    flex: 1,
    color: 'black'
  },
  difficultyRating: {
    display: 'flex',
    paddingLeft: '50px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  IconItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '10px'
  },
  Iconbutton: {
    color: '#1A202C !important'
  }
});

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: commonTheme.palette.primary.main
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #333333'
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: commonTheme.palette.primary.main
    }
  }
});

const StyledRating = styled(Rating)({
  '& .MuiSvgIcon-root': {
    color: '#faaf00'
  },
  '& .MuiRating-iconFilled': {
    color: 'green'
  },
  '& .MuiRating-iconHover': {
    color: 'red'
  }
});

function TocDialog(props) {
  const { onClose, classes, questionData, chatgptTocUtility, submitFlag } = props;
  const [modifyQuestionData, setModifyQuestionData] = useState({});
  const [difficultyLevel, setDifficultyLevel] = useState(0);
  const [questionTextError, setQuestionTextError] = useState(false);
  const handleQuestionChange = (event) => {
    const questionText = event.target.value;
    setModifyQuestionData((prev) => ({ ...prev, question_text: questionText }));
    setQuestionTextError(false);
  };

  const handleOptionChange = (event, type, itemInfo) => {
    const { value } = event.target;
    const { id } = itemInfo;
    if (modifyQuestionData) {
      const question = toJS(modifyQuestionData);
      const changeCorrectChoice = question.options.map((item) => {
        const questionItem = toJS(item);
        if (questionItem.id === id) {
          if (type === 'option_text') {
            questionItem.option_text = value;
            questionItem.error1 = false;
          } else if (type === 'explanation') {
            questionItem.explanation = value;
            questionItem.error2 = false;
          } else if (type === 'radio') {
            questionItem.correct_choice = 'Yes';
          }
        } else if (type === 'radio') {
          questionItem.correct_choice = 'No';
        }
        return questionItem;
      });
      setModifyQuestionData({ ...modifyQuestionData, options: changeCorrectChoice });
    }
  };

  useEffect(() => {
    if (questionData) {
      if (questionData.question) {
        setModifyQuestionData(questionData.question);
      }
      if (questionData.difficultyLevel) {
        setDifficultyLevel(questionData.difficultyLevel);
      }
    }
  }, [questionData]);

  /* eslint-disable */
  const handleSave = async () => {
    if (questionData) {
      let valueCheck = true;
      const { question_text, options } = modifyQuestionData;
      let radioOptionCount = 0;
      if (question_text) {
        setQuestionTextError(false);
      } else {
        setQuestionTextError(true);
        valueCheck = false;
      }
      if (options && options.length > 0) {
        const checkedData = options.map((item) => {
          if (item.correct_choice === 'Yes') {
            radioOptionCount += 1;
          }
          const error1 = item.option_text ? false : true;
          const error2 = item.explanation ? false : true;
          if (error1 || error2) {
            valueCheck = false;
          }
          return {
            ...item,
            error1,
            error2
          };
        });
        setModifyQuestionData((prev) => ({ ...prev, options: checkedData }));
      }
      if (valueCheck && radioOptionCount > 0) {
        const filterQuestionKeys = options.map((item) => {
          const { error1, error2, ...restValues } = item;
          return restValues;
        });
        const question = {
          ...questionData,
          question: { ...modifyQuestionData, options: filterQuestionKeys },
          difficultyLevel,
          lifeCycleStatus: constants.REVIEW_STATUS
        };
        await chatgptTocUtility.updateMcqQuestion(question);
        onClose();
      }
    }
    /* eslint-enable */
  };

  const handleAdditionalOption = () => {
    const newOptionId = { id: modifyQuestionData.options.length + 1 };
    const question = toJS(modifyQuestionData);
    const newVal = [...question.options, newOptionId];
    setModifyQuestionData({ ...modifyQuestionData, options: newVal });
  };

  const DeleteOption = () => {
    const question = toJS(modifyQuestionData);
    if (question.options) {
      const newVal = question.options;
      newVal.pop();
      setModifyQuestionData({ ...modifyQuestionData, options: newVal });
    }
  };

  useEffect(() => {
    if (submitFlag) {
      handleSave();
    }
  }, [submitFlag]);

  return (
    <div>
      <div className={classes.mainPadding}>
        <CssTextField
          fullWidth
          id="outlined-basic"
          label="Update your question here"
          variant="outlined"
          value={modifyQuestionData && modifyQuestionData.question_text}
          onChange={(e) => handleQuestionChange(e)}
          error={questionTextError}
          inputProps={{
            style: {
              color: 'black'
            }
          }}
        />
      </div>
      {modifyQuestionData &&
        modifyQuestionData.options &&
        modifyQuestionData.options.map((item) => (
          <>
            <div className={classes.mainContainer}>
              <div>
                <CssTextField
                  fullWidth
                  id="outlined-basic"
                  label="Update your option here"
                  variant="outlined"
                  value={item.option_text}
                  onChange={(e) => handleOptionChange(e, 'option_text', item)}
                  error={item.error1}
                  inputProps={{
                    style: {
                      color: 'black'
                    }
                  }}
                />
              </div>
              <br />
              <div>
                <CssTextField
                  fullWidth
                  id="outlined-basic"
                  label="Update your feedback here"
                  variant="outlined"
                  value={item.explanation}
                  onChange={(e) => handleOptionChange(e, 'explanation', item)}
                  error={item.error2}
                  inputProps={{
                    style: {
                      color: 'black'
                    }
                  }}
                />
              </div>
              <div className={classes.flexNoSpace}>
                <h4>Correct Choice :</h4>
                <Radio
                  checked={item.correct_choice === 'Yes'}
                  onChange={(e) => handleOptionChange(e, 'radio', item)}
                  value="Yes"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </div>{' '}
            </div>
          </>
        ))}
      <div className={classes.IconItem}>
        <Tooltip title="This will add block of Option/Feedback.MAX limit-4">
          <IconButton
            disabled={modifyQuestionData && modifyQuestionData.options && modifyQuestionData.options.length >= 4}
            onClick={() => handleAdditionalOption()}
            className={classes.Iconbutton}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        <IconButton
          disabled={modifyQuestionData && modifyQuestionData.options && modifyQuestionData.options.length <= 1}
          onClick={() => DeleteOption()}
          className={classes.Iconbutton}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </div>

      <div className={classes.flexContainerBottom}>
        <div className={classes.difficultyRating}>
          <Typography variant="h6">Difficutly : </Typography>
          <StyledRating
            name="simple-controlled"
            value={difficultyLevel}
            onChange={(event, newValue) => {
              setDifficultyLevel(newValue);
            }}
          />
        </div>
      </div>
    </div>
  );
}

TocDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  questionData: PropTypes.object.isRequired,
  chatgptTocUtility: shapes.modelOf(Toc).isRequired,
  submitFlag: PropTypes.bool
};

TocDialog.defaultProps = {
  submitFlag: false
};

export default withStyles(styles)(inject('chatgptTocUtility')(TocDialog));

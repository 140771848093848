import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, useTheme } from '@mui/material';

const CustomSelect = ({ label, value, name, onChange, options, error = false, helperText = '', ...props }) => {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event);
  };

  // Use theme values to dynamically apply styles
  const selectStyles = {
    // backgroundColor: theme.palette.background.paper, // Use background color from the theme
    borderColor: error ? theme.palette.error.main : theme.palette.primary.main, // Conditional border color
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    '&:hover': {
      borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark // Border color on hover
    },
    '& .MuiInputBase-input': {
      padding: '4px' // Padding inside the input field
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main // Use primary color for the select icon
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#bfbeba' // Default border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333' // Hover border color
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3d5b7a' // Focused border color
    }
  };

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        value={selectedValue}
        name={name}
        onChange={handleChange}
        label={label}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          }
        }}
        sx={selectStyles}
        {...props}
      >
        <MenuItem value="" disabled>
          <em>{label}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.name}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

// Prop validation with PropTypes
CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

// Default values for optional props
CustomSelect.defaultProps = {
  error: false,
  helperText: '',
  value: ''
};

export default CustomSelect;

import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import * as _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../../../../common/Loader';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import AutocompleteComponent from '../../common/components/Autocomplete';
import ChannelsUtilityModel from '../../models/ChannelsUtility';
import ChatgptAdmin from '../../models/ChatgptAdmin';
import InternalUsersList from '../../models/InternalUsersList';
import ChannelsDataGrid from './datagrid/ChannelsDataGrid';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  datePicker: {
    marginTop: 'inherit'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});
@inject(
  'channelsUtility',
  'channelsUtilityStatus',
  'chatgptAdmin',
  'ChatGptUtilityAdminStatus',
  'ChatGptUtilityGetAdminStatus',
  'internalUsers'
)
@observer
class ChannelsComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    channelsUtility: shapes.modelOf(ChannelsUtilityModel).isRequired,
    channelsUtilityStatus: shapes.state.isRequired,
    chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
    ChatGptUtilityAdminStatus: shapes.state.isRequired,
    ChatGptUtilityGetAdminStatus: shapes.state.isRequired,
    internalUsers: shapes.modelOf(InternalUsersList).isRequired
  };

  constructor(props) {
    super(props);
    const { channelsUtility } = props;
    this.defaultSearch = {
      channelsId: 'general-chemistry',
      userId: '',
      email: '',
      startDateTime: '',
      endDateTime: '',
      type: ''
    };
    this.state = {
      defaultDropdownValue: { title: 'general-chemistry', channelsId: 'general-chemistry', id: 1 },
      newRequest: true,
      searchFields: {
        channelsId: 'general-chemistry'
      },
      channelsResponse: channelsUtility,
      endDisabled: true,
      loaderStatus: true,
      types: [],
      userIdChecked: false,
      channelsList: [],
      inputValue: '',
      isUserIdExcluded: true,
      tokenValue: ''
    };
  }

  componentDidMount = () => {
    const { channelsUtility, chatgptAdmin } = this.props;
    const groupsData = JSON.parse(JSON.stringify(toJS(chatgptAdmin)));
    if (!_.isEmpty(groupsData?.groups)) {
      const typesData = groupsData?.groups?.filter((item) => item.name === 'Types')[0];
      const channelsNames = groupsData?.groups?.filter((item) => item.name === 'channelsName')[0];
      this.setState({ types: typesData, channelsList: channelsNames });
    }

    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.CHANNELS_RESPONSE, () => {
      const { data } = channelsUtility;
      if (channelsUtility) {
        const { newRequest, channelsResponse } = this.state;
        const combineDatatoJS = data;
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = combineDatatoJS.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState({ channelsResponse: sortedDate });
        } else {
          const combineDataGpt = [...channelsResponse, ...combineDatatoJS];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState(() => ({ channelsResponse: sortedDate }));
        }
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { internalUsers, chatgptAdmin } = this.props;
    const { tokenValue } = this.state;
    const usersData = JSON.parse(JSON.stringify(toJS(internalUsers)));
    const groupsData = JSON.parse(JSON.stringify(toJS(chatgptAdmin)));
    if (prevState.tokenValue !== tokenValue) {
      if (groupsData && groupsData.groups === null) {
        this.getChannelsData();
      }
      if (usersData && usersData?.userId.length === 0) {
        this.getS3InternalUsersList();
      }
    }
  };

  getS3InternalUsersList = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const payload = {
      itemPath: constants.S3_EXCLUDED_USERS_LIST_PATH
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_S3_USERS_LIST_URL}`, payload, {
      headers
    });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('internalUsers').setResponse(response.data.data[0]);
    }
  };

  getChannelsData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_CHANNELS_DATA_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const { groups } = response.data.data;
      if (groups && groups.length > 0) {
        const typesData = groups.filter((item) => item.name === 'Types')[0];
        const channelsNames = groups.filter((item) => item.name === 'channelsName')[0];
        this.setState({ types: typesData, channelsList: channelsNames });
      }
      Framework.getStoreRegistry().getStore('chatgptAdmin').setResponse(response.data);
    }
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDateTime) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleReset = () => {
    this.setState({
      endDisabled: true,
      searchFields: {
        ...this.defaultSearch
      },
      userIdChecked: false
    });
  };

  handleSearch = (customPayload) => {
    const { searchFields } = this.state;
    const { channelsUtility } = this.props;
    if (searchFields.userId) {
      this.setState({ userIdChecked: true });
    } else {
      this.setState({ userIdChecked: false });
    }
    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }

    const payload = { ...searchFields, ...customPayload };
    if (payload && (payload.channelsId || payload.email || payload.userId || payload.type)) {
      channelsUtility.fetch(payload);
    }
  };

  handleAscending = (dir, value) => {
    const { channelsResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...channelsResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...channelsResponse,
        channelsResponse: sorted
      });
    } else {
      const sorted = [...channelsResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...channelsResponse,
        channelsResponse: sorted
      });
    }
  };

  setUserId = (field, checkedUserId) => {
    const { searchFields, userIdChecked } = this.state;
    const fieldChanged = {};
    if (userIdChecked) {
      fieldChanged[field] = '';
      this.setState(
        {
          searchFields: { ...searchFields, ...fieldChanged },
          userIdChecked: false
        },
        () => {
          this.handleSearch();
        }
      );
    } else {
      fieldChanged[field] = checkedUserId;
      this.setState(
        {
          searchFields: { ...searchFields, ...fieldChanged },
          userIdChecked: true
        },
        () => {
          this.handleSearch();
        }
      );
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        channelsId: newValue !== null ? newValue.id : ''
      }
    });
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleFilterUserChange = (e) => {
    this.setState({
      isUserIdExcluded: e.target.checked
    });
  };

  render() {
    const {
      classes,
      channelsUtilityStatus,
      ChatGptUtilityAdminStatus,
      ChatGptUtilityGetAdminStatus,
      channelsUtility: { isNextPageKey }
    } = this.props;
    const {
      searchFields,
      endDisabled,
      loaderStatus,
      channelsResponse,
      types,
      userIdChecked,
      inputValue,
      channelsList,
      defaultDropdownValue,
      isUserIdExcluded,
      tokenValue
    } = this.state;

    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }

    return (
      <>
        {ChatGptUtilityGetAdminStatus.isPending ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Paper>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h5" align="center" gutterBottom spacing={9}>
                    {constants.CHAT_CRITERIA_TEXT}
                  </Typography>
                </Box>
                <Grid container direction="row">
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}>
                      {/* <TextField
                    label="Channels Id" 
                    margin="dense"
                    name="channelsId"
                    onChange={($event) => this.handleChange($event)}
                    style={{ minWidth: '32%', marginTop: '0px' }}
                    value={searchFields.channelsId}
                    variant="outlined"
                  /> */}
                      {channelsList && channelsList.properties && (
                        <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                          <AutocompleteComponent
                            options={channelsList.properties}
                            onAutoCompleteChange={this.onAutoCompleteChange}
                            onInputChange={this.onInputChange}
                            stateVal={inputValue}
                            errorVal=""
                            name="channelsId"
                            label="Channels Id"
                            componentDefaultValue={defaultDropdownValue}
                          />
                        </FormControl>
                      )}
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            format={constants.MM_DD_YYYY_FORMAT}
                            autoOk
                            size="small"
                            variant="inline"
                            style={{ minWidth: '32%' }}
                            inputVariant="outlined"
                            disableFuture
                            value={searchFields.startDateTime || null}
                            label="Start Date"
                            onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            format={constants.MM_DD_YYYY_FORMAT}
                            autoOk
                            variant="inline"
                            size="small"
                            style={{ minWidth: '32%' }}
                            inputVariant="outlined"
                            disableFuture
                            value={searchFields.endDateTime || null}
                            label="End Date"
                            onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                            disabled={endDisabled}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="center">
                  <Grid item xs={10} direction="row">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                      <TextField
                        label={searchFields.userId ? '' : 'User ID'}
                        margin="dense"
                        name="userId"
                        onChange={($event) => this.handleChange($event)}
                        style={{ minWidth: '32%' }}
                        value={searchFields.userId}
                        variant="outlined"
                      />
                      <TextField
                        label="Email ID"
                        margin="dense"
                        type="email"
                        style={{ minWidth: '32%' }}
                        name="email"
                        onChange={($event) => this.handleChange($event)}
                        value={searchFields.email}
                        variant="outlined"
                      />
                      <FormControl sx={{ mt: 1, width: '32%' }} size="small">
                        <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                        <Select
                          label="Type"
                          name="type"
                          onChange={($event) => this.handleChange($event)}
                          value={searchFields.type || ''}
                        >
                          {types &&
                            types.properties &&
                            types.properties.map((item) => <MenuItem value={item.id}>{item.display_name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <br />
              </Box>
              <Box>
                <Grid container direction="row" justifyContent="center">
                  <FormControl size="small">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleFilterUserChange}
                          name="excludeUserId"
                          checked={isUserIdExcluded}
                        />
                      }
                      style={{
                        alignItems: 'center'
                      }}
                      labelPlacement="end"
                      label="Filter Internal Users"
                    />
                  </FormControl>
                </Grid>
              </Box>
              <br />
              <Box>
                <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
                  <ThemeProvider theme={themes}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => this.handleSearch()}
                      className={classes.buttonStyle}
                    >
                      {constants.CHAT_SEARCH}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={this.handleReset}
                      className={classes.buttonStyle}
                    >
                      {constants.CHAT_RESET}
                    </Button>
                  </ThemeProvider>
                </Grid>
                <br />
              </Box>
            </Paper>
            <LoadingHandler
              loading={channelsUtilityStatus.isPending || ChatGptUtilityAdminStatus.isPending}
              isError={
                channelsUtilityStatus.isError &&
                channelsUtilityStatus.error &&
                channelsUtilityStatus.error.status === 401 &&
                channelsUtilityStatus.error.status === 500
              }
              content={
                channelsResponse && channelsResponse.length > 0 ? (
                  <Box>
                    <br />
                    <ChannelsDataGrid
                      channelsUtilityData={channelsResponse}
                      handleAscending={this.handleAscending}
                      isNextPageKey={isNextPageKey}
                      handleSearch={this.handleSearch}
                      setUserId={this.setUserId}
                      userIdChecked={userIdChecked}
                      isUserIdExcluded={isUserIdExcluded}
                    />
                  </Box>
                ) : (
                  <>
                    <br />
                    <Paper>
                      <br />
                      <Box>
                        <br />
                        <Typography
                          variant="h4"
                          align="center"
                          gutterBottom
                          spacing={9}
                          className={classes.title}
                          style={{ p: 5, mt: 25 }}
                        >
                          {constants.CHAT_FETCH}
                        </Typography>
                      </Box>
                    </Paper>
                  </>
                )
              }
              loadingContent={<Loader loaderStatus={loaderStatus} />}
              errorContent={<StateErrorDisplay error={channelsUtilityStatus.error} showDetails />}
            />
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles)(ChannelsComponent);

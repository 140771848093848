import React from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControlLabel, styled, Typography } from '@mui/material';

const CustomSwitch = styled(Switch)(({ theme, color, size }) => {
  const sizes = {
    small: {
      width: 40,
      height: 24,
      thumbSize: 18,
      padding: 4
    },
    medium: {
      width: 60,
      height: 34,
      thumbSize: 26,
      padding: 7
    },
    large: {
      width: 80,
      height: 44,
      thumbSize: 34,
      padding: 9
    }
  };

  // Use medium size as the default if no size is provided
  const { width, height, thumbSize, padding } = sizes[size] || sizes.medium;

  return {
    width,
    height,
    padding,
    '& .MuiSwitch-switchBase': {
      padding: 3,
      transitionDuration: '300ms'
    },
    '& .MuiSwitch-track': {
      borderRadius: height / 2,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#bdbdbd'
    },
    '& .MuiSwitch-thumb': {
      width: thumbSize,
      height: thumbSize,
      borderRadius: '50%',
      backgroundColor: '#fff',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      transition: 'all 0.3s ease'
    },
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: color || '#4caf50', // Use dynamic color when checked
        transform: `translateX(${width - thumbSize - 2}px)`
      },
      '& .MuiSwitch-track': {
        backgroundColor: color || '#4caf50' // Use dynamic color for track when checked
      }
    }
  };
});

// Main component
function CustomSwitchComponent({ checked, onChange, label, color, size }) {
  return (
    <div>
      <FormControlLabel
        control={<CustomSwitch checked={checked} onChange={onChange} color={color} size={size} />}
        label={<Typography sx={{ color, fontSize: '12px' }}>{label}</Typography>}
      />
    </div>
  );
}

// PropTypes validation
CustomSwitchComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

CustomSwitchComponent.defaultProps = {
  label: 'Custom Switch',
  color: '#4caf50',
  size: 'medium'
};

export default CustomSwitchComponent;

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Typography
} from '@material-ui/core';

import Framework, { shapes } from '@greenville/framework';
import Users from '../../models/ChatutilityUsers';
import ChatutilityEditUser from './ChatutilityEditUser';
import UserDialog from './ChatutilityUserDialog';
import RoleDialog from './ChatutilityRoleDialog';
import Roles from '../../models/ChatutilityRoles';
import Permissions from '../../models/ChatutilityPermissions';

/**
 * A Component for the display of the Course Card.
 *
 * @param data the title of the course
 * @param btnText the title of the course
 * @param lastName the title of the course
 * @param role the title of the course
 * @param edit the title of the course
 * @return {*} the React pure component
 * @constructor
 */

const columns = [
  {
    id: 'userLogin',
    label: 'User Login',
    minWidth: 70,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'role',
    label: 'Role',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 120,
    align: 'center',
    variant: 'outlined',
    color: 'primary',
    size: 'small',
    format: (value) => value.toLocaleString()
  }
];

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  addUser: {
    marginBottom: '20px',
    minWidth: 120
  },

  display: {
    display: 'flex'
  },
  posClass: {
    cursor: 'pointer',
    color: '#005d83',
    '&:hover': {
      fontWeight: 'bold',
      color: '#005d83',
      textDecoration: 'underline',
      textDecorationColor: '#005d83'
    }
  },
  customDisplay: {
    position: 'relative'
  },
  customText: {
    position: 'absolute',
    top: '10%',
    left: '80%',
    width: '50%'
  },
  fontSize: {
    fontSize: '15px',
    fontWeight: '400'
  }
});

const ChatutilityUsersList = ({
  classes,
  data,
  chatutilityusersState,
  chatutilityuserRoles,
  chatutilityuserPermissions
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showAddUserText, setshowAddUserText] = React.useState(false);
  const [showEditData, setshowEditData] = React.useState({});
  const [openNewUserDialog, setOpenNewUserDialog] = React.useState(false);
  const [openNewRoleDialog, setOpenNewRoleDialog] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const history = useHistory();

  const closeUserDialog = () => {
    setOpenNewUserDialog(false);
  };

  const handleAddUserClick = () => {
    setOpenNewUserDialog(true);
  };

  const closeRoleDialog = () => {
    setOpenNewRoleDialog(false);
  };

  const handleAddRoleClick = () => {
    setOpenNewRoleDialog(true);
  };

  const handleEditRoleClick = () => {
    Framework.redirectTo('/chatgptutility/roles');
  };

  const closeShowAddEdit = () => {
    setshowAddUserText(false);
  };

  const saveUser = (userData) => {
    chatutilityusersState.addUser(userData);
    setOpenNewUserDialog(false);
  };

  const saveRole = (rolesData) => {
    chatutilityusersState.addRoles(rolesData);
    setOpenNewRoleDialog(false);
  };

  const updateUser = (userData) => {
    chatutilityusersState.updateUser(userData);
    setshowAddUserText(false);
  };

  const UserEditData = (email) => {
    chatutilityusersState.data.map((x) => {
      if (x.email === email) {
        return setshowEditData(x);
      }
      return null;
    });
  };

  const handleEditData = (row, column) => {
    if (column.id === 'action') {
      setshowAddUserText(true);
      UserEditData(row.email);
    }
  };

  return (
    <>
      {history.location.pathname === '/chatgptutility/users' && (
        <div>
          <Typography align="center" variant="h5">
            User List
          </Typography>{' '}
          <br />
          <br />
          <span>
            <Button
              id="newUserBtn"
              className={classes.addUser}
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleAddUserClick}
            >
              Add User
            </Button>
          </span>
          <span style={{ float: 'right', marginLeft: '5px' }}>
            <Button
              id="newUserBtn"
              className={classes.addUser}
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleEditRoleClick}
            >
              Edit Role
            </Button>
          </span>
          <span style={{ float: 'right' }}>
            <Button
              id="newUserBtn"
              className={classes.addUser}
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleAddRoleClick}
            >
              Add Role
            </Button>
          </span>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        className={classes.fontSize}
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={`$(row.id)-${i}`}>
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={`$(column.id)-${index}`}
                            align={column.align}
                            onClick={() => handleEditData(row, column)}
                          >
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                            {column.id === 'action' ? (
                              <span>
                                <Button variant={column.variant} size={column.size} color={column.color}>
                                  EDIT
                                </Button>
                              </span>
                            ) : (
                              ''
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 30, 60, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <br />
          <br />
          <br />
          {openNewUserDialog && (
            <UserDialog
              closeUserDialog={closeUserDialog}
              saveUser={saveUser}
              chatutilityuserRoles={chatutilityuserRoles}
            />
          )}
          {openNewRoleDialog && (
            <RoleDialog
              closeRoleDialog={closeRoleDialog}
              saveRole={saveRole}
              chatutilityuserRoles={chatutilityuserRoles}
              chatutilityuserPermissions={chatutilityuserPermissions}
            />
          )}
          {showAddUserText && (
            <ChatutilityEditUser
              closeShowAddEdit={closeShowAddEdit}
              showAddUserText={showAddUserText}
              showEditData={showEditData}
              updateUser={updateUser}
              chatutilityuserRoles={chatutilityuserRoles}
            />
          )}
        </div>
      )}
    </>
  );
};

ChatutilityUsersList.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  chatutilityusersState: shapes.modelOf(Users).isRequired,
  chatutilityuserRoles: shapes.modelOf(Roles).isRequired,
  chatutilityuserPermissions: shapes.modelOf(Permissions).isRequired
};

export default withStyles(styles)(ChatutilityUsersList);

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const allPermissions = types.model('AllPermissions', {
  permission: types.maybeNull(types.string)
});

const ChatutilityPermissions = types
  .model('ChatutilityPermissions', {
    data: types.maybeNull(types.array(types.optional(allPermissions, {}))),
    success: types.maybeNull(types.string)
  })
  .actions((self) => ({
    fetch() {
      Framework.getEventManager().publish(constants.CHATUTILITY_GET_USER_PERMISSIONS, {});
    },
    set(response) {
      const arrayOfObj = Object.entries(response.permissions).map((e) => ({ permission: e[1] }));
      applySnapshot(self.data, arrayOfObj);
    }
  }));

export default ChatutilityPermissions;

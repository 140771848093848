import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../../common/constants';
import AssessmentDialog from './AssessmentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  mainDiv: {
    display: 'flex',
    padding: '10px',
    flexDirection: 'column'
  },
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  tabTitle: {
    textTransform: 'capitalize !important',
    minHeight: '55px !important'
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    padding: '15px',
    border: '2px dotted'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginBottom: '5px !important'
  },
  heading: {
    fontWeight: '600'
  },
  dokDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    background: '#f7f9fd',
    width: '40%'
  },
  dokText: { marginBottom: '15px !important' }
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const SelectedChapter = ({ history }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [state, setState] = useState({
    mcq: false,
    mcqdesc: false,
    longBrief: false,
    longSummary: false,
    dok: false
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [showDokDetails, setShowDokDetails] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.dok === true) {
      setShowDokDetails(true);
    } else {
      setShowDokDetails(false);
    }
  }, [state.dok]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    setLoading(true);
    // Simulate uploading process
    /* eslint-disable */
    setTimeout(() => {
      setLoading(false);
      alert(`File "${file.name}" uploaded successfully.`);
      setFile(null);
    }, 2000);
    /* eslint-disable */
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleAutoGenAI = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box>
        <Typography variant="h5" align="center" gutterBottom spacing={9} style={{ p: 5, mt: 5 }}>
          Assessment Basics (REQUIRED)
        </Typography>
      </Box>
      <div className={classes.flexBorder}>
        <div className={classes.mainDiv}>
          <Paper style={{ padding: '15px' }}>
            <Typography variant="h6" align="start" spacing={9} style={{ p: 5, mt: 5 }}>
              1. What should it be based on?
            </Typography>
            <Typography variant="subtitle2" align="start" spacing={9} style={{ p: 5, mt: 5, fontSize: '12px' }}>
              (Choose how you want to generate your assessment items. You can generate them from the Units or Lessons
              which you have selected, or by document you upload, or by text you enter)
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange}>
                  <Tab label="Selected Lesson" className={classes.tabTitle} />
                  <Tab label="Document" className={classes.tabTitle} />
                  <Tab label="Text or Passage" className={classes.tabTitle} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Typography variant="subtitle2" align="start" gutterBottom>
                  {history.location.state.chapterName}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div className={classes.document}>
                  <Typography variant="h4" align="start">
                    Upload PDF, PPT, a word document
                  </Typography>
                  <Typography variant="caption" align="start">
                    Maximum Size 1MB only. Text-Based only files.
                  </Typography>
                  <br />
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx,.txt" // Specify accepted file types
                  />
                  {/* eslint-disable */}
                  <label htmlFor="fileInput">
                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                      Choose File
                    </Button>
                  </label>
                  {/* eslint-disable */}
                  {file && (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                      <Typography variant="subtitle1">Selected File: {file.name}</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        disabled={loading}
                        style={{ marginLeft: '10px' }}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Upload'}
                      </Button>
                    </div>
                  )}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <InputLabel className={classes.subLable}>Type or Copy Paste Here</InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      style={{ width: '80%' }}
                      name="Text"
                      variant="outlined"
                      minRows={6}
                      maxRows={12}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </Box>
          </Paper>
          <br />
          <Paper style={{ padding: '15px' }}>
            <Typography variant="h6" align="start" spacing={9} style={{ p: 5, mt: 5 }}>
              2. Item Type(s)
            </Typography>
            <Typography variant="subtitle2" align="start" spacing={9} style={{ p: 5, mt: 5, fontSize: '12px' }}>
              (Select atleast one type then you can modify quantity.)
            </Typography>
            <div className={classes.root}>
              <Stack sx={{ alignItems: 'center' }}>
                <FormGroup display="inline" spacing={0} style={{ display: 'flex' }}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={state.mcq} onChange={handleChange} name="mcq" />}
                    label={
                      <Typography variant="subtitle2" align="start" gutterBottom>
                        Multiple Choice - Standard
                      </Typography>
                    }
                    style={{ alignItems: 'center', padding: '0px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={state.mcqdesc} onChange={handleChange} name="mcqdesc" />
                    }
                    style={{ alignItems: 'center', padding: '0px' }}
                    label={
                      <Typography variant="subtitle2" align="start" gutterBottom>
                        Multiple Choice - Descriptive
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={state.longBrief} onChange={handleChange} name="longBrief" />
                    }
                    style={{ alignItems: 'center', padding: '0px' }}
                    label={
                      <Typography variant="subtitle2" align="start" gutterBottom>
                        Long Answer - brief
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.longSummary}
                        onChange={handleChange}
                        name="longSummary"
                      />
                    }
                    style={{ alignItems: 'center', padding: '0px' }}
                    label={
                      <Typography variant="subtitle2" align="start" gutterBottom>
                        Long Answer - Summary
                      </Typography>
                    }
                  />
                </FormGroup>
              </Stack>
            </div>
          </Paper>
          <br />
          <div>
            <Accordion style={{ minHeight: '0px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>Other Details (OPTIONAL)</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: 'flex', flexDirection: 'column', paddingTop: '0', paddingLeft: '5%' }}
              >
                <Typography variant="h6" align="start" spacing={9} style={{ p: 5, mt: 5 }}>
                  1. Depth of Knowledge (DOK)
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={state.dok}
                        onChange={handleChange}
                        name="dok"
                        color="primary"
                      />
                    }
                    style={{ alignItems: 'center', padding: '0px' }}
                    label={
                      <Typography variant="subtitle2" align="start" gutterBottom>
                        I would like to control the Depth of Knowledge.
                      </Typography>
                    }
                  />
                </FormGroup>
                {showDokDetails && (
                  <>
                    <Typography variant="subtitle2" align="start" spacing={9} style={{ p: 5, mt: 5, fontSize: '12px' }}>
                      Please select the allocation of DOK for AI generated items
                    </Typography>
                    <Box className={classes.dokDetails}>
                      <TextField label="Level 1 - Recall" variant="outlined" size="small" className={classes.dokText} />
                      <TextField
                        label="Level 2 - Skill/Concept"
                        variant="outlined"
                        size="small"
                        className={classes.dokText}
                      />
                      <TextField
                        label="Level 3 - Strategic Thinking"
                        variant="outlined"
                        size="small"
                        className={classes.dokText}
                      />
                      <TextField
                        label="Level 4 - Extended Thinking"
                        variant="outlined"
                        size="small"
                        className={classes.dokText}
                      />
                      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="subtitle2" align="end" spacing={9} style={{ p: 5, mt: 5 }}>
                          Total 0
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          align="end"
                          spacing={9}
                          style={{ p: 5, mt: 5, fontSize: '11px' }}
                        >
                          Remaining 8
                        </Typography>
                      </Box>
                    </Box>
                    <br />
                  </>
                )}
                <Typography variant="h6" align="start" spacing={9} style={{ p: 5, mt: 5 }}>
                  2. Do you have any other details to add?
                </Typography>
                <Typography variant="subtitle2" align="start" spacing={9} style={{ p: 5, mt: 5, fontSize: '12px' }}>
                  (Add any aditional instructions for AI to use, eg.. the tone of assessment or anything in particular
                  you want the assessment to focus on.)
                </Typography>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <InputLabel className={classes.subLable}>Enter Additional Instructions</InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      style={{ width: '80%' }}
                      name="Text"
                      variant="outlined"
                      minRows={8}
                      maxRows={16}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={classes.flexContainerBottom}>
          <div className={classes.ButtonClass}>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="small" onClick={() => handleAutoGenAI()} sx={{ background: '#005d83' }}>
                {constants.AUTO_GENERATE_AI}
              </Button>
              <Button variant="contained" size="small" sx={{ background: '#005d83' }} onClick={() => history.goBack()}>
                BACK
              </Button>
            </Stack>
          </div>
        </div>
      </div>
      {openDialog && <AssessmentDialog openDialog={openDialog} handleClose={handleClose} history={history} />}
    </>
  );
};

SelectedChapter.propTypes = {
  history: PropTypes.object.isRequired
};
export default SelectedChapter;

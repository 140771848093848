import { createTheme } from '@mui/material/styles';
// import * as constants from '../../common/constants';

export const AIADMIN_PRIMARY_COLOR = '#1A202C';

// Common settings shared by both light and dark themes
const commonThemeSettings = {
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '0.5px'
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: '0.4px'
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0.3px'
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.2px'
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
      color: '#333333'
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: '0.1px',
      color: '#333333'
    },
    body1: {
      fontSize: '1rem',
      color: '#333333'
    },
    body2: {
      fontSize: '0.875rem',
      color: '#333333',
      textAlign: 'left'
    },
    button: {
      textTransform: 'uppercase' // Uppercase text for buttons
    }
  },
  spacing: 8, // Base spacing unit
  shape: {
    borderRadius: 8 // Global border-radius for rounded corners
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
    modal: 1300
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)'
    },
    duration: {
      standard: 300,
      short: 150,
      long: 600
    }
  },

  // Default border settings (color and width)
  border: {
    color: AIADMIN_PRIMARY_COLOR, // Default border color
    width: '2px', // Default border width
    radius: '8px' // Default border radius
  },

  // Button, Input, Dropdown, Radio Button, and Border Styling
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 12, // All buttons will have rounded corners
        borderWidth: '2px', // Button border width
        borderColor: '#1976d2', // Border color for outlined buttons
        color: '#ffffff !important'
      },
      disabled: {
        backgroundColor: '#dcdcdc !important', // Set your desired background color
        color: '#999', // Set your desired text color
        cursor: 'not-allowed !important'
      },
      contained: {
        color: '#ffffff !important',
        padding: '8px 16px !important',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' // Custom button shadow for light theme
      },
      outlined: {
        borderWidth: '2px', // Border width for outlined buttons
        borderColor: '#1976d2', // Border color for outlined buttons
        '&:hover': {
          borderColor: '#115293' // Darker border color on hover
        }
      },
      text: {
        padding: '8px 16px' // Padding for text buttons
      }
    },
    // Input Box (TextField / OutlinedInput) Styles
    MuiTextField: {
      root: {
        marginBottom: '16px' // Space below input fields
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '12px', // Rounded corners for outlined input fields
        borderWidth: '2px', // Border width
        borderColor: '#1976d2', // Default border color
        '&.Mui-focused': {
          borderColor: '#1976d2' // Border color when focused
        },
        '&:hover': {
          borderColor: '#115293' // Darker border color on hover
        }
      },
      input: {
        padding: '12px 16px', // Padding inside the input box
        fontSize: '1rem', // Font size for the input text
        color: '#333' // Text color inside the input box
      }
    },
    MuiInputLabel: {
      root: {
        color: '#555', // Label color in the light theme
        '&.Mui-focused': {
          color: '#1976d2' // Label color when focused
        }
      }
    },

    MuiSelect: {
      root: {
        borderRadius: '12px', // Ensure consistent rounded corners
        border: '2px solid #ccc', // Default border
        '&:hover': {
          borderColor: '#1976d2' // Hover state color
        },
        '&.Mui-focused': {
          borderColor: '#1976d2' // Focus state color
        }
      },
      select: {
        padding: '12px', // Ensure proper padding inside the select field
        borderRadius: '12px'
      },
      icon: {
        color: '#1976d2' // Dropdown arrow color
      }
    },

    // Radio Button Styles
    MuiRadio: {
      root: {
        color: '#1976d2', // Default radio button color
        '&.Mui-checked': {
          color: '#1976d2' // Color when checked
        },
        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.04)' // Hover effect (light blue background on hover)
        },
        '&:focus': {
          backgroundColor: 'transparent' // Remove focus outline background
        },
        borderWidth: '2px', // Border width for radio buttons
        borderColor: '#1976d2' // Border color for radio buttons
      }
    },

    MuiFormControlLabel: {
      label: {
        fontSize: '1rem', // Font size for label
        color: '#333', // Default color for label
        '&.Mui-checked': {
          color: '#1976d2' // Label color when radio button is selected
        }
      }
    },

    // Card and Paper Components - Global Border Styles
    MuiCard: {
      root: {
        borderWidth: '2px', // Border width for cards
        borderColor: '#1976d2', // Border color for cards
        borderRadius: '8px', // Rounded corners for cards
        boxShadow: 'none' // Optional: Set no box shadow (or add your own)
      }
    },
    MuiPaper: {
      root: {
        borderWidth: '2px', // Border width for paper components (like Dialogs, Containers, etc.)
        borderColor: '#1976d2', // Border color for paper components
        borderRadius: '8px' // Rounded corners for paper components
      }
    }
  }
};

export const getLightTheme = () => {
  return createTheme({
    ...commonThemeSettings, // Spread the common settings
    palette: {
      mode: 'light', // Theme mode: 'light' or 'dark'
      primary: {
        main: '#1976d2', // Primary color (blue)
        contrastText: '#fff' // Text color for primary elements (white)
      },
      secondary: {
        main: '#f50057', // Secondary color (pink)
        contrastText: '#fff' // Text color for secondary elements (white)
      },
      background: {
        default: '#fafafa', // Default background color for the app
        paper: '#ffffff', // Background color for paper components
        lightGray: '#f1f1f1', // Custom background color (light gray)
        lightBlue: '#e3f2fd', // Custom background color (light blue)
        accent: '#fce4ec' // Custom accent background (pinkish)
      },
      text: {
        primary: '#000000', // Primary text color
        secondary: '#555555' // Secondary text color
      },
      action: {
        active: '#1976d2', // Active state color for actions like icons
        hover: '#1976d2', // Hover state color
        selected: '#1976d2' // Selected state color
      }
    },
    shadows: [
      'none',
      '0px 2px 10px rgba(0, 0, 0, 0.1)',
      '0px 4px 20px rgba(0, 0, 0, 0.1)',
      '0px 6px 30px rgba(0, 0, 0, 0.15)'
    ],
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 12 // All buttons will have rounded corners
        },
        contained: {
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' // Custom button shadow
        }
      }
    }
  });
};

export const getDarkTheme = () => {
  return createTheme({
    ...commonThemeSettings, // Spread the common settings
    palette: {
      mode: 'dark', // Theme mode: 'light' or 'dark'
      primary: {
        main: AIADMIN_PRIMARY_COLOR, // Primary color (light blue)
        contrastText: '#000' // Text color for primary elements (black)
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#000' // Text color for secondary elements (black)
      },
      background: {
        default: '#121212', // Default background color for the app
        paper: '#ffffff', // Background color for paper components //#1d1d1d
        darkGray: '#333333', // Custom background color (dark gray)
        darkBlue: AIADMIN_PRIMARY_COLOR, // Custom background color (dark blue) //#263238
        accent: '#3e2723' // Custom accent background (brownish)
      },
      border: {
        darkBlue: AIADMIN_PRIMARY_COLOR
      },
      text: {
        primary: '#ffffff', // Primary text color
        secondary: '#b0bec5' // Secondary text color
      },
      action: {
        active: '#90caf9', // Active state color for actions like icons
        hover: AIADMIN_PRIMARY_COLOR, // Hover state color
        selected: '#90caf9' // Selected state color
      }
    },
    // shadows: [
    //   'none',
    //   '0px 2px 10px rgba(0, 0, 0, 0.2)',
    //   '0px 4px 20px rgba(0, 0, 0, 0.3)',
    //   '0px 6px 30px rgba(0, 0, 0, 0.4)'
    // ],
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              color: '#333333' // Default text color inside the TextField
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: AIADMIN_PRIMARY_COLOR // Default border color
              },
              '&.Mui-disabled': {
                color: '#c7c7c7 !important', // Make text visible
                '-webkit-text-fill-color': '#c7c7c7 !important', // Override for Webkit browsers
                opacity: 1, // Ensure full opacity,
                border: '1px solid #000'
              },
              '&:hover fieldset': {
                borderColor: AIADMIN_PRIMARY_COLOR // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: AIADMIN_PRIMARY_COLOR // Focus border color
              },
              border: '1px solid #c7c7c7'
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            // Override the default background color for the root of the Autocomplete
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: AIADMIN_PRIMARY_COLOR
            },
            '& .MuiOutlinedInput-root': {
              '& .MuiInputBase-input': {
                color: '#333' // Custom text color for the input text
              },
              '& .MuiIconButton-root': {
                color: '#333' // Custom text color for the input text
              }
            }
          },
          listbox: {
            '& .MuiAutocomplete-option': {
              '&:hover': {
                backgroundColor: AIADMIN_PRIMARY_COLOR, // Background color on hover in the dropdown
                color: '#fff' // Text color on hover in the dropdown
              },
              '&.Mui-focused': {
                backgroundColor: AIADMIN_PRIMARY_COLOR, // Focused state background color for the option
                color: '#ffffff' // Focused option text color
              }
            }
          }
        }
      },
      // Style overrides for ListItem (Drawer and other lists)
      MuiListItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#1a2029', // Hover background color
              color: '#fff' // Hover text color
            }
          }
        }
      },
      // Style overrides for Drawer menu ListItems
      MuiListItemText: {
        styleOverrides: {
          primary: {
            '&:hover': {
              color: '#fff' // Ensure text changes to white on hover
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: AIADMIN_PRIMARY_COLOR, // Background color on hover
              color: '#fff' // Text color on hover
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            // backgroundColor: 'lightblue', // Change background color of the select box
            borderRadius: '8px', // Round the corners of the select box
            // padding: '8px 16px', // Padding inside the select box
            '&:focus': {
              backgroundColor: 'lightblue', // Focused state background color
              borderColor: 'red' // Border color on focus
            },
            '&.Mui-disabled': {
              color: '#c7c7c7 !important',
              '-webkit-text-fill-color': '#c7c7c7 !important',
              opacity: 1,
              borderColor: '1px solid #000'
            }
          },
          select: {
            '&.Mui-disabled': {
              color: '#c7c7c7 !important', // Visible text
              '-webkit-text-fill-color': '#c7c7c7 !important'
            }
          },
          icon: {
            color: '#000000',
            '&.Mui-disabled': {
              color: '#c7c7c7'
            } // Change color of the dropdown icon (arrow)
          },
          outlined: {
            // border: '1px solid #c7c7c7' // Border style for outlined variant
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: '#fff',
            borderRadius: '12px',
            padding: '8px 16px',
            backgroundColor: AIADMIN_PRIMARY_COLOR,
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.7)'
            },
            '&:hover::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '12px', // Same as the Button's borderRadius
              padding: '2px', // Thickness of the outline
              background: 'linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%)',
              WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              pointerEvents: 'none',
              zIndex: 0
            },
            '&.Mui-disabled': {
              backgroundColor: '#b0bec5',
              color: '#607d8b'
            }
          },
          // disabled: {
          //   backgroundColor: '#b0bec5',
          //   color: '#607d8b'
          // },
          sizeSmall: {
            '& span': {
              minWidth: 'auto'
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#ffffff'
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            color: '#333'
          }
        }
      },
      // Override TableCell
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: '#333'
            // padding: '8px'
          }
        }
      },
      // Override TableHeader
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: AIADMIN_PRIMARY_COLOR
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: '#fff' // White color for sort label in header
          }
        }
      },
      // Override TablePagination
      MuiTablePagination: {
        styleOverrides: {
          root: {
            backgroundColor: '#f1f1f1', // Custom background color for pagination area
            color: '#333', // Custom text color for pagination
            borderTop: '2px solid #333', // Border for pagination
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px'
          },
          select: {
            color: '#333' // Color for rows per page select dropdown
          },
          actions: {
            color: '#fff'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#1A202C'
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            margin: 'auto'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6a7070'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ccc'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2'
          }
        }
      }
    }
  });
};

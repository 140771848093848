import MomentUtils from '@date-io/moment';
import { Box, Button, Grid } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../../../../common/constants';
import AutocompleteComponent from '../../common/components/Autocomplete';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

const DynamicTrendsComponent = React.memo((props) => {
  const {
    classes,
    searchFields,
    endDisabled,
    showDateFields,
    showOutline,
    clusterState,
    bookList,
    inputValue,
    handleSelectChange,
    onAutoCompleteChange,
    onInputChange,
    handleDateChange,
    handleDateclicker,
    trendsDataFetch,
    tenantName
  } = props;
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
              <FormControl sx={{ minWidth: '39%', maxWidth: '90%', mr: 5 }} size="small">
                <AutocompleteComponent
                  options={bookList}
                  onAutoCompleteChange={onAutoCompleteChange}
                  onInputChange={onInputChange}
                  stateVal={inputValue}
                  errorVal=""
                  name="bookId"
                  label={tenantName !== 'Reader+' ? 'Course Id' : 'Book Id'}
                />
              </FormControl>
              <Box className={classes.marginBox} sx={{ '& button': { m: 31, p: 12 } }}>
                <Stack direction="row" spacing={1}>
                  <Button
                    size="small"
                    variant={showOutline === '1D' ? 'outlined' : 'text'}
                    style={{ color: '#0d0c0c8c' }}
                    onClick={(event) => handleDateclicker(event, '1D')}
                  >
                    1d
                  </Button>
                  <Button
                    size="small"
                    style={{ color: '#0d0c0c8c' }}
                    variant={showOutline === '3D' ? 'outlined' : 'text'}
                    onClick={(event) => handleDateclicker(event, '3D')}
                  >
                    3d
                  </Button>
                  <Button
                    size="small"
                    style={{ color: '#0d0c0c8c' }}
                    variant={showOutline === '1W' ? 'outlined' : 'text'}
                    onClick={(event) => handleDateclicker(event, '1W')}
                  >
                    1w
                  </Button>
                  <Button
                    size="small"
                    variant={showOutline === 'custom' ? 'outlined' : 'text'}
                    style={{ color: '#0d0c0c8c' }}
                    onClick={() => trendsDataFetch('custom')}
                  >
                    custom
                    <CalendarMonthIcon />
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <br />
        {showDateFields && (
          <Grid container direction="row">
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      format={constants.MM_DD_YYYY_FORMAT}
                      autoOk
                      size="small"
                      variant="inline"
                      inputVariant="outlined"
                      style={{ minWidth: '32%', marginRight: '6%' }}
                      disableFuture
                      value={searchFields.startDate || null}
                      label="Start Date"
                      onChange={(date) => handleDateChange(date, 'startDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      // margin="normal"
                      format={constants.MM_DD_YYYY_FORMAT}
                      autoOk
                      variant="inline"
                      size="small"
                      style={{ minWidth: '32%' }}
                      inputVariant="outlined"
                      disableFuture
                      value={searchFields.endDate || null}
                      label="End Date"
                      onChange={(date) => handleDateChange(date, 'endDate')}
                      disabled={endDisabled}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Box>
              <br />
            </Grid>
            <Grid item xs={2} />
          </Grid>
        )}
        <Grid container direction="row">
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Box sx={{ pl: '15%' }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Report By
                </InputLabel>
                <NativeSelect
                  onChange={(e) => handleSelectChange(e)}
                  value={clusterState}
                  inputProps={{
                    name: 'age',
                    id: 'uncontrolled-native'
                  }}
                >
                  <option value="cluster">{constants.CLUSTERING}</option>
                  <option value="count">{constants.COUNT}</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} />
          <br />
        </Grid>
        <br />
      </Box>
      <br />
    </>
  );
});

DynamicTrendsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  searchFields: PropTypes.object.isRequired,
  endDisabled: PropTypes.bool.isRequired,
  showDateFields: PropTypes.bool.isRequired,
  showOutline: PropTypes.string,
  clusterState: PropTypes.string,
  bookList: PropTypes.array.isRequired,
  inputValue: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func,
  handleDateclicker: PropTypes.func.isRequired,
  trendsDataFetch: PropTypes.func.isRequired,
  tenantName: PropTypes.string
};

DynamicTrendsComponent.defaultProps = {
  handleDateChange: () => {},
  clusterState: 'cluster',
  showOutline: '1D',
  tenantName: ''
};

export default withStyles(styles)(DynamicTrendsComponent);

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AssistantIcon from '@material-ui/icons/Assistant';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import PVSBookData from './Biology_B_manifest.json';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  heading: {
    marginTop: '20px !important'
  },
  extendedIcon: {
    marginRight: '8px'
  },
  lesson: {
    height: '200px',
    width: '200px',
    textAlign: 'center'
  },
  divContainer: {
    position: 'relative',
    border: '1px solid #ccc',
    height: '200px',
    width: '300px',
    background: '#ecebeb',
    margin: '15px'
  },
  centeredDiv: {
    position: 'absolute',
    top: '65%',
    left: '53%',
    transform: 'translate(-50%, -50%)',
    width: '200px',
    height: '100px'
  },
  chipClass: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  accordionHeader: {
    minHeight: '0px'
  }
});

const SelectLesson = ({ classes, history }) => {
  const selectedData = history.location.state.data;
  const [showLessons, setShowLessons] = useState(false);
  const [lessonsData, setLessonsData] = useState([]);

  useEffect(() => {
    (async () => {
      const getLessonsData = async (obj, results = []) => {
        /* eslint-disable */
        // Check if the current object is an array
        if (Array.isArray(obj)) {
          // If it's an array, iterate over each item and recursively call the function
          obj.forEach((item) => getLessonsData(item, results));
        } else if (typeof obj === 'object' && obj !== null) {
          if (
            obj.hasOwnProperty('contents') &&
            obj.contents.hasOwnProperty('title') &&
            obj.contents.title.hasOwnProperty('text') &&
            obj.contents.hasOwnProperty('bodymatter') &&
            obj.contents.bodymatter[0].type === 'xhtml-content'
          ) {
            results.push({
              contentUrn: obj && obj.contents && obj.contents.bodymatter[0] && obj.contents.bodymatter[0].contentUrn,
              text: obj && obj.contents && obj.contents.title && obj.contents.title.text
            });
          }
          // Recursively call the function for each property in the object
          Object.values(obj).forEach((val) => getLessonsData(val, results));
          /* eslint-disable */
        }
        return results;
      };

      const resData = await getLessonsData(PVSBookData);
      setLessonsData(resData);
    })();
  }, []);

  const handleLessonClick = () => {
    setShowLessons(true);
  };
  const handleChapterClick = (item) => {
    history.push('/chatgptutility/pvsassessment/selectlesson/chapter', {
      chapterName: item.text,
      sourceId: item.contentUrn
    });
  };
  const renderLessonsList = () => {
    return (
      <div>
        <>
          <Typography variant="h4" align="start" gutterBottom style={{ padding: '8px' }}>
            <AssistantIcon /> Course Outline
          </Typography>
        </>
        {lessonsData.map((item, index) => (
          <ul style={{ listStyleType: 'none' }}>
            <li
              key={index}
              style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#fafaf9', cursor: 'pointer' }}
              onClick={() => handleChapterClick(item)}
            >
              {`${index + 1} : ${item.text}`}
            </li>
          </ul>
        ))}
        <br />
      </div>
    );
  };

  return (
    <div className={classes.flexBorder}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" align="center" gutterBottom spacing={9} style={{ p: 5, mt: 5 }}>
          {selectedData.assessmentName}
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <>
            {selectedData.type && (
              <>
                Type:
                <Chip
                  label={selectedData.type}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  className={classes.chipClass}
                />
              </>
            )}
            Status:
            <Chip
              label={selectedData.status}
              variant="outlined"
              color={selectedData.status === 'Published' ? 'primary' : 'secondary'}
              size="medium"
              className={classes.chipClass}
            />
          </>
        </div>
      </Box>
      <div style={{ display: 'flex' }}>
        <div className={classes.divContainer}>
          <div className={classes.centeredDiv}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FolderSharedIcon />}
              onClick={handleLessonClick}
              className={classes.button}
            >
              Select Course
            </Button>
          </div>
        </div>
        <div className={classes.divContainer}>
          <div className={classes.centeredDiv}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<MenuBookIcon />}
              onClick={handleLessonClick}
              className={classes.button}
            >
              Select Lesson
            </Button>
          </div>
        </div>
      </div>
      <br />
      {showLessons && <>{renderLessonsList()}</>}
      <br />
    </div>
  );
};

SelectLesson.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectLesson);

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  textareaHeight: {
    marginTop: '5px'
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const ChatBotDialog = (props) => {
  const { classes } = props;
  const { onClose, open, taskDetails, description, handleChange, saveDescription } = props;

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ style: { width: '60%' } }}
    >
      <DialogTitle>{taskDetails.task}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers style={{ textAlign: 'center' }}>
        <TextareaAutosize
          className={classes.textareaHeight}
          placeholder="Add Your Details Here"
          onChange={($event) => handleChange($event)}
          name="description"
          minRows={3}
          maxRows={6}
          style={{ minWidth: '100%' }}
          value={description}
        />
      </DialogContent>
      <DialogActions>
        <Button id="saveBtn" variant="outlined" color="primary" size="small" onClick={saveDescription}>
          Save
        </Button>
        <Button id="cancelBtn" variant="outlined" color="primary" size="small" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

ChatBotDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.object.isRequired,
  onClose: PropTypes.object.isRequired,
  taskDetails: PropTypes.object.isRequired,
  saveDescription: PropTypes.object.isRequired,
  handleChange: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired
};

export default withStyles(styles)(ChatBotDialog);

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Typography } from '@mui/material';

// CustomLoader Component
const CustomLoader = ({ size, color, thickness, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <CircularProgress
        size={size} // Size of the spinner
        color={color} // Spinner color
        thickness={thickness} // Thickness of the spinner stroke
      />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        {label}
      </Typography>
    </Box>
  );
};

// Prop type validation using PropTypes
CustomLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit', 'error', 'success', 'info', 'warning', 'default']), // Color options for the loader
  thickness: PropTypes.number,
  label: PropTypes.string
};

// Default props
CustomLoader.defaultProps = {
  size: 50,
  color: 'primary',
  thickness: 3,
  label: 'Loading...'
};

export default CustomLoader;

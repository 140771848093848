import React from 'react';
import { Box, Typography, Table, TableContainer, TableBody, TableRow, TableCell } from '@mui/material';
import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import SubTypeResponse from '../../models/SubTypeModel';
import ExpandableCard from '../DynamicComponent/dataGrid/ExpandableCard';

const ChatSubTypeResponse = ({ ChatUtilitySubTypeResponse }) => {
  const { SubTypeData } = ChatUtilitySubTypeResponse;
  const contentFilterTableHeader = () => {
    return (
      <TableContainer>
        <Table>
          <TableRow style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <TableCell style={{ paddingTop: '2px', paddingBottom: '2px', fontWeight: 'bold' }}>Category</TableCell>
            <TableCell style={{ paddingTop: '2px', paddingBottom: '2px', fontWeight: 'bold' }}>Severity</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    );
  };

  // Helper function to render a response card
  const renderResponseCard = (header, content) => {
    return (
      <ExpandableCard
        maxHeight="300px"
        header={header}
        body={
          <>
            {header === 'Content Filter Response' ? contentFilterTableHeader() : <></>}
            {content}
          </>
        }
      />
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      {SubTypeData && SubTypeData.length > 0 ? (
        <Box sx={{ margin: '0 8px' }}>
          {SubTypeData?.map((typeData) => {
            if (typeData.type === 'content_filter') {
              return renderResponseCard(
                'Content Filter Response',
                typeData.botResponse?.Category_Severity?.map((data) => (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ maxWidth: '54px', paddingTop: '2px', paddingBottom: '2px' }}>
                            <Typography variant="body2">{data.Category}</Typography>
                          </TableCell>
                          <TableCell style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                            <Typography variant="body2">{data.Severity}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))
              );
            }

            if (typeData.type === 'chat_intent') {
              return renderResponseCard(
                'Chat Intent Response',
                typeData.botResponse?.chatIntentResponse?.map((response, index) => (
                  <Box key={index} sx={{ marginBottom: '8px' }}>
                    {Object.entries(response).map(([key, value]) => (
                      <Typography key={key} variant="body2">
                        <strong>{key} :</strong> {value}
                      </Typography>
                    ))}
                  </Box>
                ))
              );
            }

            if (typeData.type === 'explain_stand_alone') {
              return renderResponseCard(
                'Explain Standalone Prompt',
                typeData.prompt?.map((data, index) => (
                  <Box key={index} sx={{ marginBottom: '8px' }}>
                    <Typography variant="body2" fontWeight="bold">
                      Role: {data.role}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      Prompt:
                    </Typography>
                    <Typography variant="body2">{data.prompt}</Typography>
                  </Box>
                ))
              );
            }
            return null;
          })}
          {SubTypeData?.map((typeData) => {
            if (typeData.type === 'explain_stand_alone') {
              return renderResponseCard(
                'Explain Standalone Response',
                <Box sx={{ marginBottom: '8px' }}>
                  <Typography variant="body2">{typeData.botResponse.explainStandAloneBotResponse}</Typography>
                </Box>
              );
            }
            return null;
          })}
        </Box>
      ) : (
        <Box sx={{ padding: 2 }}>No records founds!</Box>
      )}
    </Box>
  );
};

ChatSubTypeResponse.propTypes = {
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
};

export default inject('ChatUtilitySubTypeResponse')(observer(ChatSubTypeResponse));

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Grid } from '@mui/material';
import * as constants from '../../../../common/constants';

const styles = () => ({
  buttonStyle: {}
});

const ButtonComponent = (props) => {
  const {
    classes,
    title,
    handleClick,
    disabled,
    icon,
    toolTiptext,
    secondButtonTitle,
    secondButtonClick,
    secondButtonDisabled
  } = props;
  return (
    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Button
          variant="contained"
          size="small"
          color="primary"
          title={toolTiptext}
          onClick={() => handleClick()}
          className={classes.buttonStyle}
          disabled={disabled}
          startIcon={icon}
        >
          {title || constants.CHAT_SEARCH}
        </Button>
      </Grid>
      {secondButtonTitle && (
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            title={secondButtonTitle}
            onClick={() => secondButtonClick()}
            className={classes.buttonStyle}
            disabled={secondButtonDisabled}
          >
            {secondButtonTitle || constants.CHAT_SEARCH}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ButtonComponent.defaultProps = {
  title: null,
  disabled: false,
  toolTiptext: '',
  secondButtonTitle: null,
  secondButtonClick: () => null,
  secondButtonDisabled: false,
  icon: null
};
ButtonComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  secondButtonTitle: PropTypes.string,
  secondButtonClick: PropTypes.func,
  secondButtonDisabled: PropTypes.bool,
  toolTiptext: PropTypes.string,
  icon: PropTypes.node
};

export default withStyles(styles)(ButtonComponent);

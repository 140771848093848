import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Box } from '@mui/material';

const CustomTooltip = ({ content, bgColor, children, ...props }) => {
  const renderContent = typeof content === 'string' ? <span>{content}</span> : content;

  return (
    <Tooltip
      {...props}
      title={<Box sx={{ backgroundColor: bgColor, color: '#fff', padding: 1, borderRadius: 1 }}>{renderContent}</Box>}
      arrow
      sx={{
        '.MuiTooltip-tooltip': {
          backgroundColor: bgColor,
          borderRadius: '4px'
        },
        '.MuiTooltip-arrow': {
          color: bgColor
        }
      }}
    >
      {children}
    </Tooltip>
  );
};

// PropTypes validation
CustomTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // content should be a string or React component
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired // children should be a valid React element
};

// Default props for optional props
CustomTooltip.defaultProps = {
  bgColor: 'rgba(0, 0, 0, 0.87)'
};

export default CustomTooltip;

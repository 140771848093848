import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const allRoles = types.model('AllRoles', {
  role: types.maybeNull(types.string),
  role_type_id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  permissions: types.maybeNull(types.array(types.string), [])
});

const ChatutilityRoles = types
  .model('ChatutilityRoles', {
    data: types.optional(types.array(allRoles), []),
    success: types.maybeNull(types.string)
  })
  .actions((self) => ({
    fetch() {
      Framework.getEventManager().publish(constants.CHATUTILITY_GET_USER_ROLES, {});
    },
    set(response) {
      applySnapshot(self.data, response.data);
    }
  }));

export default ChatutilityRoles;

import Framework, { BaseService } from '@greenville/framework';
import _ from 'lodash';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class CommonSearchUtilityService extends BaseService {
  constructor() {
    super(constants.GET_COMMON_SEARCH_UTILITY_DATA, 'commonSearchUtilityStatus');
  }

  handleEvent(eventName, event) {
    let urlPathName = '';
    let payload = {};
    if (event.payload.selectedTabValue === 0) {
      urlPathName = '/chat/table';
      payload = _.omit(event.payload, ['selectedTabValue']);
    }
    if (event.payload.selectedTabValue === 1) {
      urlPathName = '/userFeedback/table';
      payload = _.omit(event.payload, ['selectedTabValue']);
    }
    if (event.payload.selectedTabValue === 2) {
      if (event.payload.clusterState === 'count') {
        urlPathName = '/chatutility/getcounts';
        payload = _.omit(event.payload, ['selectedTabValue', 'clusterState', 'startDateTime', 'endDateTime']);
      }
      if (event.payload.clusterState === 'cluster') {
        urlPathName = '/chatutility/TrendsInsights';
        payload = _.omit(event.payload, ['selectedTabValue', 'clusterState', 'startDateTime', 'endDateTime']);
      }
    }
    return this.getClient('evergreen').post(urlPathName, payload);
  }

  handleResponse(response) {
    const { data } = response;
    if (data.status === 'success') {
      const notification = {
        open: true,
        type: 'success',
        message: 'Data Fetched successfully'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      Framework.getStoreRegistry().getStore('commonSearchUtility').setResponse(response.data);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    } else if (error.message === 'timeout of 60000ms exceeded') {
      const notification = {
        open: true,
        message: error.message,
        type: 'error'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
    Framework.getStoreRegistry().getStore('commonSearchUtility').resetStoreValues();
  }
}

import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { useTheme, Box, Card, CardContent, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../../common/Loader';
import * as constants from '../../../../../common/constants';
import utils from '../../../../../common/utils';
import SubTypeResponse from '../../../models/SubTypeModel';
import ExpandableCard from './ExpandableCard';
import CustomSwitchComponent from '../../../common/components/CustomSwitchComponent';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  aiStudyToolcard: {
    height: '435px',
    overflowY: 'auto !important'
  },
  inCreaseAiStudyToolcard: {
    overflowY: 'auto !important',
    height: '585px'
  },
  promptCard: {
    overflowY: 'auto !important',
    maxHeight: '235px'
  },
  minimizedPromptCard: {
    overflowY: 'auto !important',
    height: '130px'
  },
  chatIntentCard: {
    overflowY: 'auto !important',
    height: '200px',
    fontSize: '12px'
  },
  subTypeModel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const DynamicDialog = ({
  classes,
  dataValue,
  filterPromt,
  chatgptUtilityResponseStatus,
  isFeedback,
  loaderStatusResponse,
  ChatUtilitySubTypeResponse,
  chatgptUtilitySubTypeResponseStatus,
  tenantTitle
}) => {
  const { SubTypeData } = ChatUtilitySubTypeResponse;
  const [checkRectifierData, setCheckRecitifierData] = useState({});

  const [isChecked, setIsChecked] = useState(false);
  const [rawResponse, setRawResponse] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (!_.isEmpty(dataValue.botResponse)) {
      if (isChecked) {
        setRawResponse(JSON.stringify(dataValue?.botResponse, null, 2).slice(1, -1));
      } else {
        setRawResponse('');
      }
    }
  }, [isChecked]);

  useEffect(() => {
    if (!_.isEmpty(dataValue) && !chatgptUtilitySubTypeResponseStatus.isPending) {
      const { requestId, bookId, type } = dataValue;
      if (type && type === constants.PROBLEM_SOLVE_RECTIFIER) {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_VERIFIER });
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
      }
      if (type && type === constants.PROBLEM_SOLVE) {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_VERIFIER });
        Framework.getEventManager().on(constants.SET_PROBLEM_SOLVE_DATA, () => {
          if (SubTypeData.length > 0) {
            const findValue = SubTypeData.find(
              (data) =>
                data.bookId === bookId && data.requestId === requestId && data.type === constants.PROBLEM_SOLVE_VERIFIER
            );
            if (findValue) {
              setCheckRecitifierData(findValue);
            }
          }
        });
      }
    }
  }, [dataValue, chatgptUtilitySubTypeResponseStatus]);

  useEffect(() => {
    if (!_.isEmpty(checkRectifierData) && checkRectifierData.botResponse === 'False') {
      const { requestId, bookId } = dataValue;
      ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_RECTIFIER });
    }
  }, [checkRectifierData]);

  const handleAlterIndex = (index) => {
    let defIndex = '';
    switch (index) {
      case 0:
        defIndex = 'a)';
        break;
      case 1:
        defIndex = 'b)';
        break;
      case 2:
        defIndex = 'c)';
        break;
      case 3:
        defIndex = 'd)';
        break;
      default:
    }
    return defIndex;
  };
  const renderResponsePrompts = () => {
    if (dataValue && dataValue.prompts && dataValue.prompts.length > 0) {
      return dataValue.prompts.map((data) => {
        return <Typography variant="body2">{`${data.name} Version = ${data.version} (${data.status})`}</Typography>;
      });
    }
    return <></>;
  };

  const renderMathJaxResponse = (response) => {
    const regesXmlns = /<math([^>]*)>/g;
    const res = utils.containsMathML(response)
      ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
      : response;
    return (
      <>
        {/* eslint-disable */}
        <span
          ref={(el) => {
            utils.processMath(el);
          }}
          dangerouslySetInnerHTML={{ __html: res }}
        />
        {/* eslint-disable */}
      </>
    );
  };

  const checkMathJax = (mathJaxData) => {
    let response = mathJaxData;
    if (!isFeedback && !_.isEmpty(response)) {
      if (utils.containsMarkDownText(response)) {
        response = utils.convertToMarkedDownString(response);
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\'))
        return renderMathJaxResponse(response);
    }
    if (isFeedback && !_.isEmpty(response)) {
      if (typeof response === 'object') {
        return (
          Object.keys(response).length > 0 &&
          Object.keys(response).map((val) => {
            return (
              <>
                {response[val] &&
                  Array.isArray(response[val]) &&
                  response[val].length > 0 &&
                  response[val].map((arr, index) => (
                    <>
                      <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                      <Typography variant="body2" key={index}>
                        <>
                          {/* eslint-disable-next-line */}
                          <span
                            ref={(el) => {
                              utils.processMath(el);
                            }}
                            dangerouslySetInnerHTML={{ __html: arr }}
                          />
                        </>
                      </Typography>
                    </>
                  ))}
              </>
            );
          })
        );
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\')) {
        return renderMathJaxResponse(response);
      }
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: response }} />
      </>
    );
  };
  const renderExpertDeskResponse = (data) => {
    if (data && data.flashcards && data.flashcards.length > 0) {
      const flashcardsArr = data.flashcards;
      return flashcardsArr.map((item) => {
        return (
          <>
            {item.type && (
              <Typography align="left" variant="subtitle1">
                type : {item.type}
              </Typography>
            )}
            {item.front_side && (
              <Typography align="left" variant="subtitle1">
                front_side : {item.front_side}
              </Typography>
            )}
            {item.back_side && (
              <Typography align="left" variant="subtitle1">
                back_side : {item.back_side}
              </Typography>
            )}
            {item.distractors && (
              <Typography align="left" variant="subtitle1">
                <Typography align="left" variant="subtitle1">
                  distractors :{' '}
                </Typography>
                <Typography align="left" variant="subtitle1">
                  {item.distractors.map((value) => (
                    <Typography align="left" variant="subtitle1">
                      {value}
                    </Typography>
                  ))}
                </Typography>
              </Typography>
            )}
            <br />
          </>
        );
      });
    }
    return <></>;
  };

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const renderRawResponse = () => {
    const botResValue = dataValue.botResponse;
    if (_.isEmpty(botResValue)) {
      return <></>;
    }
    return (
      <CustomSwitchComponent
        checked={isChecked}
        onChange={handleSwitchChange}
        label="Raw Response"
        color="#ffffff"
        size="small"
      />
    );
  };

  const customCardHeader = (cardheader, isActionEnabled = false) => {
    return (
      <Box
        sx={{
          fontSize: '12px',
          background: theme.palette.background.darkBlue,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          height: '35px',
          fontWeight: 'bold'
        }}
      >
        <Box>{cardheader}</Box>
        {isActionEnabled ? <Box sx={{ paddingLeft: '16px' }}>{renderRawResponse()}</Box> : <></>}
      </Box>
    );
  };
  return (
    <>
      <Box>
        <Box>
          <LoadingHandler
            loading={chatgptUtilityResponseStatus.isPending || chatgptUtilitySubTypeResponseStatus.isPending}
            isError={
              (chatgptUtilityResponseStatus.isError &&
                chatgptUtilityResponseStatus.error &&
                chatgptUtilityResponseStatus.error.status === 401 &&
                chatgptUtilityResponseStatus.error.status === 500) ||
              (chatgptUtilitySubTypeResponseStatus.isError &&
                chatgptUtilitySubTypeResponseStatus.error &&
                chatgptUtilitySubTypeResponseStatus.error.status === 401 &&
                chatgptUtilitySubTypeResponseStatus.error.status === 500)
            }
            content={<></>}
            loadingContent={<Loader loaderStatusResponse={loaderStatusResponse} />}
            errorContent={
              <StateErrorDisplay
                error={chatgptUtilityResponseStatus.error || chatgptUtilitySubTypeResponseStatus.error}
                showDetails
              />
            }
          />
        </Box>

        <Box>
          {!isFeedback && dataValue && (
            <ExpandableCard
              header={constants.CHAT_PROMPT_CONTENT}
              body={
                <>
                  {dataValue &&
                    dataValue.content &&
                    Array.isArray(dataValue.content) &&
                    dataValue.content.map((val, index) => (
                      <div style={{ margin: '8px 0' }} key={index}>
                        {dataValue.type === 'summary' ? <Typography variant="h6">Chunk {index + 1}</Typography> : ''}
                        <Typography variant="body2">{val}</Typography>
                      </div>
                    ))}

                  {dataValue && dataValue.content && dataValue.content.length === 0 ? (
                    <Typography variant="body2">
                      There is no data {dataValue.type === 'answer' ? 'context' : 'content'} available for this record.
                    </Typography>
                  ) : (
                    <Typography variant="body2">{dataValue.content}</Typography>
                  )}
                  <br />

                  {renderResponsePrompts()}

                  {filterPromt.includes(dataValue.type) && dataValue.prompt && typeof dataValue.prompt === 'object' ? (
                    dataValue.prompt.map((data) => {
                      return (
                        <>
                          <Typography variant="body2" fontWeight="bold" textAlign="center">
                            {`Role: ${data.role}`}
                          </Typography>

                          {data.prompt && (
                            <>
                              <Typography variant="body2" fontWeight="bold">
                                {constants.CHAT_PROMPT}:
                              </Typography>
                              <Typography variant="body2">{data.prompt.toString().replace(/\n/g, '\\n')}</Typography>
                              <br />
                            </>
                          )}

                          {data.safety && (
                            <>
                              <Typography variant="body2" fontWeight="bold">
                                {constants.CHAT_SAFETY}:
                              </Typography>
                              <Typography variant="body2">{data.safety}</Typography>
                              <br />
                            </>
                          )}

                          {data.userTopic && (
                            <>
                              <Typography variant="body2" fontWeight="bold">
                                {constants.CHAT_USERTOPIC}:
                              </Typography>
                              <Typography variant="body2">{data.userTopic}</Typography>
                              <br />
                            </>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <Typography variant="body2">
                      {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                    </Typography>
                  )}
                </>
              }
            />
          )}

          {SubTypeData &&
            SubTypeData.map((typeData) => {
              if (
                typeData &&
                (typeData.type === constants.PROBLEM_SOLVE_VERIFIER ||
                  typeData.type === constants.PROBLEM_SOLVE_RECTIFIER ||
                  typeData.type === constants.PROBLEM_SOLVE)
              ) {
                return (
                  <ExpandableCard
                    maxHeight="300px"
                    header={`${constants.CHAT_PROMPT_CONTENT} - Problem Solve ${
                      typeData.type === constants.PROBLEM_SOLVE_VERIFIER
                        ? 'Verifier'
                        : typeData.type === constants.PROBLEM_SOLVE_RECTIFIER
                        ? 'Rectifier'
                        : ''
                    }`}
                    body={
                      <>
                        {typeData.prompt &&
                          typeof typeData.prompt === 'object' &&
                          typeData.prompt.map((data) => {
                            return (
                              <div style={{ marginBottom: '16px' }}>
                                <Typography variant="body2" fontWeight="bold" textAlign="center">
                                  {`Role: ${data.role}`}
                                </Typography>
                                {data.prompt && (
                                  <>
                                    <Typography variant="body2" fontWeight="bold">
                                      {constants.CHAT_PROMPT}:
                                    </Typography>
                                    <Typography variant="body2">
                                      {data.prompt.toString().replace(/\n/g, '\\n')}
                                    </Typography>
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </>
                    }
                  />
                );
              }
              return <></>;
            })}
          {!isFeedback &&
            dataValue &&
            dataValue.type === 'content_filter' &&
            dataValue.botResponse &&
            Object.keys(dataValue.botResponse).length > 0 && (
              <Box sx={{ marginTop: '16px' }}>
                <Card>
                  {customCardHeader('Content Filter Response')}
                  <CardContent className={classes.chatIntentCard}>
                    {Object.keys(dataValue.botResponse).map((key) => {
                      if (key === 'Category_Severity') {
                        return dataValue.botResponse[key].map((data, index) => {
                          return (
                            <>
                              <Typography variant="body2" key={index}>
                                Category : {data.Category}
                              </Typography>
                              <Typography variant="body2" key={index}>
                                Severity : {data.Severity}
                              </Typography>
                            </>
                          );
                        });
                      }
                      return (
                        <>
                          <Typography variant="body2">
                            blocklistsMatchResults :{' '}
                            {dataValue.botResponse[key].length > 0 ? (
                              dataValue.botResponse[key].map((data, index1) => {
                                return Object.entries(data).map(([blockListResultKey, blockListResultValue]) => (
                                  <Typography variant="body2" key={index1}>
                                    {blockListResultKey} : {blockListResultValue}
                                  </Typography>
                                ));
                              })
                            ) : (
                              <Typography variant="body2">[]</Typography>
                            )}
                          </Typography>
                          <br />
                        </>
                      );
                    })}
                  </CardContent>
                </Card>
              </Box>
            )}

          {!isFeedback ? (
            <ExpandableCard
              maxHeight="300px"
              header={customCardHeader(`${tenantTitle} Response`, true)}
              body={
                <>
                  {!isChecked ? (
                    <>
                      {dataValue &&
                        dataValue.type === 'chat_intent' &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        dataValue.botResponse.map((data, index) => {
                          return (
                            <>
                              {' '}
                              {dataValue.botResponse.length > 1 && (
                                <Typography variant="body2">{index === 0 ? '[NORM-GPT]' : '[BAD-GPT]'}</Typography>
                              )}
                              {Object.entries(data).map(([key, value]) => (
                                <Typography variant="body2">
                                  {key} : {value}
                                </Typography>
                              ))}
                              <br />
                            </>
                          );
                        })}

                      {dataValue &&
                        dataValue.type === 'chat_intent' &&
                        dataValue.botResponse &&
                        typeof dataValue.botResponse === 'string' && (
                          <Typography variant="body2">{dataValue.botResponse}</Typography>
                        )}

                      {dataValue &&
                        (dataValue.type === 'problem_solve' || dataValue.type === constants.PROBLEM_SOLVE_RECTIFIER) &&
                        dataValue.botResponse &&
                        typeof dataValue.botResponse === 'string' && (
                          <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                        )}

                      {dataValue && dataValue.type === 'content_filter' && dataValue.finalResponse && (
                        <Typography variant="body2">{dataValue.finalResponse}</Typography>
                      )}

                      {dataValue &&
                        dataValue.type === 'summary' &&
                        Object.keys(dataValue.botResponse).map((val) => {
                          return (
                            val === 'processedSummary' && (
                              <>
                                {dataValue.botResponse[val] &&
                                  dataValue.botResponse[val].map((arr, index) => (
                                    <>
                                      {val === 'processedSummary' && (
                                        <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                                      )}
                                      <Typography variant="body2" key={index}>
                                        {checkMathJax(arr)}
                                      </Typography>
                                    </>
                                  ))}
                                <br />
                              </>
                            )
                          );
                        })}

                      {dataValue &&
                        dataValue.type === 'summary' &&
                        Object.keys(dataValue.botResponse).map((val) => {
                          return (
                            val === 'botSummary' && (
                              <>
                                <Typography variant="h6">Final Summary</Typography>
                                {dataValue.botResponse[val] &&
                                  dataValue.botResponse[val].map((arr, index) => (
                                    <>
                                      <Typography variant="body2" key={index}>
                                        {checkMathJax(arr)}
                                      </Typography>
                                    </>
                                  ))}
                                <br />
                              </>
                            )
                          );
                        })}

                      {dataValue &&
                        dataValue.type === 'flashcards' &&
                        dataValue.botResponse &&
                        dataValue.botResponse.map((data, index) => (
                          <Typography variant="body2" key={index}>
                            {data.question}
                            <br />
                            {data.answer.map((ans, indexKey) => (
                              <Typography variant="body2" key={indexKey}>
                                {checkMathJax(ans)}
                                <br />
                              </Typography>
                            ))}
                            <br />
                          </Typography>
                        ))}

                      {dataValue &&
                        filterPromt.includes(dataValue.type) &&
                        dataValue.type !== 'chat_intent' &&
                        dataValue.type !== 'problem_solve' &&
                        dataValue.type !== constants.PROBLEM_SOLVE_RECTIFIER &&
                        dataValue.type !== 'explain_stand_alone' &&
                        dataValue.type !== constants.ANSWER_CYU_MCQ &&
                        dataValue.type !== 'salutation' &&
                        dataValue.type !== 'chat_enrichment' &&
                        dataValue.type !== 'flashcards' &&
                        dataValue.type !== 'quiz_mcq' &&
                        dataValue.type !== 'quiz' &&
                        dataValue.type !== 'content_classifier' &&
                        dataValue.type !== 'context_identifier_user_selection' &&
                        dataValue.type !== constants.SUMMARY_EXPLAIN &&
                        dataValue.type !== constants.EXPERTDESK_MCQ &&
                        dataValue.type !== constants.EXPERTDESK_FREE_RESPONSE &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        dataValue.botResponse.map((data, index) => (
                          <Typography variant="body2" key={index}>
                            {checkMathJax(data)}
                          </Typography>
                        ))}

                      {constants.commonAuditTypesResponse.includes(dataValue.type) &&
                        dataValue.botResponse &&
                        Object.keys(dataValue.botResponse).length > 0 &&
                        Object.values(dataValue.botResponse).map((item) => {
                          if (Array.isArray(item) && item.length > 0) {
                            return item.map((subItem, index) => {
                              return (
                                <>
                                  <Typography variant="body2" key={index}>
                                    {checkMathJax(subItem)}
                                  </Typography>
                                  <br />
                                </>
                              );
                            });
                          }
                          return <></>;
                        })}

                      {dataValue &&
                        (dataValue.type === 'explain_stand_alone' ||
                          dataValue.type === 'salutation' ||
                          dataValue.type === constants.ANSWER_CYU_MCQ) &&
                        dataValue.botResponse &&
                        (dataValue.botResponse.explainStandAloneBotResponse ||
                          dataValue.botResponse.answerCyuMcqParserBotResponse ||
                          dataValue.botResponse.salutationBotResponse) && (
                          <>
                            <Typography variant="body2">
                              {dataValue.botResponse.explainStandAloneBotResponse ||
                                dataValue.botResponse.salutationBotResponse ||
                                dataValue.botResponse.answerCyuMcqParserBotResponse}
                            </Typography>
                          </>
                        )}

                      {dataValue &&
                        filterPromt.includes(dataValue.type) &&
                        (dataValue.type === 'quiz_mcq' || dataValue.type === 'quiz') &&
                        dataValue.botResponse &&
                        dataValue.botResponse.map((data) => {
                          const changedValue = JSON.parse(data);
                          return changedValue.questions.map((value) => {
                            return (
                              <>
                                <Typography variant="body2">
                                  Question Id :<b> {`${value.id}`}</b>
                                  <br />
                                  <br />
                                  <span>
                                    {'Question : '} {value.question_stem}
                                  </span>
                                  <br />
                                  <br />
                                </Typography>
                                {value.options.map((values) => {
                                  return (
                                    <Typography variant="body2">
                                      {`${values.id}.`}
                                      {values.distractor}
                                    </Typography>
                                  );
                                })}
                                <br />
                                {value.options.map((feed) => {
                                  return (
                                    <Typography variant="body2">
                                      {`Feedback for answer choices : ${feed.id}.`}
                                      {feed.feedback}
                                      <br />
                                      <br />
                                    </Typography>
                                  );
                                })}
                                <br />
                              </>
                            );
                          });
                        })}

                      {dataValue && dataValue.type === 'content_classifier' && dataValue.botResponse && (
                        <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                      )}

                      {dataValue && dataValue.type === 'chat_enrichment' && dataValue.botResponse && (
                        <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                      )}

                      {dataValue && dataValue.type === constants.SUMMARY_EXPLAIN && dataValue.botResponse && (
                        <Typography variant="body2">
                          {checkMathJax(dataValue.botResponse.summaryExplainBotResponse)}
                        </Typography>
                      )}

                      {dataValue &&
                        dataValue.type === 'context_identifier_user_selection' &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        dataValue.botResponse.map((data) => {
                          return (
                            <>
                              <Typography variant="body2" fontWeight="bold" textAlign="center">
                                {`Role: ${data.role}`}
                              </Typography>
                              <>
                                <Typography variant="body2">{`Content: ${data.content}`}</Typography>
                                <br />
                              </>
                            </>
                          );
                        })}

                      {dataValue &&
                        (dataValue.type === constants.EXPERTDESK_FREE_RESPONSE ||
                          dataValue.type === constants.EXPERTDESK_MCQ) &&
                        dataValue.botResponse &&
                        dataValue.botResponse.expertDeskMcqBotResponse && (
                          <>
                            <Typography>flashcards</Typography>
                            {renderExpertDeskResponse(dataValue.botResponse.expertDeskMcqBotResponse)}
                          </>
                        )}
                    </>
                  ) : (
                    <Typography variant="body2">{rawResponse}</Typography>
                  )}
                </>
              }
            />
          ) : (
            <>
              <Box sx={{ marginBottom: '16px' }}>
                <Card>
                  {customCardHeader(`${tenantTitle} Response`, true)}

                  {!isChecked ? (
                    <CardContent style={{ overflowY: 'auto', maxHeight: '205px' }}>
                      {dataValue &&
                        dataValue.type === 'chat_intent' &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        dataValue.botResponse.map((data, index) => {
                          return (
                            <>
                              {' '}
                              {dataValue.botResponse.length > 1 && (
                                <Typography variant="body2">{index === 0 ? '[NORM-GPT]' : '[BAD-GPT]'}</Typography>
                              )}
                              {Object.entries(data).map(([key, value]) => (
                                <Typography variant="body2">
                                  {key} : {value}
                                </Typography>
                              ))}
                              <br />
                            </>
                          );
                        })}
                      {dataValue &&
                        (dataValue.type === 'quiz_mcq' || dataValue.type === 'quiz') &&
                        dataValue.feedbackContent &&
                        Array.isArray(dataValue.feedbackContent) &&
                        dataValue.feedbackContent.map((val) => {
                          return Object.keys(val).length > 0 && typeof val !== 'string' ? (
                            <>
                              <Typography variant="body2">
                                {`Question : ${val && val.question}`}
                                <br />
                                <br />
                                {val.options &&
                                  val.options.map((values, index) => {
                                    const defIndex = handleAlterIndex(index);
                                    if (
                                      values.option.startsWith('a)') ||
                                      values.option.startsWith('b)') ||
                                      values.option.startsWith('c)') ||
                                      values.option.startsWith('d)')
                                    ) {
                                      return (
                                        <Typography variant="body2">
                                          {defIndex} {values.option.slice(2)}
                                        </Typography>
                                      );
                                    }
                                    return (
                                      <Typography variant="body2">
                                        {defIndex} {values.option} {''}{' '}
                                        {values.correct_choice === 'Yes' ? '(Correct)' : ''}
                                      </Typography>
                                    );
                                  })}
                                <br />
                                {val && val.answer && val.answer.length > 0 && (
                                  <Typography variant="body2">{val.answer}</Typography>
                                )}
                                {val &&
                                  val.feedback &&
                                  val.feedback.length !== 0 &&
                                  val.feedback.map((item, index) => {
                                    const defIndex = handleAlterIndex(index);
                                    return (
                                      <Typography variant="body2">
                                        Feedback for option
                                        {defIndex} {item}
                                      </Typography>
                                    );
                                  })}
                              </Typography>
                            </>
                          ) : (
                            <Typography>{val}</Typography>
                          );
                        })}
                      {dataValue &&
                        dataValue.type === 'summary' &&
                        Object.keys(dataValue.botResponse).map((val) => {
                          return (
                            val === 'processedSummary' && (
                              <>
                                {dataValue.botResponse[val] &&
                                  dataValue.botResponse[val].map((arr, index) => (
                                    <>
                                      {val === 'processedSummary' && (
                                        <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                                      )}
                                      <Typography variant="body2" key={index}>
                                        {checkMathJax(arr)}
                                      </Typography>
                                    </>
                                  ))}
                                <br />
                              </>
                            )
                          );
                        })}
                      {dataValue &&
                        dataValue.type === 'summary' &&
                        Object.keys(dataValue.botResponse).map((val) => {
                          return (
                            val === 'botSummary' && (
                              <>
                                <Typography variant="h6">Final Summary</Typography>
                                {dataValue.botResponse[val] &&
                                  dataValue.botResponse[val].map((arr, index) => (
                                    <>
                                      <Typography variant="body2" key={index}>
                                        {checkMathJax(arr)}
                                      </Typography>
                                    </>
                                  ))}
                                <br />
                              </>
                            )
                          );
                        })}
                      {dataValue &&
                        dataValue.type === 'flashcards' &&
                        dataValue.botResponse &&
                        dataValue.botResponse.map((data, index) => (
                          <Typography variant="body2" key={index}>
                            {data.question}
                            <br />
                            {data.answer.map((ans, indexKey) => (
                              <Typography variant="body2" key={indexKey}>
                                {checkMathJax(ans)}
                                <br />
                              </Typography>
                            ))}
                            <br />
                          </Typography>
                        ))}
                      {dataValue &&
                        dataValue.type === 'content_filter' &&
                        dataValue.botResponse &&
                        Object.keys(dataValue.botResponse).length > 0 &&
                        Object.keys(dataValue.botResponse).map((key) => {
                          if (key === 'Category_Severity') {
                            return dataValue.botResponse[key].map((data, index) => {
                              return (
                                <>
                                  <Typography variant="body2" key={index}>
                                    Category : {data.Category}
                                  </Typography>
                                  <Typography variant="body2" key={index}>
                                    Severity : {data.Severity}
                                  </Typography>
                                </>
                              );
                            });
                          }
                          return <></>;
                        })}
                      {dataValue &&
                        (dataValue.type === 'explain_stand_alone' ||
                          dataValue.type === 'salutation' ||
                          dataValue.type === constants.ANSWER_CYU_MCQ) &&
                        dataValue.botResponse &&
                        (dataValue.botResponse.explainStandAloneBotResponse ||
                          dataValue.botResponse.salutationBotResponse ||
                          dataValue.botResponse.answerCyuMcqParserBotResponse) && (
                          <>
                            <Typography variant="body2">
                              {dataValue.botResponse.explainStandAloneBotResponse ||
                                dataValue.botResponse.salutationBotResponse ||
                                dataValue.botResponse.answerCyuMcqParserBotResponse}
                            </Typography>
                          </>
                        )}
                      {dataValue &&
                        filterPromt.includes(dataValue.type) &&
                        dataValue.type !== constants.PROBLEM_SOLVE &&
                        dataValue.type !== constants.PROBLEM_SOLVE_RECTIFIER &&
                        dataValue.type !== constants.ANSWER_CYU_MCQ &&
                        dataValue.type !== 'explain_stand_alone' &&
                        dataValue.type !== 'salutation' &&
                        dataValue.type !== 'chat_enrichment' &&
                        dataValue.type !== 'flashcards' &&
                        dataValue.type !== 'quiz_mcq' &&
                        dataValue.type !== 'content_classifier' &&
                        dataValue.type !== 'chat_intent' &&
                        dataValue.type !== 'content_filter' &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        dataValue.botResponse.map((data, index) => (
                          <Typography variant="body2" key={index}>
                            {checkMathJax(data)}
                          </Typography>
                        ))}
                      {constants.commonAuditTypesResponse.includes(dataValue.type) &&
                        dataValue.botResponse &&
                        Object.keys(dataValue.botResponse).length > 0 &&
                        Object.values(dataValue.botResponse).map((item) => {
                          if (Array.isArray(item) && item.length > 0) {
                            return item.map((subItem, index) => {
                              return (
                                <>
                                  <Typography variant="body2" key={index}>
                                    {checkMathJax(subItem)}
                                  </Typography>
                                  <br />
                                </>
                              );
                            });
                          }
                          return <></>;
                        })}
                      {dataValue &&
                        (dataValue.type === 'content_classifier' ||
                          dataValue.type === constants.PROBLEM_SOLVE ||
                          dataValue.type === constants.PROBLEM_SOLVE_RECTIFIER) &&
                        dataValue.botResponse && (
                          <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                        )}
                      {dataValue && dataValue.type === 'chat_enrichment' && dataValue.botResponse && (
                        <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                      )}
                    </CardContent>
                  ) : (
                    <CardContent>
                      <Typography variant="body2">{rawResponse}</Typography>
                    </CardContent>
                  )}
                </Card>
              </Box>

              <Box sx={{ marginBottom: '16px' }}>
                <Card>
                  {customCardHeader(constants.CHAT_PROMPT_CONTENT)}
                  <CardContent style={{ overflowY: 'auto', maxHeight: '285px' }}>
                    {dataValue &&
                      (dataValue.type === 'discuss' || dataValue.type === 'answer' || dataValue.type === 'summary') &&
                      dataValue.content &&
                      dataValue.content.map((val, index) => (
                        <Typography variant="body2" key={index}>
                          {val && val}
                        </Typography>
                      ))}
                    <br />
                    {filterPromt.includes(dataValue.type) &&
                    dataValue.prompt &&
                    typeof dataValue.prompt === 'object' ? (
                      dataValue.prompt.map((data) => {
                        return (
                          <>
                            <Typography variant="body2" fontWeight="bold" textAlign="center">
                              {`Role: ${data.role}`}
                            </Typography>
                            {data.prompt && (
                              <>
                                <Typography variant="body2" fontWeight="bold">
                                  {constants.CHAT_PROMPT}:
                                </Typography>
                                <Typography variant="body2">{data.prompt.toString().replace(/\n/g, '\\n')}</Typography>
                                <br />
                              </>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <Typography variant="body2" fontWeight="bold">
                        {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
          {SubTypeData &&
            SubTypeData.map((typeData) => {
              if (
                typeData &&
                (typeData.type === constants.PROBLEM_SOLVE ||
                  typeData.type === constants.PROBLEM_SOLVE_VERIFIER ||
                  typeData.type === constants.PROBLEM_SOLVE_RECTIFIER)
              ) {
                return (
                  <ExpandableCard
                    maxHeight="300px"
                    header={`AI Study Tools Response - ${
                      typeData.type === constants.PROBLEM_SOLVE_VERIFIER
                        ? 'Problem Solve Verifier'
                        : typeData.type === constants.PROBLEM_SOLVE_RECTIFIER
                        ? 'Problem Solve Rectifier'
                        : 'Problem Solve'
                    }`}
                    body={<>{checkMathJax(typeData.botResponse)}</>}
                  />
                );
              }
              return <></>;
            })}
        </Box>
      </Box>
    </>
  );
};

DynamicDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isFeedback: PropTypes.bool.isRequired,
  loaderStatusResponse: PropTypes.bool.isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataValue: PropTypes.object,
  dataLength: PropTypes.object,
  modalIndex: PropTypes.string,
  filterPromt: PropTypes.object,
  tenantTitle: PropTypes.string,
  chatgptUtilitySubTypeResponseStatus: shapes.state.isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
};

DynamicDialog.defaultProps = {
  modalIndex: '',
  filterPromt: [],
  dataValue: {},
  dataLength: {},
  tenantTitle: ''
};

export default withStyles(styles)(
  inject(
    'chatgptUtilityResponseStatus',
    'ChatUtilitySubTypeResponse',
    'chatgptUtilitySubTypeResponseStatus'
  )(observer(DynamicDialog))
);

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const trendingData = types.model('chartsData', {
  count: types.maybeNull(types.number),
  topicName: types.maybeNull(types.string)
});

const TrendingTopicByBook = types
  .model('TrendingTopicByBook', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(trendingData), [])
  })
  .actions((self) => ({
    fetch(bookId) {
      Framework.getEventManager().publish(constants.GET_TRENDING_TOPIC_DATA, {
        bookId
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_TRENDING_TOPIC_DATA);
    },
    resetStoreValues() {
      const initialState = {};
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_TRENDING_TOPIC_DATA);
    }
  }));

export default TrendingTopicByBook;

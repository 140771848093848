import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class ChatutilityPermissionsService extends BaseService {
  constructor() {
    super(constants.CHATUTILITY_GET_USER_PERMISSIONS, 'chatutilityuserPermissionStatus');
  }

  handleEvent() {
    return this.getClient('evergreen').get('/chatutility/users/permissions');
  }

  handleResponse(response) {
    // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('chatutilityuserPermissions').set(response.data);
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
    }
  }
}

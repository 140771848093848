import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const countArray = types.model('countArray', {
  intentType: types.maybeNull(types.string),
  count: types.maybeNull(types.number)
});

const PopularPageData = types.model('PopularPageData', {
  title: types.maybeNull(types.string),
  counts: types.maybeNull(types.optional(types.array(countArray), [])),
  totalCount: types.maybeNull(types.number)
});

const PopularPageModel = types
  .model('PopularPageModel', {
    data: types.optional(types.array(PopularPageData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.FETCH_POPULAR_PAGES_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_POPULAR_PAGES_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_POPULAR_PAGES_DATA);
    }
  }));

export default PopularPageModel;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const CustomHiddenInput = ({ onChange }) => {
  return <VisuallyHiddenInput type="file" onChange={(event) => onChange(event)} />;
};

CustomHiddenInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default CustomHiddenInput;

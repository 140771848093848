import { Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../../../../common/constants';

const styles = () => ({
  groupTitle: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    paddingTop: '35px',
    width: '45%'
  },
  flexDisplay: {
    display: 'flex',
    border: '1px solid',
    margin: '8px'
  },
  flexWidthDisplay: {
    display: 'flex',
    width: '50%'
  },
  itemTypes: {
    display: 'flex',
    margin: '40px auto'
  },
  indexValue: {
    display: 'flex',
    margin: '40px auto',
    fontSize: '20px'
  }
});

const PopularPagesGrid = (props) => {
  const { gridData, classes } = props;
  return (
    <div>
      {gridData &&
        gridData.map((val, index) => (
          <Paper className={classes.flexDisplay}>
            <div className={classes.indexValue}>{`${index + 1}.`}</div>
            <div className={classes.groupTitle}>
              <Typography gutterBottom variant="h5" component="div">
                {val.title}
              </Typography>
            </div>
            <div className={classes.flexWidthDisplay}>
              {val.counts.map((a) => (
                <div className={classes.itemTypes}>
                  {/* eslint-disable */}
                  {a.intentType === constants.EXPLAIN_FEATURE
                    ? constants.EXPLAIN
                    : a.intentType === constants.SUMMARIZE_FEATURE
                    ? constants.SUMMARY
                    : a.intentType === constants.PRACTICE_QUESTION_FEATURE
                    ? constants.PRACTICE_QUESTIONS
                    : null}
                  {/* eslint-disable */}
                  {` (${a.count})`}
                </div>
              ))}
            </div>
          </Paper>
        ))}
      <br />
    </div>
  );
};

PopularPagesGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  gridData: PropTypes.array.isRequired
};

export default withStyles(styles)(PopularPagesGrid);

import { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Grid, Paper, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../common/Loader';
import * as constants from '../../../../common/constants';
import utils from '../../../../common/utils';
import SubTypeResponse from '../../models/SubTypeModel';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  aiStudyToolcard: {
    height: '435px',
    overflowY: 'auto !important'
  },
  inCreaseAiStudyToolcard: {
    overflowY: 'auto !important',
    height: '585px'
  },
  promptCard: {
    overflowY: 'auto !important',
    maxHeight: '235px',
    minHeight: '235px'
  },
  minimizedPromptCard: {
    overflowY: 'auto !important',
    height: '130px'
  },
  chatIntentCard: {
    overflowY: 'auto !important',
    height: '200px'
  },
  subTypeModel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const AIChartsDialog = ({
  openChat,
  dataValue,
  handleClose,
  dataLength,
  filterPromt,
  modalIndex,
  handleModalChange,
  chatgptUtilityResponseStatus,
  isFeedback,
  loaderStatusResponse,
  ChatUtilitySubTypeResponse,
  chatgptUtilitySubTypeResponseStatus
}) => {
  const [checkboxSelectedValue, setCheckboxSelectedValue] = useState({});

  useEffect(() => {
    if (Object.keys(checkboxSelectedValue).length === 0) {
      ChatUtilitySubTypeResponse.resetStoreValues();
    }
  }, [chatgptUtilityResponseStatus, checkboxSelectedValue, dataValue, chatgptUtilitySubTypeResponseStatus]);

  const handleNavigationChange = (nav) => {
    handleModalChange(nav);
    setCheckboxSelectedValue({});
    ChatUtilitySubTypeResponse.resetStoreValues();
  };

  const renderMathJaxResponse = (response) => {
    const regesXmlns = /<math([^>]*)>/g;
    const res = utils.containsMathML(response)
      ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
      : response;
    return (
      <>
        {/* eslint-disable */}
        <span
          ref={(el) => {
            utils.processMath(el);
          }}
          dangerouslySetInnerHTML={{ __html: res }}
        />
        {/* eslint-disable */}
      </>
    );
  };

  const checkMathJax = (mathJaxData) => {
    let response = mathJaxData;
    if (!_.isEmpty(response)) {
      if (utils.containsMarkDownText(response)) {
        response = utils.convertToMarkedDownString(response);
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\'))
        return renderMathJaxResponse(response);
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: response }} />
      </>
    );
  };

  return (
    <>
      <Dialog fullScreen open={openChat} onClose={handleClose}>
        <Box sx={{ p: 2 }}>
          <Paper elevation={0}>
            <Grid container spacing={0} padding={0}>
              <Grid item xs={5}>
                <Typography align="left" variant="subtitle1">
                  {constants.CHAT_USERLOGIN}: {dataValue.userId}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="left" display="inline">
                  <Stack
                    display="inline"
                    spacing={0}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Button disabled={modalIndex === 0} onClick={() => handleNavigationChange('prev')} size="large">
                      <ChevronLeftIcon />
                    </Button>
                    <span color="secondary">{modalIndex + 1}</span> /<span color="secondary"> {dataLength}</span>
                    <Button disabled={modalIndex + 1 === dataLength} onClick={() => handleNavigationChange('next')}>
                      <ChevronRightIcon />
                    </Button>
                    {dataValue && dataValue.type && (
                      <Typography>
                        {constants.TYPE} :{' '}
                        {dataValue.type === 'answer' || dataValue.type === 'discuss'
                          ? 'Explain'
                          : dataValue.type.charAt(0).toUpperCase() + dataValue.type.slice(1)}
                      </Typography>
                    )}
                  </Stack>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="subtitle1">
                  <Typography display="inline" variant="subtitle2">
                    <Box display="inline" sx={{ pl: 4 }}>
                      <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                        <Tooltip title="Close">
                          <CloseIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box sx={{ p: 2, paddingTop: '0px' }}>
          <Box>
            <LoadingHandler
              loading={chatgptUtilityResponseStatus.isPending || chatgptUtilitySubTypeResponseStatus.isPending}
              isError={
                (chatgptUtilityResponseStatus.isError &&
                  chatgptUtilityResponseStatus.error &&
                  chatgptUtilityResponseStatus.error.status === 401 &&
                  chatgptUtilityResponseStatus.error.status === 500) ||
                (chatgptUtilitySubTypeResponseStatus.isError &&
                  chatgptUtilitySubTypeResponseStatus.error &&
                  chatgptUtilitySubTypeResponseStatus.error.status === 401 &&
                  chatgptUtilitySubTypeResponseStatus.error.status === 500)
              }
              content={<></>}
              loadingContent={<Loader loaderStatusResponse={loaderStatusResponse} />}
              errorContent={
                <StateErrorDisplay
                  error={chatgptUtilityResponseStatus.error || chatgptUtilitySubTypeResponseStatus.error}
                  showDetails
                />
              }
            />
          </Box>
          <Grid container spacing={2} style={{ minWidth: 800 }}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Item>
                  <Card style={{ overflowY: 'auto', maxHeight: '205px', minHeight: '205px' }}>
                    <CardHeader title="Feedback and Comments" style={{ background: '#34282808' }} align="left" />
                    <CardContent>
                      <Typography variant="body2" color="text.primary" textAlign="left">
                        {dataValue && dataValue.feedback}
                      </Typography>
                      <br />
                      <Typography variant="body2" color="text.primary" textAlign="left">
                        {dataValue && dataValue.comments}
                      </Typography>
                      <br />
                    </CardContent>
                  </Card>
                </Item>
              </Grid>
              {isFeedback && (
                <>
                  <br />
                  <Grid item xs={12}>
                    <Item>
                      <Card style={{ overflowY: 'auto', maxHeight: '185px', minHeight: '185px' }}>
                        <CardHeader title="Reason for Dislike" style={{ background: '#34282808' }} align="left" />
                        <CardContent>
                          {dataValue && dataValue.reasonForDisLike && (
                            <Typography variant="body2" color="text.primary" textAlign="left">
                              {dataValue.reasonForDisLike}
                            </Typography>
                          )}
                          <br />
                          <br />
                        </CardContent>
                      </Card>
                    </Item>
                  </Grid>
                </>
              )}
              <br />
            </Grid>
            <br />
            <Grid item xs={6}>
              <>
                <Item>
                  <Card style={{ overflowY: 'auto', minHeight: '205px', maxHeight: '205px' }}>
                    <CardHeader title="AI Study Tool Response" style={{ background: '#34282808' }} align="left" />
                    {/* eslint-disable */}
                    <CardContent style={{ overflowY: 'auto', textAlign: 'left' }}>
                      {dataValue && dataValue.content && checkMathJax(dataValue.content)}
                    </CardContent>
                    {/* eslint-disable */}
                  </Card>
                </Item>
                <br />
                <Item>
                  <Card style={{ overflowY: 'auto', minHeight: '185px', maxHeight: '185px' }}>
                    <CardHeader
                      title={constants.CHAT_PROMPT_CONTENT}
                      style={{ background: '#34282808' }}
                      align="left"
                    />
                    <CardContent>
                      {dataValue &&
                        (dataValue.type === 'discuss' ||
                          // dataValue.type === 'quiz' ||
                          dataValue.type === 'answer' ||
                          dataValue.type === 'summary') &&
                        dataValue.content && (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {dataValue.content}
                          </Typography>
                        )}
                      <br />
                      {filterPromt.includes(dataValue.type) &&
                      dataValue.prompt &&
                      typeof dataValue.prompt === 'object' ? (
                        dataValue.prompt.map((data) => {
                          return (
                            <>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                textAlign="center"
                              >{`Role: ${data.role}`}</Typography>
                              {data.prompt && (
                                <>
                                  {' '}
                                  <Typography variant="body2" color="text.primary" textAlign="left">
                                    {constants.CHAT_PROMPT}:
                                  </Typography>{' '}
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    {data.prompt.toString().replace(/\n/g, '\\n')}
                                  </Typography>
                                  <br />
                                </>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <Typography variant="body2" color="text.primary" textAlign="left">
                          {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Item>
              </>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <br />
    </>
  );
};

AIChartsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  openChat: PropTypes.bool.isRequired,
  isFeedback: PropTypes.bool.isRequired,
  loaderStatusResponse: PropTypes.bool.isRequired,
  // chatgptUtilityResponseStatus: PropTypes.bool.isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataValue: PropTypes.object,
  dataLength: PropTypes.object,
  modalIndex: PropTypes.string,
  filterPromt: PropTypes.object,
  userTocDetails: PropTypes.object,
  problemSolveFlow: PropTypes.string,
  handleModalChange: PropTypes.func.isRequired,
  chatgptUtilitySubTypeResponseStatus: shapes.state.isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
};

AIChartsDialog.defaultProps = {
  modalIndex: '',
  filterPromt: [],
  dataValue: {},
  dataLength: {},
  userTocDetails: {},
  problemSolveFlow: ''
};

export default withStyles(styles)(
  inject(
    'chatgptUtilityResponseStatus',
    'ChatUtilitySubTypeResponse',
    'chatgptUtilitySubTypeResponseStatus'
  )(observer(AIChartsDialog))
);

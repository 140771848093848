/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Box } from '@material-ui/core';

const styles = () => ({
  progressBar: {
    position: 'absolute',
    left: '50%',
    top: window.innerHeight / 3
  },
  progressBarBlur: {
    position: 'absolute',
    left: '48%',
    top: -(window.innerHeight / 3)
  },
  progressResBlur: {
    // position: 'absolute',
    // left: '50%',
    // top: '394%',
    // zIndex: '9999'
  },
  message: {
    top: window.innerHeight / 2,
    left: '0%',
    bottom: '0%',
    right: '0%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  positionChatgpt: {
    position: 'fixed',
    top: '50%',
    left: '50%'
  },
  positionEvergreen: {
    position: 'relative'
  }
});

const Loader = ({
  classes,
  primaryMessage,
  secondaryMessage,
  loaderStatus,
  loaderStatusResponse,
  chatGptUtilityTrendsPage
}) => (
  <div>
    <Box
      className={loaderStatusResponse ? classes.positionChatgpt : classes.positionEvergreen}
      style={{ marginTop: chatGptUtilityTrendsPage ? '230px' : '0px' }}
    >
      {!loaderStatusResponse && (
        <CircularProgress
          className={loaderStatus ? classes.progressBarBlur : classes.progressBar}
          thickness={3}
          size={50}
        />
      )}
      {loaderStatusResponse && (
        <CircularProgress className={loaderStatusResponse ? classes.progressResBlur : null} thickness={3} size={50} />
      )}
      {(primaryMessage || secondaryMessage) && (
        <Box className={classes.message}>
          <Typography align="center" variant="h3" color="textSecondary">
            {primaryMessage}
            <br />
            {secondaryMessage}
          </Typography>
        </Box>
      )}
    </Box>
  </div>
);

Loader.defaultProps = {
  primaryMessage: null,
  secondaryMessage: null,
  loaderStatus: false,
  loaderStatusResponse: false,
  chatGptUtilityTrendsPage: false
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
  loaderStatus: PropTypes.bool,
  loaderStatusResponse: PropTypes.bool,
  chatGptUtilityTrendsPage: PropTypes.bool
};

export default withStyles(styles)(Loader);

import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
// import FormHelperText from '@mui/material/FormHelperText';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import FormHelperText from '@mui/material/FormHelperText';
import { inject, observer } from 'mobx-react';
// import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { toJS } from 'mobx';
import Loader from '../../../common/Loader';
import ChatgptConfig from '../../../common/config/ChatgptConfig';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import utils from '../../../common/utils';
import AutocompleteComponent from '../common/components/Autocomplete';
import ChatgptUtilityModel from '../models/ChatgptUtility';
import McqSearch from '../models/McqSearch';
import ChatDataGrid from './datagrid/ChatDataGrid';
import InternalUsersList from '../models/InternalUsersList';
import CDNToken from '../models/CDNToken';

const materialTheme = createTheme({
  overrides: {
    // MuiPickersBasePicker:{
    // },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  datePicker: {
    // width: '49%',
    marginTop: 'inherit'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  },
  searchBarClass: {
    width: '25vw'
  }
});

@inject('chatgptUtility', 'chatgptUtilityStatus', 'mcqSearch', 'internalUsers', 'cdnToken')
@observer
class ChatgptUtility extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    chatgptUtility: shapes.modelOf(ChatgptUtilityModel).isRequired,
    chatgptUtilityStatus: shapes.state.isRequired,
    mcqSearch: shapes.modelOf(McqSearch).isRequired,
    internalUsers: shapes.modelOf(InternalUsersList).isRequired,
    cdnToken: shapes.modelOf(CDNToken).isRequired
  };

  constructor(props) {
    super(props);
    this.defaultSearch = {
      bookId: '',
      userId: '',
      email: '',
      startDateTime: '',
      endDateTime: '',
      type: ''
    };
    this.state = {
      defaultDropdownValue: { title: constants.ALL_BOOKS, bookId: constants.ALL_BOOKS, id: 1 },
      newRequest: true,
      searchFields: '',
      gptResponse: [],
      endDisabled: true,
      loaderStatus: true,
      isbookIdRequired: false,
      bookList: [],
      inputValue: '',
      tokenValue: '',
      userIdChecked: false,
      isBookmarkFlow: false,
      isAudioIntentSelected: false,
      isUserIdExcluded: true
    };
  }

  componentDidMount = () => {
    const { chatgptUtility, mcqSearch } = this.props;
    if (mcqSearch && mcqSearch.data.length > 0) {
      const resData = _.cloneDeep(toJS(mcqSearch.data));
      resData.unshift({ title: constants.ALL_BOOKS, bookId: constants.ALL_BOOKS, id: 1 });
      this.setState({
        bookList: resData,
        searchFields: { bookId: constants.ALL_BOOKS }
      });
    }
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.GPT_RESPONSE, () => {
      const { data } = chatgptUtility;
      if (chatgptUtility && data) {
        const { newRequest, gptResponse, bookList, isAudioIntentSelected } = this.state;
        const combineData = [...data];
        bookList.forEach((item) => {
          const alteredValue = item.bookId;
          if (!alteredValue) return;
          combineData.forEach((dataValue, index) => {
            if (dataValue.bookId === alteredValue) {
              combineData[index].title = item.title;
            }
          });
        });
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        /* eslint-enable camelcase */
        if (newRequest) {
          let sortedDate = combineDatatoJS.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          if (isAudioIntentSelected) {
            sortedDate = sortedDate.filter((item) => item?.audioIntent);
          }
          this.setState({ gptResponse: sortedDate, newRequest: false });
        } else {
          const combineDataGpt = [...gptResponse, ...combineDatatoJS];
          let sortedDate = combineDataGpt.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          if (isAudioIntentSelected) {
            sortedDate = sortedDate.filter((item) => item?.audioIntent);
          }
          this.setState(() => ({ gptResponse: sortedDate }));
        }
      }
    });
    const urlParams = new URLSearchParams(window.location.search);
    const bookId = urlParams.get('bookId');
    const requestId = urlParams.get('requestId');
    const type = urlParams.get('type');

    if (bookId && requestId && type) {
      this.setState({ isBookmarkFlow: true });
    } else {
      this.setState({ isBookmarkFlow: false });
    }
    /* eslint-enable camelcase */
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mcqSearch, internalUsers, cdnToken } = this.props;
    const { tokenValue } = this.state;
    const usersData = JSON.parse(JSON.stringify(toJS(internalUsers)));
    const cdnTokenValue = JSON.parse(JSON.stringify(toJS(cdnToken)));
    const isCDNtokenPresent = utils.getCookieByName('etext-cdn-token');
    if (prevState.tokenValue !== tokenValue) {
      if (_.isEmpty(cdnTokenValue?.name) && !isCDNtokenPresent) {
        this.getCDNTokenData();
      }
      if (mcqSearch && mcqSearch.data.length === 0) {
        this.getBookData();
      }
      if (usersData && usersData?.userId.length === 0) {
        this.getS3InternalUsersList();
      }
    }
  };

  getCDNTokenData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_CDN_TOKEN_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const cdnTokenVal = response.data.data;
      Framework.getStoreRegistry().getStore('cdnToken').setResponse(cdnTokenVal);
      utils.setCDNTokenInCookie(cdnTokenVal);
    }
  };

  getS3InternalUsersList = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const payload = {
      itemPath: constants.S3_EXCLUDED_USERS_LIST_PATH
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_S3_USERS_LIST_URL}`, payload, {
      headers
    });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('internalUsers').setResponse(response.data.data[0]);
    }
  };

  getBookData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (response && response.data && response.data.data) {
      const resData = _.cloneDeep(response.data.data);
      resData.unshift({ title: constants.ALL_BOOKS, bookId: constants.ALL_BOOKS, id: 1 });
      Framework.getStoreRegistry().getStore('mcqSearch').setResponse(response.data);
      this.setState({
        bookList: resData,
        searchFields: { bookId: constants.ALL_BOOKS }
      });
    }
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    if (e.target.name === 'audioIntentSelected') {
      this.setState({ isAudioIntentSelected: e.target.checked });
    } else {
      fieldChanged[e.target.name] = e.target.value;
    }
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged },
      isbookIdRequired: false
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDateTime) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleReset = () => {
    this.setState({
      endDisabled: true,
      isbookIdRequired: false,
      searchFields: {
        ...this.defaultSearch
      },
      inputValue: '',
      userIdChecked: false,
      isAudioIntentSelected: null
    });
  };

  handleExport = async () => {
    const { searchFields } = this.state;
    const payload = { ...searchFields };
    if (payload && (payload.bookId || payload.email || payload.userId || payload.type)) {
      const responseData = await axios({
        timeout: 1000 * 60 * 5,
        method: 'post',
        url: `${env.EVERGREEN_API_BASE_URL}/chatutility/audit/exportToexcel`,
        responseType: 'blob',
        data: payload
      }).catch((error) => {
        const { response } = error;
        if (response) {
          const { status, data } = response;
          if (status === 500 && data && data.error) {
            const notification = {
              open: true,
              message: data.error.message,
              type: 'error'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        } else if (error.message === 'timeout of 60000ms exceeded') {
          const notification = {
            open: true,
            message: error.message,
            type: 'error'
          };
          Framework.getStoreRegistry().getStore('notificationState').set(notification);
        }
      });
      if (responseData && responseData.data) {
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        today = `${dd}/${mm}/${yyyy}`;
        const outputFilename = `Chat-utility_AuditLog_generated ${today}.csv`;

        const url = URL.createObjectURL(
          // eslint-disable-next-line no-undef
          new Blob([responseData.data], {
            type: responseData.headers['content-type']
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        const notification = {
          open: true,
          message: `${outputFilename} Data Exported Successfully`,
          type: 'success'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    } else {
      this.setState({ isbookIdRequired: true });
    }
  };

  handleSearch = (customPayload) => {
    const { searchFields, newRequest, isAudioIntentSelected } = this.state;
    const { chatgptUtility } = this.props;
    const { summaryNextPageKey, quizNextPageKey, problemSolveNextPageKey, explainNextPageKey } = chatgptUtility;
    const bookSearch = { allBookSearch: false };
    if (searchFields.userId) {
      this.setState({ userIdChecked: true });
    } else {
      this.setState({ userIdChecked: false });
    }
    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      if (searchFields?.bookId === constants.ALL_BOOKS) {
        chatgptUtility.resetPageNextKey();
      }
      this.setState({ newRequest: true });
    }
    const payload = { ...searchFields, ...customPayload };
    if (payload.type && payload.type === constants.CONTENT_FILTER_BLOCKED) {
      payload.type = 'content_filter';
      payload.finalStatus = 'BLOCKED';
    }
    if (payload.type && payload.type === constants.CHAT_INTENT_AUDIO) {
      payload.type = constants.CHAT_INTENT;
      payload.audioIntent = true;
    }
    if (isAudioIntentSelected) {
      payload.type = searchFields.type ? searchFields.type : constants.CHAT_INTENT;
      payload.audioIntent = true;
    }
    if (payload.type && payload.type === constants.CHAT_INTENT_PROBLEM_SOLVE) {
      payload.type = constants.CHAT_INTENT;
      payload.responseKeyword = constants.PROBLEM_SOLVE_FEATURE;
    }
    if (
      payload &&
      payload.bookId === constants.ALL_BOOKS &&
      !payload.type &&
      !payload.email &&
      !payload.userId &&
      !isAudioIntentSelected
    ) {
      const removeEmptyKeys = (objKeys) => {
        let lastKey = {};
        if (objKeys && objKeys.ExclusiveStartKey) {
          const startKey = objKeys.ExclusiveStartKey;
          /* eslint-disable no-param-reassign */
          lastKey = Object.entries(startKey).reduce((obj, [key, value]) => {
            if (key !== null && value !== null) {
              obj[key] = value;
            }
            return obj;
          }, {});
        }
        return { ExclusiveStartKey: lastKey };
      };
      bookSearch.allBookSearch = true;
      chatgptUtility.setAllBookSearch(bookSearch);
      const type = searchFields?.type ? searchFields?.type : '';
      const startDateTime = searchFields?.startDateTime ? searchFields.startDateTime : '';
      const endDateTime = searchFields?.endDateTime ? searchFields.endDateTime : '';
      const userId = searchFields?.userId ? searchFields.userId : '';
      const email = searchFields?.email ? searchFields.email : '';
      const commonDateDefaultValue = { startDateTime, endDateTime, type, email, userId };

      const explainTypeLastKey = explainNextPageKey?.ExclusiveStartKey?.bookId?.S
        ? { lastKey: removeEmptyKeys(explainNextPageKey) }
        : {};
      const summaryTypeLastKey = summaryNextPageKey?.ExclusiveStartKey?.bookId?.S
        ? { lastKey: removeEmptyKeys(summaryNextPageKey) }
        : {};
      const problemSolveTypeLastKey = problemSolveNextPageKey?.ExclusiveStartKey?.bookId?.S
        ? { lastKey: removeEmptyKeys(problemSolveNextPageKey) }
        : {};
      const quizMcqTypeLastKey = quizNextPageKey?.ExclusiveStartKey?.bookId?.S
        ? { lastKey: removeEmptyKeys(quizNextPageKey) }
        : {};
      const apiPayloadOne = {
        ...commonDateDefaultValue,
        type: constants.EXPLAIN_TYPE,
        ...explainTypeLastKey
      };
      const apiPayloadTwo = {
        ...commonDateDefaultValue,
        type: constants.QUIZ_MCQ_TYPE,
        ...quizMcqTypeLastKey
      };
      const apiPayloadThree = {
        ...commonDateDefaultValue,
        type: constants.PROBLEM_SOLVE,
        ...problemSolveTypeLastKey
      };
      const apiPayloadFour = {
        ...commonDateDefaultValue,
        type: constants.SUMMARY_TYPE,
        ...summaryTypeLastKey
      };
      if (newRequest || apiPayloadOne.lastKey) {
        chatgptUtility.fetch(apiPayloadOne);
      }
      if (newRequest || apiPayloadTwo.lastKey) {
        chatgptUtility.fetch(apiPayloadTwo);
      }
      if (newRequest || apiPayloadThree.lastKey) {
        chatgptUtility.fetch(apiPayloadThree);
      }
      if (newRequest || apiPayloadFour.lastKey) {
        chatgptUtility.fetch(apiPayloadFour);
      }
    } else if (
      payload &&
      (payload.bookId || payload.email || payload.userId || payload.type || isAudioIntentSelected)
    ) {
      chatgptUtility.resetPageNextKey();
      bookSearch.allBookSearch = false;
      const { bookId, ...searchPayload } = payload;
      chatgptUtility.setAllBookSearch(bookSearch);
      if (bookId === constants.ALL_BOOKS) {
        chatgptUtility.fetch(searchPayload);
      } else {
        chatgptUtility.fetch(payload);
      }
    } else {
      this.setState({ isbookIdRequired: true });
    }
  };

  handleAscending = (dir, value) => {
    const { gptResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...gptResponse].sort((a, b) => {
        if (value === 'audioIntent') {
          if (a[value] === true && b[value] !== true) return -1;
          if (a[value] === null && b[value] !== null) return 1;
          return 0;
        }
        return a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1;
      });
      this.setState({
        ...gptResponse,
        gptResponse: sorted
      });
    } else {
      const sorted = [...gptResponse].sort((a, b) => {
        if (value === 'audioIntent') {
          if (a[value] === true && b[value] !== true) return 1;
          if (a[value] === null && b[value] !== null) return -1;
          return 0;
        }
        return a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1;
      });
      this.setState({
        ...gptResponse,
        gptResponse: sorted
      });
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        bookId: newValue !== null ? newValue.bookId : ''
      },
      isbookIdRequired: false
    });
  };

  setUserId = (field, checkedUserId) => {
    const { searchFields, userIdChecked } = this.state;
    const fieldChanged = {};
    if (userIdChecked) {
      fieldChanged[field] = '';
      this.setState(
        {
          searchFields: { ...searchFields, ...fieldChanged },
          isbookIdRequired: false,
          userIdChecked: false
        },
        () => {
          this.handleSearch();
        }
      );
    } else {
      fieldChanged[field] = checkedUserId;
      this.setState(
        {
          searchFields: { ...searchFields, ...fieldChanged },
          isbookIdRequired: false,
          userIdChecked: true
        },
        () => {
          this.handleSearch();
        }
      );
    }
  };

  handleFilterUserChange = (e) => {
    this.setState({
      isUserIdExcluded: e.target.checked
    });
  };

  render() {
    const {
      classes,
      chatgptUtilityStatus,
      chatgptUtility: {
        isNextPageKey,
        summaryNextPageKey,
        quizNextPageKey,
        problemSolveNextPageKey,
        explainNextPageKey,
        listOfType,
        isGetAllBookData
      }
    } = this.props;
    const {
      isAudioIntentSelected,
      defaultDropdownValue,
      searchFields,
      endDisabled,
      gptResponse,
      loaderStatus,
      bookList,
      isbookIdRequired,
      inputValue,
      tokenValue,
      userIdChecked,
      isBookmarkFlow,
      isUserIdExcluded
    } = this.state;
    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }
    const isNextPageKeyEnable = {};
    isNextPageKeyEnable.ExclusiveStartKey = {
      bookId:
        summaryNextPageKey?.ExclusiveStartKey?.bookId?.S ||
        quizNextPageKey?.ExclusiveStartKey?.bookId?.S ||
        problemSolveNextPageKey?.ExclusiveStartKey?.bookId?.S ||
        explainNextPageKey?.ExclusiveStartKey?.bookId?.S
    };
    const typeList = toJS(listOfType);
    return (
      <>
        <Paper>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                {constants.CHAT_CRITERIA_TEXT}
              </Typography>
            </Box>
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={11}>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}>
                  <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                    <AutocompleteComponent
                      options={bookList}
                      onAutoCompleteChange={this.onAutoCompleteChange}
                      onInputChange={this.onInputChange}
                      stateVal={inputValue}
                      errorVal={isbookIdRequired}
                      name="bookId"
                      label="Book Id"
                      componentDefaultValue={defaultDropdownValue}
                    />
                    {isbookIdRequired && (
                      <FormHelperText style={{ color: 'red' }}>Please select any BookId</FormHelperText>
                    )}
                  </FormControl>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <KeyboardDatePicker
                        className={classes.datePicker}
                        format={constants.MM_DD_YYYY_FORMAT}
                        autoOk
                        size="small"
                        variant="inline"
                        style={{ minWidth: '32%' }}
                        inputVariant="outlined"
                        disableFuture
                        value={searchFields.startDateTime || null}
                        label="Start Date"
                        onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                      <KeyboardDatePicker
                        className={classes.datePicker}
                        format={constants.MM_DD_YYYY_FORMAT}
                        autoOk
                        variant="inline"
                        size="small"
                        style={{ minWidth: '32%' }}
                        inputVariant="outlined"
                        disableFuture
                        value={searchFields.endDateTime || null}
                        label="End Date"
                        onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                        disabled={endDisabled}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
            </Grid>
            <br />
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={11} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label={searchFields.userId ? '' : 'User ID'}
                    margin="dense"
                    name="userId"
                    onChange={($event) => this.handleChange($event)}
                    style={{ minWidth: '32%' }}
                    value={searchFields.userId}
                    variant="outlined"
                  />
                  <TextField
                    label="Email ID"
                    margin="dense"
                    type="email"
                    style={{ minWidth: '32%' }}
                    name="email"
                    onChange={($event) => this.handleChange($event)}
                    value={searchFields.email}
                    variant="outlined"
                  />
                  <FormControl sx={{ mt: 1, width: '32%' }} size="small">
                    <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                    <Select
                      label="Type"
                      name="type"
                      onChange={($event) => this.handleChange($event)}
                      value={searchFields.type || ''}
                    >
                      {ChatgptConfig.filterByChats.map((filter, index) => (
                        <MenuItem divider={index === 3} value={filter.name}>
                          {filter.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <br />
            <div className={chatgptUtilityStatus.isPending ? classes.blurStyle : null} />
          </Box>
          <Box>
            <Grid container direction="row" justifyContent="center">
              <FormControl size="small">
                <FormControlLabel
                  control={
                    <Checkbox onChange={this.handleFilterUserChange} name="excludeUserId" checked={isUserIdExcluded} />
                  }
                  style={{
                    alignItems: 'center'
                  }}
                  labelPlacement="end"
                  label="Filter Internal Users"
                />
              </FormControl>
              <FormControl size="small">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={($event) => this.handleChange($event)}
                      name="audioIntentSelected"
                      checked={isAudioIntentSelected}
                    />
                  }
                  style={{
                    alignItems: 'center'
                  }}
                  labelPlacement="end"
                  label="Audio Intent"
                />
              </FormControl>
            </Grid>
          </Box>
          <br />
          <Box>
            <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
              <ThemeProvider theme={themes}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => this.handleSearch()}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_SEARCH}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.handleReset}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_RESET}
                </Button>
              </ThemeProvider>
            </Grid>
            <br />
          </Box>
        </Paper>
        <LoadingHandler
          loading={chatgptUtilityStatus.isPending}
          isError={
            chatgptUtilityStatus.isError &&
            chatgptUtilityStatus.error &&
            chatgptUtilityStatus.error.status === 401 &&
            chatgptUtilityStatus.error.status === 500
          }
          content={
            (!isGetAllBookData && gptResponse && gptResponse.length > 0) ||
            (isGetAllBookData && typeList?.length > 0 && gptResponse?.length > 0) ||
            isBookmarkFlow ? (
              <Box>
                <ChatDataGrid
                  chatgptUtilityData={gptResponse}
                  handleSearch={this.handleSearch}
                  handleAscending={this.handleAscending}
                  isNextPageKey={isNextPageKey}
                  isNextPageKeyEnable={isNextPageKeyEnable}
                  setUserId={this.setUserId}
                  userIdChecked={userIdChecked}
                  isAudioIntentSelected={isAudioIntentSelected}
                  isUserIdExcluded={isUserIdExcluded}
                />
              </Box>
            ) : (
              <Paper>
                <Box sx={{ p: 1, mt: 1 }}>
                  <Typography variant="h4" align="center">
                    {constants.CHAT_FETCH}
                  </Typography>
                </Box>
              </Paper>
            )
          }
          loadingContent={<Loader loaderStatus={loaderStatus} />}
          errorContent={<StateErrorDisplay error={chatgptUtilityStatus.error} showDetails />}
        />
      </>
    );
  }
}

export default withStyles(styles)(ChatgptUtility);

/* eslint-disable*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChatGptUtility from './components/ChatGptUtility';
import ChatGptUtilityFeeback from './components/ChatGptUtilityFeeback';
import ChatGptUtilityTrends from './components/ChatGptUtilityTrends';
import ChannelsComponent from './components/channels/ChannelsComponent';
import ChannelsFeedback from './components/channels/ChannelsFeedback';
import ChannelsTrends from './components/channels/ChannelsTrends';
import ChatDataToc from './components/datagrid/ChatDataToc';
import McqSearch from './components/titleSearch/McqSearch';
import AdminView from './components/AdminView';
import DynamicComponent from './components/DynamicComponent/DynamicComponent';
import TenantListView from './components/tenantList/TenantListView';
import ChatutilityRolesContainer from './components/user/ChatutilityRolesContainer';
import ChatutilityUsersContainer from './components/user/ChatutilityUsersContainer';
import ChatUtilityDashboard from './components/ChatUtilityDashboard';

// import ReportsComponent from './components/reports/ReportsComponent';
// import TenantList from './components/tenantList/TenantList';
// import ChatgptUtilityAdmin from './components/ChatgptUtilityAdmin';
// import NotFound from './components/NotFound';
import AdminPromptView from './components/admin/AdminPromptView';
// import TrendingTopicsView from './components/aiCharts/trendingTopics/TrendingTopicsView';
// import ChatBotConfig from './components/chatBotConfig/ChatBotConfig';
import EffectivenessMainComponent from './components/effectiveness/EffectivenessMainComponent';
// import PopularPageComponent from './components/popularPages/PopularPageComponent';
// import PVSAssesment from './components/pvsAssessment/PVSAssesment';
// import SelectLesson from './components/pvsAssessment/SelectLesson';
// import SelectedChapter from './components/pvsAssessment/SelectedChapter';

function ChatUtiltyRouter(props) {
  const { tenantId } = props;
  // const { tabValue } = props;

  return (
    <Switch>
      <Route path="/aiadmin/aistudytools/promptmgt" exact render={() => <AdminPromptView />} />
      {/* <Route path="/aiadmin/aitrendingtopics" exact render={() => <TrendingTopicsView {...props} />} /> */}
      {/* <Route path="/aiadmin/popularpage" exact render={() => <PopularPageComponent />} /> */}
      {/* Route path="/aiadmin/pvsassessment" exact render={() => <PVSAssesment />} /> */}
      {/* <Route path="/aiadmin/chatbotconfig" exact render={() => <ChatBotConfig />} /> */}
      {/* <Route path="/aiadmin/pvsassessment/selectlesson" exact render={() => <SelectLesson {...props} />} /> */}
      <Route path="/aiadmin/aistudytools/promptmgt/effectiveness" exact render={() => <EffectivenessMainComponent />} />
      <Route path="/aiadmin/aistudytools/effectiveness" exact render={() => <EffectivenessMainComponent />} />
      {/* <Route path="/aiadmin/pvsassessment/selectlesson/chapter" exact render={() => <SelectedChapter {...props} />} /> */}

      <Route path="/aiadmin" exact render={() => <ChatUtilityDashboard {...props} />} />
      <Route path="/aiadmin/channels" exact render={() => <ChannelsComponent />} />
      <Route path="/aiadmin/aistudytools" exact render={() => <ChatGptUtility />} />
      <Route path="/aiadmin/users" exact render={() => <ChatutilityUsersContainer />} />
      <Route path="/aiadmin/roles" exact render={() => <ChatutilityRolesContainer />} />
      <Route path="/aiadmin/aistudytools/feedback" exact render={() => <ChatGptUtilityFeeback />} />
      <Route path="/aiadmin/aistudytools/trends" exact render={() => <ChatGptUtilityTrends />} />
      <Route path="/aiadmin/aistudytools/trends/charts" exact render={() => <ChatGptUtilityTrends />} />
      <Route path="/aiadmin/aistudytools/trends/clustering" exact render={() => <ChatGptUtilityTrends />} />
      <Route
        path="/aiadmin/aistudytools/mcq/view/:bookId"
        exact
        render={() => <ChatDataToc {...props} viewType="quiz" />}
      />
      <Route
        path="/aiadmin/aistudytools/mcq/add/:bookId"
        exact
        render={() => <ChatDataToc {...props} viewType="quiz" />}
      />
      <Route
        path="/aiadmin/aistudytools/mcq/edit/:bookId"
        exact
        render={() => <ChatDataToc {...props} viewviewType="quiz" />}
      />
      <Route path="/aiadmin/aistudytools/mcq" exact render={() => <ChatDataToc viewType="quiz" />} />
      <Route
        path="/aiadmin/aistudytools/summary/view/:bookId"
        exact
        render={() => <ChatDataToc {...props} viewType="summary" />}
      />
      <Route
        path="/aiadmin/aistudytools/summary/add/:bookId"
        exact
        render={() => <ChatDataToc {...props} viewType="summary" />}
      />
      <Route
        path="/aiadmin/aistudytools/summary/edit/:bookId"
        exact
        render={() => <ChatDataToc {...props} viewType="summary" />}
      />
      <Route path="/aiadmin/aistudytools/summary" exact render={() => <ChatDataToc viewType="summary" />} />
      <Route path="/aiadmin/aistudytools/trends/unanswered" exact render={() => <ChatGptUtilityTrends />} />
      <Route path="/aiadmin/aistudytools/titlesearch" exact render={() => <McqSearch />} />
      <Route path="/aiadmin/channels/feedback" exact render={() => <ChannelsFeedback />} />
      <Route path="/aiadmin/channels/trends" exact render={() => <ChannelsTrends />} />
      <Route path="/aiadmin/channels/promptmgt" exact render={() => <AdminPromptView />} />
      <Route path="/aiadmin/admin" exact render={() => <AdminView />} />
      <Route path="/aiadmin/tenants" exact render={() => <TenantListView />} />
      <Route
        path="/aiadmin/*"
        render={() => <DynamicComponent {...props} selectedTabValue={0} tenantId={tenantId} />}
      />
    </Switch>
  );
}

ChatUtiltyRouter.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tenantId: PropTypes.string.isRequired
};

export default ChatUtiltyRouter;

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import RolesList from './ChatutilityRolesList';
import Users from '../../models/ChatutilityUsers';
import Roles from '../../models/ChatutilityRoles';
import Loader from '../../../../common/Loader';
import Permissions from '../../models/ChatutilityPermissions';

const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
};
@inject(
  'chatutilityusersState',
  'chatutilityusersStatus',
  'chatutilityuserRoles',
  'chatutilityaddUserStatus',
  'chatutilityeditUserstatus',
  'chatutilityaddRolesStatus',
  'chatutilityupdateRolesStatus',
  'chatutilityuserPermissions'
)
@observer
class ChatutilityRolesContainer extends React.Component {
  static propTypes = {
    chatutilityusersState: shapes.modelOf(Users).isRequired,
    chatutilityuserRoles: shapes.modelOf(Roles).isRequired,
    chatutilityusersStatus: shapes.state.isRequired,
    chatutilityeditUserstatus: shapes.state.isRequired,
    chatutilityaddUserStatus: shapes.state.isRequired,
    chatutilityaddRolesStatus: shapes.state.isRequired,
    chatutilityupdateRolesStatus: shapes.state.isRequired,
    chatutilityuserPermissions: shapes.modelOf(Permissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { chatutilityusersState, chatutilityuserRoles, chatutilityuserPermissions } = this.props;
    chatutilityusersState.fetch();
    chatutilityuserRoles.fetch();
    chatutilityuserPermissions.fetch();
  }

  render() {
    const {
      chatutilityusersState,
      chatutilityusersStatus,
      chatutilityeditUserstatus,
      chatutilityaddUserStatus,
      chatutilityaddRolesStatus,
      chatutilityupdateRolesStatus,
      chatutilityuserPermissions
    } = this.props;
    return (
      <>
        <main>
          <Container maxWidth="xl">
            <LoadingHandler
              loading={
                chatutilityusersStatus.isPending ||
                chatutilityeditUserstatus.isPending ||
                chatutilityaddUserStatus.isPending ||
                chatutilityaddRolesStatus.isPending ||
                chatutilityupdateRolesStatus.isPending
              }
              isError={
                chatutilityusersStatus.isError && chatutilityusersStatus.error && chatutilityusersStatus.error.status
              }
              content={
                <RolesList
                  data={chatutilityusersState.data.slice()}
                  chatutilityusersState={chatutilityusersState}
                  chatutilityuserPermissions={chatutilityuserPermissions}
                  {...this.props}
                />
              }
              loadingContent={<Loader />}
              errorContent={<StateErrorDisplay error={chatutilityusersStatus.error} showDetails />}
            />
          </Container>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(ChatutilityRolesContainer)));

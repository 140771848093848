import Typography from '@material-ui/core/Typography/Typography';
import Box from '@mui/material/Box';
import { toJS } from 'mobx';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Framework, { shapes } from '@greenville/framework';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Listing from '../models/Listing';
import ChatgptUtilityIESUser from '../models/ChatgptIESUser';
import ChatDialogComponent from './datagrid/ChatDialogComponent';
import ChatgptConfig from '../../../common/config/ChatgptConfig';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';
import InternalUsersList from '../models/InternalUsersList';
import ResponseModel from '../models/Response';
import CustomTable from '../common/components/CustomTable';
import ChatFeedBackComponent from './DynamicComponent/dataGrid/ChatFeedBackComponent';

const styles = () => ({
  tableColumnHeight: {
    maxHeight: '600px',
    overflow: 'hidden',
    marginBottom: '2%'
  },
  highlighted: {
    backgroundColor: '#8080802e'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600',
    textAlign: 'center'
  },
  tableLeftWidth: {
    paddingLeft: '12px'
  },
  tableLeftScroll: {
    maxHeight: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  },
  columnData: {
    color: '#6a7070',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  iconStyle: {
    display: 'flex',
    paddingLeft: '6px',
    cursor: 'pointer',
    alignItems: 'center'
  },
  fontStyle: {
    marginLeft: '3px',
    fontSize: '12px',
    color: '#333333'
  }
});

const Listings = (props) => {
  const {
    classes,
    ChatgptUtilityIESUserData,
    chatgptListingUtility,
    chatgptResponseUtility,
    ChatUtilitySubTypeResponse,
    isUserIdExcluded,
    internalUsers
  } = props;
  const feedback = false;
  const [highLight, setHighLght] = useState(-1);
  const [trendsList, setTrendsList] = useState([]);
  const [clusterTable, setClusterTable] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [rowDialogue, setRowDialogue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [openChat, openChatDialog] = useState(false);
  const [dataLength, setDatalength] = useState({});
  const [fromValue] = useState(0);
  const [filteredData, setFilteredData] = useState(chatgptListingUtility?.data);
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [checkboxSelectedValue, setCheckboxSelectedValue] = useState({
    chat_intent: false,
    content_filter: false,
    explain_stand_alone: false,
    GENERATE_PYTHON: false
  });
  const theme = useTheme();

  useEffect(() => {
    const combinedObjects = {};
    setshowTable(false);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        // setUserEmailDetails(userData[userData.length - 1]);
      }
    });

    if (filteredData.length > 0) {
      filteredData.forEach((obj) => {
        const key = obj.clusterName && obj.clusterName.split('').sort().join('');
        if (combinedObjects[key]) {
          combinedObjects[key].count += 1;
        } else {
          combinedObjects[key] = { name: obj.clusterName, count: 1 };
        }
      });
      if (Object.values(combinedObjects).length > 0) {
        const pushToLast = Object.values(combinedObjects);
        const filteredItems = pushToLast.filter((item) => item.name !== 'Other topics');
        const itemToMove = pushToLast.find((item) => item.name === 'Other topics');
        const resultArray = filteredItems
          .sort((a, b) => b.count - a.count)
          .map(({ name, count }) => (count > 1 ? `${name} (${count})` : name));
        const updatedItems = !_.isEmpty(itemToMove)
          ? [...resultArray, `${itemToMove.name} (${itemToMove.count})`]
          : resultArray;
        setTrendsList(updatedItems);
      }
    }
    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const storeData = utils.processStoreValue(chatgptResponseUtility);
      if (storeData) {
        setRowDialogue(storeData);
      }
    });
    // setTrendsList(data);
  }, [filteredData]);

  useEffect(() => {
    const internalUserIds = JSON.parse(JSON.stringify(toJS(internalUsers)));
    if (isUserIdExcluded && internalUserIds?.userId.length > 0) {
      const filteredItems = filteredData.filter((item) => !internalUserIds?.userId.includes(item.userId));
      setFilteredData(filteredItems);
    } else {
      setFilteredData(chatgptListingUtility?.data);
    }
  }, [isUserIdExcluded]);

  function getObjectName(array, targetName) {
    const matchObjects = array.filter((obj) => obj.clusterName === targetName);
    return matchObjects.map(({ serverDateTime, userCommand, dateStamp, bookId, userId, title, type, requestId }) => ({
      serverDateTime,
      userCommand,
      dateStamp,
      bookId,
      userId,
      type,
      title,
      requestId
    }));
  }

  const handleList = (item, listIndex) => {
    setHighLght(listIndex);
    ChatgptConfig.chatData.forEach((value) => {
      const alteredValue = value.bookId;
      if (!alteredValue) return;
      filteredData.forEach((dataValue, index) => {
        if (dataValue.bookId === alteredValue) {
          filteredData[index].title = value.title;
        }
      });
    });
    const trimmedName = item.replace(/\(\d+\)/g, '').trim();
    setClusterName(trimmedName);
    setshowTable(true);
    const matchingNamesArray = getObjectName(filteredData, trimmedName);
    const sortedArray = matchingNamesArray.sort((x, y) => new Date(y.dateStamp) - new Date(x.dateStamp));
    // setPage(0);
    setClusterTable(sortedArray);
  };

  const handleSelectedValue = (requestId, bookId) => {
    setCheckboxSelectedValue({
      chat_intent: true,
      content_filter: true,
      explain_stand_alone: true,
      GENERATE_PYTHON: true
    });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.CHAT_INTENT });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.CONTENT_FILTER_TYPE });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.EXPLAIN_STAND_ALONE });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.GENERATE_PYTHON });
  };

  const handleEvent = (params, index) => {
    setCheckboxSelectedValue({
      chat_intent: false,
      content_filter: false,
      explain_stand_alone: false,
      GENERATE_PYTHON: true
    });
    ChatUtilitySubTypeResponse.resetStoreValues();
    const { requestId, bookId } = params;
    setDatalength(clusterTable.length);
    // setRowDialogue(params);
    setIsExpanded(false);
    openChatDialog(true);
    setModalIndex(fromValue + index);
    chatgptResponseUtility.fetch({ type: constants.CHAT_UTILITY_ANSWER, requestId, bookId });
    handleSelectedValue(requestId, bookId);
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, bookId } = clusterTable[modalIndex - 1];
      // setRowDialogue(clusterTable[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type: constants.CHAT_UTILITY_ANSWER });
    } else {
      const { requestId, bookId } = clusterTable[modalIndex + 1];
      // setRowDialogue(clusterTable[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type: constants.CHAT_UTILITY_ANSWER });
    }
  };

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      openChatDialog(false);
    }
    if (isExpanded) {
      openChatDialog(true);
    }
  };

  const customColumnData = [
    { id: 'dateStamp', label: constants.GPT_TRENDS_DATE },
    { id: 'bookId', label: constants.BOOK_ID },
    { id: 'title', label: constants.GPT_TRENDS_BOOKTITLE },
    { id: 'userId', label: constants.CHAT_USERLOGIN },
    { id: 'userCommand', label: constants.GPT_TRENDS_USER_INPUT }
  ];

  return (
    <>
      <Box>
        <Grid item xs={12} container direction="row">
          <br />
          {trendsList.length > 0 && (
            <Grid item xs={2}>
              <br />
              <Typography align="left" variant="h3">
                {constants.CLUSTER_LIST}
              </Typography>
              <br />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" xs={12}>
          <Grid item xs={2} className={classes.tableColumnHeight}>
            {trendsList.length > 0 && (
              <Box
                sx={{
                  height: '100%',
                  border: '2px solid black',
                  overflowY: 'scroll',
                  borderRadius: '2px'
                }}
              >
                {trendsList.length > 0 &&
                  trendsList.map((item, index) => (
                    <div key={index} className={index === highLight ? classes.highlighted : ''}>
                      <div
                        className={classes.iconStyle}
                        onClick={() => handleList(item, index)}
                        onKeyDown={() => handleList(item)}
                        role="button"
                        tabIndex={0}
                      >
                        <LightbulbIcon sx={{ color: theme.palette.background.darkBlue, height: '16px' }} />
                        <p className={classes.fontStyle}>{item}</p>
                      </div>
                    </div>
                  ))}
                <Divider />
              </Box>
            )}
          </Grid>
          <Grid item xs={10} className={classes.tableLeftWidth} md={isExpanded ? 10 : 4}>
            <Grid
              item
              xs={12}
              className={classes.tableLeftWidth}
              md={isExpanded ? 12 : 12}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#1A202C',
                borderBottom: '2px solid white',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderLeft: '2px solid black',
                borderRight: '2px solid black'
              }}
            >
              <Grid item style={{ paddingLeft: '14px' }} xs={2}>
                <Typography align="left" variant="h3" style={{ color: 'white' }}>
                  {showTable && clusterName}
                </Typography>
              </Grid>
              <Grid item xs={12} onClick={toggleCollapse}>
                {showTable && (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'white',
                      marginRight: '27px'
                    }}
                  >
                    <IconButton color="#fff" title="Click Here to expand table">
                      {isExpanded ? <ArrowBack /> : <ArrowForward />}
                    </IconButton>
                    <Box>{isExpanded ? 'Collapse' : 'Expand'}</Box>
                  </Box>
                )}
              </Grid>
            </Grid>
            {showTable && (
              <Box sx={{ width: '100%', border: '2px solid black' }}>
                <>
                  <CustomTable columns={customColumnData} data={clusterTable} onRowClick={handleEvent} />
                </>
              </Box>
            )}
          </Grid>
          {openChat && (
            <Grid style={{ paddingLeft: '12px' }} item xs={12} md={isExpanded ? 0 : 3}>
              <Box style={{ border: '2px solid black', borderRadius: '8px' }}>
                <ChatDialogComponent
                  openChat={openChat}
                  dataValue={rowDialogue}
                  filterPromt={constants.FILTER_PROMPT}
                  dataLength={dataLength}
                  modalIndex={modalIndex}
                  handleClose={handleClose}
                  handleModalChange={handleModalChange}
                  isTrendsInsightsList
                  isFeedback={feedback}
                  checkboxSelectedValue={checkboxSelectedValue}
                />
              </Box>
            </Grid>
          )}
          {openChat && (
            <Grid style={{ paddingLeft: '12px' }} item xs={12} md={isExpanded ? 0 : 3}>
              <Box style={{ border: '2px solid black', borderRadius: '8px' }}>
                <ChatFeedBackComponent dataValue={rowDialogue} checkboxSelectedValue={checkboxSelectedValue} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

Listings.propTypes = {
  classes: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptListingUtility: shapes.modelOf(Listing).isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(ResponseModel).isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired,
  internalUsers: shapes.modelOf(InternalUsersList).isRequired
};

export default withStyles(styles)(
  observer(
    inject(
      'ChatgptUtilityIESUserData',
      'chatgptListingUtility',
      'chatgptResponseUtility',
      'internalUsers',
      'ChatUtilitySubTypeResponse'
    )(Listings)
  )
);

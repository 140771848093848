/* eslint-disable */
import Framework, { shapes } from '@greenville/framework';
import { Grid, TextField, TextareaAutosize } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { ThemeProvider, CssBaseline } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import env from '../../../../common/env';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import ColumnConfig from '../../config/ColumnConfig';
import * as constants from '../../../../common/constants';
import chatGptAdminPromptsData from '../../models/ChatGptAdminPrompts';
import ChatgptAdmin from '../../models/ChatgptAdmin';
import DialogConfirms from '../tocdialogues/McqDialogConfirms';
import ButtonComponent from '../../common/components/ButtonComponent';
import { getDarkTheme } from '../../ChatGptTheme';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '80%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '60px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginLeft: '60px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  heading: {
    marginTop: '20px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminPromptDialog = (props) => {
  const {
    openDialog,
    handleClose,
    selectedValue,
    classes,
    isEdit,
    tenantKey,
    chatgptAdmin,
    chatgptAdminPrompts,
    permissionsList,
    language
  } = props;
  const [isDialogboxOpen, setDialogboxOpen] = useState(false);
  const [DialogContent, setDialogContent] = useState('');
  const [inputValue, setInputValue] = useState({ ...selectedValue, version: isEdit ? selectedValue.version : '1.0.0' });
  const [promptAddStartData, setPromptAddStartData] = useState(ColumnConfig.promptConfig);
  const [promptAddEndData, setPromptAddEndData] = useState(ColumnConfig.promptConfig);
  const [types, setTypes] = useState([]);
  const [isPublish, setIsPublish] = useState(false);
  const [isEditPubishArchived, setIsEditPubishArchived] = useState(false);

  useEffect(() => {
    const tenantVal = tenantKey === 'AiStudyTool' ? 'aiStudyTools' : 'channels';
    chatgptAdmin.fetchTenantData(tenantVal);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_ADMIN_DATA, () => {
      const { groups } = chatgptAdmin;
      if (groups && groups.length > 0) {
        const typesData = groups.filter((item) => item.name === 'PromptTypes')[0];
        setTypes(typesData);
      }
    });
    if (
      (inputValue && inputValue.status === constants.PROMPT_LIVE_STATUS) ||
      inputValue.status === constants.PROMPT_ARCHIVED_STATUS
    ) {
      setIsEditPubishArchived(true);
    }
  }, [inputValue && inputValue.status]);

  const handleCancel = () => {
    setInputValue({});
    setPromptAddStartData(ColumnConfig.promptConfig);
    setPromptAddEndData(ColumnConfig.promptConfig);
    handleClose();
  };

  const changeToRawText = (promptVal) => {
    const { promptStart, promptEnd } = promptVal;
    const promptStartRawText = promptStart?.map((item) => {
      return { ...item, content: item.content?.replaceAll(/\\n/g, '\n').replaceAll(/\\/g, '') };
    });
    const promptEndRawText = promptEnd?.map((item) => {
      return { ...item, content: item.content?.replaceAll(/\\n/g, '\n').replaceAll(/\\/g, '') };
    });
    return { ...promptVal, promptStart: promptStartRawText, promptEnd: promptEndRawText };
  };

  const handleSave = async (e) => {
    if (isEdit) {
      const finalData = { ...changeToRawText(inputValue), type: inputValue.type && inputValue.type.toUpperCase() };
      await chatgptAdminPrompts.updateAdminPromptsData(finalData);
    } else {
      const finalData = {
        ...inputValue,
        type: inputValue.type && inputValue.type.toUpperCase(),
        tenantId: tenantKey === 'AiStudyTool' ? env.TENANT_ID.AI_STUDY_TOOLS : env.TENANT_ID.CHANNELS,
        tenantName: tenantKey,
        promptStart: promptAddStartData,
        promptEnd: promptAddEndData
      };
      await chatgptAdminPrompts.saveAdminPromptsData(changeToRawText(finalData));
    }
    e.stopPropagation();
    handleClose();
  };
  const handleDelete = async () => {
    const finalData = { ...changeToRawText(inputValue), status: constants.PROMPT_ARCHIVED_STATUS };
    await chatgptAdminPrompts.updateAdminPromptsData(finalData);
  };
  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    setInputValue({ ...inputValue, [name]: value });
  };
  const handlePromptStartChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const role = name.split('_')[1];
    if (isEdit) {
      const start = [...inputValue.promptStart].map((v, i) => {
        if (v.role === role && i === index) {
          return {
            ...v,
            role,
            content: value
          };
        }
        return v;
      });
      setInputValue({ ...inputValue, promptStart: start });
    } else {
      setPromptAddStartData(
        [...promptAddStartData].map((v) => {
          if (v.role === role) {
            return {
              ...v,
              role,
              content: value
            };
          }
          return v;
        })
      );
    }
  };

  const handlePromptEndChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const role = name.split('_')[1];
    if (isEdit) {
      const end = [...inputValue.promptEnd].map((v, i) => {
        if (v.role === role && i === index) {
          return {
            ...v,
            role,
            content: value
          };
        }
        return v;
      });
      setInputValue({ ...inputValue, promptEnd: end });
    } else {
      setPromptAddEndData(
        [...promptAddEndData].map((v) => {
          if (v.role === role) {
            return {
              ...v,
              role,
              content: value
            };
          }
          return v;
        })
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(selectedValue)) {
      if (selectedValue.promptStart) {
        setPromptAddStartData(selectedValue.promptStart);
      }

      if (selectedValue.promptEnd) {
        setPromptAddEndData(selectedValue.promptEnd);
      }

      setInputValue((prev) => ({
        ...prev,
        type: selectedValue.type || '',
        status: selectedValue.status || '',
        promptStart: selectedValue.promptStart || [],
        promptEnd: selectedValue.promptEnd || [],
        version: selectedValue.version || '1.0.0' // Default version if missing
      }));
    }
  }, [selectedValue]);

  const renderPromptEndTextArea = () => {
    const { promptEnd } = inputValue;
    if (!_.isEmpty(promptEnd)) {
      return promptEnd.map((endVal, index) => {
        return (
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.labelColumns}>
              <InputLabel id={index} className={classes.subLable}>
                Role: {endVal.role}
              </InputLabel>
            </Grid>
            <Grid item xs={12} className={classes.gridColumns}>
              <TextareaAutosize
                className={classes.textAreaSize}
                id={index}
                name={`promptEnd_${endVal.role}`}
                onChange={(e) => handlePromptEndChange(e, index)}
                variant="outlined"
                aria-label="maximum height"
                minRows={6}
                maxRows={12}
                value={endVal.content}
                required
                disabled={
                  isEdit &&
                  (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                    inputValue.status === constants.PROMPT_ARCHIVED_STATUS)
                }
              />
            </Grid>
          </Grid>
        );
      });
    }
    return <></>;
  };
  const renderPromptStartTextArea = () => {
    const { promptStart } = inputValue;
    if (!_.isEmpty(promptStart)) {
      return promptStart.map((startval, index) => {
        return (
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.labelColumns}>
              <InputLabel id={index} className={classes.subLable}>
                Role: {startval.role}
              </InputLabel>
            </Grid>
            <Grid item xs={12} className={classes.gridColumns}>
              <TextareaAutosize
                className={classes.textAreaSize}
                id={index}
                name={`promptStart_${startval.role}`}
                variant="outlined"
                aria-label="maximum height"
                onChange={(e) => handlePromptStartChange(e, index)}
                minRows={6}
                maxRows={12}
                value={startval.content}
                required
                disabled={
                  isEdit &&
                  (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                    inputValue.status === constants.PROMPT_ARCHIVED_STATUS)
                }
              />
            </Grid>
          </Grid>
        );
      });
    }
    return <></>;
  };

  const isFormValid = () => {
    const { type, version, status } = inputValue;
    const checkEditValidation = () => {
      return (
        _.isEmpty(type) ||
        _.isEmpty(version) ||
        _.isEmpty(status) ||
        (inputValue.promptStart && inputValue.promptStart.some((start) => _.isEmpty(start.content)))
        // (inputValue.promptEnd && inputValue.promptEnd.some((end) => _.isEmpty(end.content)))
      );
    };
    const checkAddValidation = () => {
      return (
        _.isEmpty(type) ||
        _.isEmpty(version) ||
        _.isEmpty(status) ||
        (promptAddStartData && promptAddStartData.some((start) => _.isEmpty(start.content))) ||
        (promptAddEndData && promptAddEndData.some((end) => _.isEmpty(end.content)))
      );
    };
    return isEdit ? checkEditValidation() : checkAddValidation();
  };

  const handleDialogBoxClose = () => {
    setDialogboxOpen(false);
  };

  const handleDialogBoxOpen = () => {
    setDialogContent('Are you sure you want to delete?');
    setDialogboxOpen(true);
  };

  const handlePublishDialogBoxOpen = () => {
    setDialogContent(constants.ADMIN_PROMPT_PUBLISH_TEXT);
    setIsPublish(true);
    setDialogboxOpen(true);
  };

  const handleConfirmAction = async (confirmed) => {
    if (isPublish && confirmed) {
      const finalData = { ...changeToRawText(inputValue), status: constants.PROMPT_LIVE_STATUS };
      await chatgptAdminPrompts.updateAdminPromptsData(finalData);
      handleClose();
    } else if (confirmed) {
      await handleDelete();
      handleClose();
    } else {
      setIsPublish(false);
    }
  };

  // const renderComments = () => {
  //   return ();
  // };

  const theme = getDarkTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.flexBorder}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} style={{ flexDirection: 'column' }}>
            <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' }}>
              <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </Box>
          </Grid>
        </Box>
        <Box>
          <Grid item xs={16}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              {isEdit ? 'Edit Prompt Details' : 'Add New Prompt'}
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <FormControl sx={{ mt: 1, width: '60%', marginRight: '20px' }} size="small" required>
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      label="Type"
                      name="type"
                      onChange={handleInputChange}
                      value={inputValue.type || ''}
                      disabled={
                        isEdit &&
                        (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                          inputValue.status === constants.PROMPT_ARCHIVED_STATUS)
                      }
                    >
                      {types &&
                        types.properties &&
                        types.properties.map((item) => <MenuItem value={item.id}>{item.display_name}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ mt: 1, width: '60%', marginRight: '20px' }} size="small" required>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      label="Status"
                      style={{ minWidth: '32%', marginBottom: '10px' }}
                      name="status"
                      onChange={handleInputChange}
                      value={inputValue.status || ''}
                      disabled={
                        isEdit &&
                        (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                          inputValue.status === constants.PROMPT_ARCHIVED_STATUS)
                      }
                      // defaultValue={inputValue.status}
                    >
                      {isEdit &&
                        !isEditPubishArchived &&
                        ChatgptConfig.adminStatusData.map((item) => {
                          if (item.id < 2) {
                            return <MenuItem value={item.statusId}>{item.title}</MenuItem>;
                          }
                          return <></>;
                        })}
                      {isEdit && isEditPubishArchived && (
                        <MenuItem value={inputValue.status}>{inputValue.status}</MenuItem>
                      )}
                      {!isEdit && (
                        <MenuItem value={ChatgptConfig.adminStatusData[0].statusId}>
                          {ChatgptConfig.adminStatusData[0].title}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Version"
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name="version"
                    onChange={handleInputChange}
                    value={inputValue.version}
                    variant="outlined"
                    required
                    disabled={
                      isEdit &&
                      (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                        inputValue.status === constants.PROMPT_ARCHIVED_STATUS)
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            {isEdit ? (
              <>
                <Grid item xs={12}>
                  <Divider className={classes.heading}>
                    <Chip label="Prompt Start" size="medium" />
                  </Divider>
                </Grid>
                {renderPromptStartTextArea()}
                <Grid item xs={12}>
                  <Divider className={classes.heading}>
                    <Chip label="Prompt End" size="medium" />
                  </Divider>
                </Grid>
                {renderPromptEndTextArea()}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Divider className={classes.heading}>
                    <Chip label="Prompt Start" size="medium" />
                  </Divider>
                </Grid>
                {promptAddStartData.map((promptStartval) => {
                  return (
                    <Grid item xs={12}>
                      <Grid item xs={12} className={classes.labelColumns}>
                        <InputLabel id={promptStartval.role} className={classes.subLable}>
                          Role: {promptStartval.role}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} className={classes.gridColumns}>
                        <TextareaAutosize
                          className={classes.textAreaSize}
                          id={`${promptStartval.role}_promptStart_id`}
                          name={`promptStart_${promptStartval.role}`}
                          variant="outlined"
                          aria-label="maximum height"
                          onChange={(e) => handlePromptStartChange(e)}
                          minRows={6}
                          maxRows={12}
                          value={promptAddStartData.content}
                          required
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Divider className={classes.heading}>
                    <Chip label="Prompt End" size="medium" />
                  </Divider>
                </Grid>
                {promptAddEndData.map((promptEndval) => {
                  return (
                    <Grid item xs={12}>
                      <Grid item xs={12} className={classes.labelColumns}>
                        <InputLabel id={promptEndval.role} className={classes.subLable}>
                          Role: {promptEndval.role}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} className={classes.gridColumns}>
                        <TextareaAutosize
                          className={classes.textAreaSize}
                          id={`${promptEndval.role}_promptEnd_id`}
                          name={`promptEnd_${promptEndval.role}`}
                          onChange={(e) => handlePromptEndChange(e)}
                          variant="outlined"
                          aria-label="maximum height"
                          minRows={6}
                          maxRows={12}
                          value={promptAddEndData.content}
                          required
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
            <Grid item xs={12} style={{ paddingTop: '20px' }}>
              <Grid item xs={12} className={classes.labelColumns}>
                <InputLabel id="comments" className={classes.subLable}>
                  Comments
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={classes.gridColumns}>
                <TextField
                  style={{ width: '80%' }}
                  margin="dense"
                  name="comments"
                  onChange={handleInputChange}
                  value={inputValue.comments}
                  variant="outlined"
                  required
                  disabled={
                    isEdit &&
                    (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                      inputValue.status === constants.PROMPT_ARCHIVED_STATUS)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <br />
        <div className={classes.flexContainerBottom}>
          <div className={classes.ButtonClass}>
            <Stack direction="row" spacing={2}>
              <ButtonComponent title={constants.CANCEL} handleClick={() => handleCancel()} />
              <TooltipCustom
                title={
                  !permissionsList.includes('admin_can_edit') ? (
                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                  ) : (
                    <FormattedMessage {...language.getText('common.SAVE')} />
                  )
                }
              >
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(e) => handleSave(e)}
                    sx={{ background: '#005d83', marginTop: '14px' }}
                    disabled={
                      !permissionsList.includes('admin_can_edit') ||
                      (isEdit &&
                        (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                          inputValue.status === constants.PROMPT_ARCHIVED_STATUS)) ||
                      isFormValid()
                    }
                  >
                    {constants.SAVE}
                  </Button>
                </span>
              </TooltipCustom>
              {isEdit && (
                <>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('admin_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('prompt.PUBLISH')} />
                      )
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handlePublishDialogBoxOpen()}
                        sx={{ background: '#005d83', marginTop: '14px' }}
                        disabled={
                          !permissionsList.includes('admin_can_edit') ||
                          inputValue.status === constants.PROMPT_LIVE_STATUS ||
                          inputValue.status === constants.PROMPT_ARCHIVED_STATUS ||
                          isFormValid()
                        }
                      >
                        {constants.GPT_PUBLISHALL}
                      </Button>
                    </span>
                  </TooltipCustom>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('admin_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('common.DELETE')} />
                      )
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleDialogBoxOpen()}
                        sx={{ background: '#005d83', marginTop: '14px' }}
                        disabled={
                          !permissionsList.includes('admin_can_edit') ||
                          (isEdit &&
                            (inputValue.status === constants.PROMPT_LIVE_STATUS ||
                              inputValue.status === constants.PROMPT_ARCHIVED_STATUS))
                        }
                      >
                        {constants.DELETE}
                      </Button>
                    </span>
                  </TooltipCustom>
                </>
              )}
            </Stack>
          </div>
        </div>
      </div>
      {isDialogboxOpen && (
        <DialogConfirms
          open={isDialogboxOpen}
          handleClose={handleDialogBoxClose}
          title=""
          content={DialogContent}
          handleConfirm={handleConfirmAction}
        />
      )}
    </ThemeProvider>
  );
};

AdminPromptDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  tenantKey: PropTypes.string.isRequired,
  chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
  chatgptAdminPrompts: shapes.modelOf(chatGptAdminPromptsData).isRequired,
  permissionsList: PropTypes.array.isRequired,
  language: shapes.language.isRequired
};

export default withStyles(styles)(observer(inject('chatgptAdmin', 'chatgptAdminPrompts')(AdminPromptDialog)));

// TrendingTopics.js
import Framework, { shapes } from '@greenville/framework';
import { toJS } from 'mobx';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Card
  // CardContent,
  // CardHeader
  //  styled
} from '@material-ui/core';
import Chip from '@mui/material/Chip';
import { inject, observer } from 'mobx-react';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../../../common/constants';
import env from '../../../../../common/env';
import utils from '../../../../../common/utils';
import AutocompleteComponent from '../../../common/components/Autocomplete';
import TrendingTopicsData from '../../../models/TrendingTopics';
import TrendingTopicByBook from '../../../models/TrendingTopicByBook';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  inputs: {
    '& .css-6od3lo-MuiChip-label': {
      paddingLeft: '28px'
    },
    '& .css-gavykb-MuiChip-root': {
      fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
      fontWeight: '600',
      borderRadius: '8px'
    }
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

const TrendingTopics = (props) => {
  const { classes, trendingTopics, trendingTopicByBook } = props;
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [tableData, setTableData] = useState({});
  const [userToken, setUserToken] = useState('');
  const [bookList, setBookList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  // const [inputBookId, setInputBookId] = useState('');
  const [trendingUserInputs, setTrendingUserInputs] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');

  const getChartsData = () => {
    // TODO: Call API and get data and replace below resData
    const bookId = 'BRNT-CCD1J3NNHS';
    trendingTopicByBook.fetch(bookId);
    Framework.getEventManager().on(constants.SET_TRENDING_TOPIC_DATA, () => {
      setTableData(toJS(trendingTopicByBook.data));
    });
  };

  const getBooksData = async () => {
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const booksdata = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (booksdata && booksdata.data && booksdata.data.data) {
      setBookList(booksdata.data.data);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setUserToken(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userToken)) {
      getBooksData();
    }
  }, [userToken]);

  const handleSearch = () => {
    getChartsData();
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  // const onAutoCompleteChange = (event, newValue) => {
  //   setInputBookId(newValue !== null ? newValue.bookId : '');
  // };

  const handleRowClick = (row) => {
    const payload = {
      bookId: 'BRNT-CCD1J3NNHS',
      topicName: row.topicName
    };
    trendingTopics.fetch(payload);
    Framework.getEventManager().on(constants.SET_TRENDING_TOPICS_MODEL_DATA, () => {
      setTrendingUserInputs(toJS(trendingTopics.data));
    });
    setSelectedRow(row.topicName);
    setIsRowClicked(true);
  };

  return (
    <>
      <Paper>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 3 }}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              spacing={9}
              className={classes.title}
              style={{ p: 2, mt: 2 }}
            >
              Please enter search criteria to fetch the Trending Topics
            </Typography>
          </Box>
          <Grid container direction="row">
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
                <FormControl sx={{ minWidth: '39%', maxWidth: '90%', mr: 5 }} size="small">
                  <AutocompleteComponent
                    options={bookList}
                    onAutoCompleteChange={() => {}}
                    onInputChange={onInputChange}
                    stateVal={inputValue}
                    errorVal=""
                    name="bookId"
                    label="Book Id"
                  />
                </FormControl>
                <Box className={classes.marginBox} sx={{ '& button': { m: 31, p: 12 } }}>
                  <Stack direction="row" spacing={1}>
                    <Button size="small" variant="outlined" style={{ color: '#0d0c0c8c' }} onClick={() => {}}>
                      1d
                    </Button>
                    <Button size="small" style={{ color: '#0d0c0c8c' }} variant="text" onClick={() => {}}>
                      3d
                    </Button>
                    <Button size="small" style={{ color: '#0d0c0c8c' }} variant="text" onClick={() => {}}>
                      1w
                    </Button>
                    <Button size="small" variant="text" style={{ color: '#0d0c0c8c' }} onClick={() => {}}>
                      custom
                      <CalendarMonthIcon />
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <br />
        </Box>
        <br />
        <Box>
          <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
            <ThemeProvider theme={themes}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSearch}
                className={classes.buttonStyle}
              >
                {constants.CHAT_SEARCH}
              </Button>
              <Button variant="contained" size="small" color="primary" className={classes.buttonStyle}>
                {constants.CHAT_RESET}
              </Button>
            </ThemeProvider>
          </Grid>
          <br />
        </Box>
      </Paper>
      {tableData.length > 0 && (
        <Box display="flex" height="100vh">
          <Box flex={isRowClicked ? 0.5 : 1} transition="flex 0.3s" overflow="auto" style={{ paddingTop: '30px' }}>
            <TableContainer component={Paper} style={{ height: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow style={{ background: '#34282808' }}>
                    <TableCell style={{ width: '50%', textAlign: 'center' }}>Trending Topics</TableCell>
                    <TableCell style={{ width: '50%', textAlign: 'center' }}>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow
                      key={row.id}
                      onClick={() => handleRowClick(row)}
                      style={{ cursor: 'pointer', textAlign: 'center' }}
                    >
                      <TableCell style={{ width: '50%', textAlign: 'center' }}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Typography variant="body1">{row.topicName}</Typography>
                          <TrendingUpIcon style={{ marginLeft: 8, color: 'green' }} />
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: '50%', textAlign: 'center' }}>{row.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {isRowClicked && (
            <Box
              flex={isRowClicked ? 0.6 : 1}
              transition="flex 0.3s"
              overflow="auto"
              style={{ paddingTop: '30px', paddingLeft: '10px' }}
            >
              <TableContainer component={Paper} style={{ height: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ width: '100%', background: '#34282808', textAlign: 'left', paddingLeft: '28px' }}
                      >
                        Top Questions for {selectedRow}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Card>
                      {trendingUserInputs.map((row) => (
                        <div style={{ padding: '0.2rem 0.2rem 0rem 0.2rem' }}>
                          <Chip
                            className={classes.inputs}
                            style={{ width: '100%', justifyContent: 'left', height: '45px' }}
                            label={row.userInput}
                          />
                        </div>
                      ))}
                    </Card>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

TrendingTopics.propTypes = {
  trendingTopics: shapes.modelOf(TrendingTopicsData).isRequired,
  trendingTopicByBook: shapes.modelOf(TrendingTopicByBook).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  inject('trendingTopics', 'trendingTopicByBook', 'language')(observer(TrendingTopics))
);

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@mui/material/FormGroup';
import {
  DialogActions,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField,
  Divider
} from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Framework, { shapes } from '@greenville/framework';
import _ from 'lodash';
import Roles from '../../models/ChatutilityRoles';
import rolesDialogConfig from '../../../../common/config/users/RolesDialogConfig';
import Users from '../../models/ChatutilityUsers';
import * as constants from '../../../../common/constants';
import ChatutilityPermissions from '../../models/ChatutilityPermissions';

const styles = (theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    flex: 1,
    textAlign: 'left'
  },
  grid: {
    width: '85%',
    marginLeft: theme.spacing(3)
  },
  textFields: {
    height: 31,
    fontSize: 12,
    width: '30ch'
  },
  textfieldInput: {
    height: 11,
    fontSize: '14px'
  },
  selectfieldInput: {
    height: 31,
    fontSize: 14,
    width: '23.5ch'
  },
  divider: {
    margin: '0px 24px 12px 24px'
  },
  checkboxfieldInput: {
    fontSize: '12px'
  }
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
@inject('chatutilityusersState', 'userPermissions')
@observer
class ChatutilityEditRole extends React.Component {
  static propTypes = {
    chatutilityusersState: shapes.modelOf(Users).isRequired,
    classes: PropTypes.object.isRequired,
    showEditData: PropTypes.object.isRequired,
    closeShowAddEdit: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    chatutilityuserRoles: shapes.modelOf(Roles).isRequired,
    chatutilityuserPermissions: shapes.modelOf(ChatutilityPermissions).isRequired
  };

  constructor(props) {
    super(props);

    this.defaultSearch = {
      role_type_id: '',
      roleName: '',
      roleDescription: '',
      permissions: []
    };
    this.state = {
      openDialog: true,
      openUserPrivilegeDialog: false,
      userData: {
        role_type_id: null,
        roleName: null,
        roleDescription: null,
        permissions: []
      },
      editValue: [],
      error: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { showEditData } = props;
    let { editValue } = state;
    if (showEditData && Object.keys(editValue).length === 0) {
      editValue = showEditData;
      const newData = showEditData.permissions.$mobx.values;
      const permissionsData = [];
      newData.forEach((value) => {
        permissionsData.push(value.storedValue);
      });
      return {
        editValue: {
          role_type_id: showEditData.role_type_id,
          roleName: showEditData.role,
          roleDescription: showEditData.description,
          permissions: permissionsData
        }
      };
    }
    return null;
  }

  componentDidMount() {
    const { showEditData, chatutilityuserRoles } = this.props;
    chatutilityuserRoles.data.forEach((x) => {
      if (x.role === showEditData.role) {
        this.setState({ userData: { roleTypeId: x.role_type_id } });
      }
    });
  }

  handleClose = () => {
    const { closeShowAddEdit } = this.props;
    closeShowAddEdit();
  };

  reset = () => {
    this.setState({
      editValue: this.defaultSearch
    });
  };

  handleUserPrivilege = () => {
    const { openUserPrivilegeDialog } = this.state;
    this.setState({
      openUserPrivilegeDialog: !openUserPrivilegeDialog
    });
  };

  closeUploadContent = () => {
    const { openUserPrivilegeDialog } = this.state;
    this.setState({
      openUserPrivilegeDialog: !openUserPrivilegeDialog
    });
  };

  handleSave = () => {
    const { updateUser, showEditData, chatutilityusersState } = this.props;
    const { userData, editValue } = this.state;
    if (showEditData !== editValue) {
      if (!editValue.roleTypeId) {
        const data = userData.roleTypeId;
        editValue.roleTypeId = data;
      }
      if (editValue) {
        Object.keys(editValue).map((key) => {
          if (_.isString(editValue[key])) {
            editValue[key] = editValue[key].trim();
          }
          return editValue;
        });
      }
      const isValidated = this.validateSave(rolesDialogConfig.requiredFields);
      if (isValidated) {
        updateUser(editValue);
      } else {
        this.setState({ error: true });
      }
    }
    Framework.getEventManager().on(constants.SET_UPDATED_DATA, () => {
      chatutilityusersState.fetch();
    });
  };

  validateData = (value) => {
    const { showEditData } = this.props;
    const { editValue, userData } = this.state;
    if (Object.keys(showEditData).length > 0) {
      return _.isEmpty(editValue[value]) || _.isNil(editValue[value]);
    }
    return _.isEmpty(userData[value]) || _.isNil(userData[value]);
  };

  validateSave = (requiredArr) => {
    let bool = true;
    let required = false;
    requiredArr.forEach((value) => {
      required = this.validateData(value);
      if (required) {
        bool = false;
      }
    });
    return bool;
  };

  handleChange = (e) => {
    const { userData, error, editValue } = this.state;
    if (userData[e.target.name] === '0') {
      this.setState({
        error: !error
      });
    } else {
      this.setState({
        error: false
      });
    }
    let updatedObject = {};
    const intProperties = ['permissions'];
    if (intProperties.includes(e.target.name)) {
      const intValue = editValue.permissions;
      if (intValue.indexOf(e.target.value) > -1) {
        const index = intValue.indexOf(e.target.value);
        intValue.splice(index, 1);
      } else {
        intValue.push(e.target.value);
      }
      updatedObject = {
        [e.target.name]: intValue
      };
    } else {
      updatedObject = {
        [e.target.name]: e.target.value
      };
    }
    this.setState({
      editValue: { ...editValue, ...updatedObject }
    });
  };

  render() {
    const { classes, chatutilityuserPermissions } = this.props;
    const { editValue, openDialog } = this.state;
    const half = Math.ceil(chatutilityuserPermissions.data.length / 2);
    const firstHalf = chatutilityuserPermissions.data.slice(0, half);
    const secondHalf = chatutilityuserPermissions.data.slice(half);
    return (
      <>
        <Dialog
          fullWidth="true"
          maxWidth="md"
          open={openDialog}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar} color="primary">
            <Toolbar>
              <Typography variant="h3" className={classes.title}>
                Update Role
              </Typography>
              <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider className={classes.divider} />
          <Grid container className={classes.grid}>
            <Grid item xs={10}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="h5" color="textPrimary">
                    Role
                    <span style={{ color: 'red' }}> * </span>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={this.validateData('role')}
                    className={classes.textFields}
                    margin="dense"
                    name="roleName"
                    id="roleName"
                    variant="outlined"
                    onChange={this.handleChange}
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    value={editValue && editValue.roleName}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="h5" color="textPrimary">
                    Description
                    <span style={{ color: 'red' }}> * </span>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className={classes.textFields}
                    error={this.validateData('roleDescription')}
                    name="roleDescription"
                    id="roleDescription"
                    style={{ width: '100%' }}
                    onChange={this.handleChange}
                    margin="dense"
                    variant="outlined"
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    value={editValue && editValue.roleDescription}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="h5" color="textPrimary">
                    Permissions
                    <span style={{ color: 'red' }}> * </span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <FormGroup value={editValue.permissions} name="permissions" onChange={this.handleChange}>
                        {firstHalf.map((permissionValue) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="permissions"
                                value={permissionValue.permission}
                                checked={editValue.permissions.indexOf(permissionValue.permission) > -1}
                              />
                            }
                            style={{ fontSize: '12px' }}
                            label={permissionValue.permission}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <FormGroup value={editValue.permissions} name="permissions" onChange={this.handleChange}>
                        {secondHalf.map((permissionValue) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="permissions"
                                value={permissionValue.permission}
                                checked={editValue.permissions.indexOf(permissionValue.permission) > -1}
                              />
                            }
                            style={{ fontSize: '12px' }}
                            label={permissionValue.permission}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <span>
              <Button id="saveBtn" variant="outlined" color="primary" size="small" onClick={this.handleSave}>
                Update
              </Button>
            </span>
            <span>
              <Button id="cancelBtn" variant="outlined" color="primary" size="small" onClick={this.handleClose}>
                Cancel
              </Button>
            </span>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(ChatutilityEditRole)));

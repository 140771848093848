import { Grid, Paper, TextField, TextareaAutosize, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import * as constants from '../../../../common/constants';

const styles = () => ({
  flexBorder: {
    border: '1px solid grey',
    margin: '20px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    width: '100%',
    marginLeft: '10px !important'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '90%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '60px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginTop: '5px !important',
    marginLeft: '5px !important',
    marginBottom: '5px !important',
    textAlign: 'start'
  },
  heading: {
    marginTop: '20px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  },
  aiStudyToolcard: {
    height: '485px',
    overflowY: 'auto !important'
  },
  inputCard: { height: '160px', overflowY: 'auto !important' },
  promptCard: { height: '270px', overflowY: 'auto !important' },
  closeIconBox: { justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' },
  cardHeaderBackground: { background: '#34282808' },
  textFieldClasses: { width: '90%' },
  cardContent: { textAlign: 'start' }
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminPromptEvaluateDialog = (props) => {
  const { openDialog, handleClose, classes, selectedValue } = props;
  const [showResponse, setShowResponse] = useState(false);
  const handleRun = () => {
    setShowResponse(true);
  };

  const renderPromptStartTextArea = () => {
    return [...selectedValue.promptStart].map((startval, index) => {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <InputLabel id={index} className={classes.subLable}>
              Role: {startval.role}
            </InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.gridColumns}>
            <TextareaAutosize
              className={classes.textAreaSize}
              id={index}
              name={`promptStart_${startval.role}`}
              variant="outlined"
              onChange={() => {}}
              minRows={6}
              maxRows={12}
              value={startval.content}
            />
          </Grid>
        </Grid>
      );
    });
  };

  const renderPromptEndTextArea = () => {
    return [...selectedValue.promptEnd].map((endVal, index) => {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <InputLabel id={index} className={classes.subLable}>
              Role: {endVal.role}
            </InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.gridColumns}>
            <TextareaAutosize
              className={classes.textAreaSize}
              id={index}
              name={`promptEnd_${endVal.role}`}
              onChange={() => {}}
              variant="outlined"
              minRows={6}
              maxRows={12}
              value={endVal.content}
            />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
      <div className={classes.flexBorder}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} style={{ flexDirection: 'column' }}>
            <Box display="flex" className={classes.closeIconBox}>
              <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </Box>
          </Grid>
        </Box>
        <Box>
          <Grid item xs={16}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              {constants.EVALUATE} {selectedValue.type}
            </Typography>
            <Button variant="contained" style={{ margin: '10px' }} endIcon={<PlayArrowIcon />} onClick={handleRun}>
              {constants.RUN}
            </Button>
          </Grid>
        </Box>
        <Box sx={{ width: '100%' }} style={{ padding: '10px' }}>
          <Grid container spacing={2} style={{ minWidth: 800 }}>
            <Grid item xs={6}>
              <Box>
                <Item>
                  <Card className={classes.inputCard}>
                    <CardHeader title="Select Model" className={classes.cardHeaderBackground} align="left" />
                    <CardContent className={classes.cardContent}>
                      <FormControl className={classes.textFieldClasses} size="small">
                        <InputLabel id="SelectModel">Select Model</InputLabel>
                        <Select label="Select Model" lableId="SelectModel" name="SelectModel">
                          {/* TODO: options will change once we get original values */}
                          <MenuItem value="GPT3.5">GPT 3.5</MenuItem>
                          <MenuItem value="GPT4.0">GPT 4.0</MenuItem>
                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Item>
              </Box>
              <br />
              <Box>
                <Item>
                  <Card className={classes.inputCard}>
                    <CardHeader title="Input" className={classes.cardHeaderBackground} align="left" />
                    <CardContent className={classes.cardContent}>
                      <TextField
                        className={classes.textFieldClasses}
                        label="Text Input"
                        margin="dense"
                        name="text"
                        variant="outlined"
                      />
                    </CardContent>
                  </Card>
                </Item>
              </Box>
              <br />
              <Box>
                <Item>
                  <Card className={classes.promptCard}>
                    <CardHeader
                      title="Prompt String (Start + End)"
                      className={classes.cardHeaderBackground}
                      align="left"
                    />
                    <CardContent>
                      <>
                        <Grid item xs={12}>
                          <Divider className={classes.heading}>
                            <Chip label="Prompt Start" size="medium" />
                          </Divider>
                        </Grid>
                        {renderPromptStartTextArea()}
                        <Grid item xs={12}>
                          <Divider className={classes.heading}>
                            <Chip label="Prompt End" size="medium" />
                          </Divider>
                        </Grid>
                        {renderPromptEndTextArea()}
                      </>
                    </CardContent>
                  </Card>
                </Item>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {showResponse && (
                <>
                  <Box>
                    <Item>
                      <Card className={classes.aiStudyToolcard}>
                        <CardHeader title="AI Response" className={classes.cardHeaderBackground} align="left" />
                        <CardContent>TEST</CardContent>
                      </Card>
                    </Item>
                  </Box>
                  <br />
                  <Box>
                    <Item>
                      <Card className={classes.inputCard}>
                        <CardHeader
                          title="True Lens Metrics Scores"
                          className={classes.cardHeaderBackground}
                          align="left"
                        />
                        <CardContent className={classes.cardContent}>
                          <TextField
                            className={classes.textFieldClasses}
                            label="True Lens Metrics Scores"
                            margin="dense"
                            name="text"
                            variant="outlined"
                          />
                        </CardContent>
                      </Card>
                    </Item>
                  </Box>
                  <br />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};

AdminPromptEvaluateDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminPromptEvaluateDialog);

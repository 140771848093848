import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const roleObj = types.model('roleObj', {
  prompt: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  safety: types.maybeNull(types.string),
  grounding: types.maybeNull(types.string),
  userTopic: types.maybeNull(types.string)
});

const botResponseArray = types.model('botResponseArray', {
  botSummary: types.optional(types.array(types.string), []),
  processedSummary: types.optional(types.array(types.string), [])
});

const SummaryResponse = types.model('SummaryResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  botResponse: types.maybeNull(types.optional(botResponseArray, {})),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.optional(types.array(types.string), [])),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  reasonForDisLike: types.optional(types.array(types.string), []),
  feedbackContent: types.maybeNull(types.optional(types.array(types.string), []))
});

const DiscussResponse = types.model('DiscussResponse', {
  bookId: types.maybeNull(types.string),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  reasonForDisLike: types.optional(types.array(types.string), [])
});

const promptsObj = types.model('promptsObj', {
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  status: types.maybeNull(types.string)
});

const contentfilterResponse = types.model('contentfilterResponse', {
  Category: types.maybeNull(types.string),
  Severity: types.maybeNull(types.string)
});

const blockListResult = types.model('blockListResult', {
  blocklistName: types.maybeNull(types.string),
  blockItemText: types.maybeNull(types.string)
});

const chatIntentBotResponse = types.model('chatIntentBotResponse', {
  format: types.maybeNull(types.string),
  intent: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  source_type: types.maybeNull(types.string),
  topic: types.maybeNull(types.string)
});

const botResponseAllValues = types.model('botResponseAllValues', {
  blocklistsMatchResults: types.maybeNull(types.optional(types.array(blockListResult), [])),
  Category_Severity: types.maybeNull(types.optional(types.array(contentfilterResponse), [])),
  chatIntentResponse: types.maybeNull(types.array(chatIntentBotResponse)),
  explainStandAloneBotResponse: types.maybeNull(types.string),
  mathMcqResponse: types.maybeNull(types.array(types.string)),
  nonMathMcqValidationResponse: types.maybeNull(types.array(types.string)),
  mathMcqCoreConceptResponse: types.maybeNull(types.array(types.string)),
  mathMcqQuestionGenerationResponse: types.maybeNull(types.array(types.string)),
  summarygpt4oResponse: types.maybeNull(types.array(types.string))
});

const commonTypeModel = types.model('SubType', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.union(types.array(roleObj), types.string)),
  botResponse: types.maybeNull(types.union(botResponseAllValues, types.array(types.string))),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  finalStatus: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const QuizMcqResponse = types.model('QuizMcqResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const data = types.model('data', {
  channelsId: types.maybeNull(types.string),
  bookId: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  clusterName: types.maybeNull(types.string),
  clusterId: types.maybeNull(types.string),
  userCommand: types.maybeNull(types.string),
  dateStamp: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  likeCount: types.maybeNull(types.number),
  disLikeCount: types.maybeNull(types.number),
  explainCount: types.maybeNull(types.number),
  summaryCount: types.maybeNull(types.number),
  quizMcqCount: types.maybeNull(types.number),
  blockWordsCount: types.maybeNull(types.number),
  unknownCount: types.maybeNull(types.number),
  unAnsweredCount: types.maybeNull(types.number),
  unAnsweredNoCSGDocCount: types.maybeNull(types.number),
  unAnsweredUnknownIntentCount: types.maybeNull(types.number),
  unAnsweredNoTopicFoundCount: types.maybeNull(types.number),
  unAnsweredProblemSolveCount: types.maybeNull(types.number)
});

const typeString = types.model('typeString', {
  S: types.maybeNull(types.string)
});
const lastEvaluvatedKey = types.model('lastEvaluvatedKey', {
  channelsId: types.maybeNull(types.string),
  bookId: types.maybeNull(typeString),
  serverDateTime: types.maybeNull(typeString),
  referenceId: types.maybeNull(typeString),
  type: types.maybeNull(typeString),
  feedbackId: types.maybeNull(typeString),
  userId: types.maybeNull(typeString)
});

const ExclusiveStartKey = types.model('ExclusiveStartKey', {
  ExclusiveStartKey: types.optional(lastEvaluvatedKey, {})
});

const CommonSearchModel = types
  .model('CommonSearchModel', {
    data: types.optional(types.array(data), []),
    summary: types.optional(types.array(SummaryResponse), []),
    discuss: types.optional(types.array(DiscussResponse), []),
    quizMcq: types.optional(types.array(QuizMcqResponse), []),
    quizIntent: types.optional(types.array(QuizMcqResponse), []),
    mcq: types.optional(types.array(QuizMcqResponse), []),
    commonAuditData: types.optional(types.array(commonTypeModel), []),
    isNextPageKey: types.optional(ExclusiveStartKey, {})
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_COMMON_SEARCH_UTILITY_DATA, {
        payload
      });
    },
    setResponse(response) {
      /* eslint-disable */
      if (
        response &&
        response.data &&
        (response.data.data ||
          response.data.summary ||
          response.data.discuss ||
          response.data.quizMcq ||
          response.data.quizIntent ||
          response.data.mcq ||
          response.data.commonAuditData)
      ) {
        applySnapshot(self, response.data);
      } else {
        applySnapshot(self, response);
      }

      /* eslint-disable */
      Framework.getEventManager().publish(constants.GET_COMMON_SEARCH_UTILITY_RESPONSE);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      applySnapshot(self.summary, initialState);
      applySnapshot(self.discuss, initialState);
      applySnapshot(self.quizMcq, initialState);
      applySnapshot(self.quizIntent, initialState);
      applySnapshot(self.mcq, initialState);
      applySnapshot(self.commonAuditData, initialState);
      applySnapshot(self.isNextPageKey, {});
      Framework.getEventManager().publish(constants.GET_COMMON_SEARCH_UTILITY_RESPONSE);
    }
  }));

export default CommonSearchModel;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FormHelperText } from '@mui/material';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import AutocompleteComponent from '../../common/components/Autocomplete';
import './dynamicComponent.css';
import ButtonComponent from '../../common/components/ButtonComponent';
import CustomSelect from '../../common/components/CustomSelect';
import CustomTextField from '../../common/components/CustomTextField';
import CustomDatePicker from '../../common/components/CustomDatePicker';

function ObserveSearchComponent(props) {
  const {
    classes,
    searchFields,
    endDisabled,
    bookList,
    inputValue,
    onAutoCompleteChange,
    onInputChange,
    handleDateChange,
    handleChange,
    handleSearch,
    tenantName,
    isbookIdRequired
  } = props;

  const setAutocompleteLable = () => {
    let lableVal = 'Book Id';
    if (tenantName === 'servicenow') {
      lableVal = 'Knowledge Based Group';
    }
    if (tenantName === 'pvs') {
      lableVal = 'Course Id';
    }
    return lableVal;
  };

  return (
    <Grid container spacing={1} alignItems="center" className="flexwrap padding8">
      <Grid item xs={12} sm={6} md={2}>
        {/* <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}> */}
        {/* <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small"> */}
        <AutocompleteComponent
          options={bookList}
          onAutoCompleteChange={onAutoCompleteChange}
          onInputChange={onInputChange}
          stateVal={inputValue}
          // errorVal={isbookIdRequired}
          name="bookId"
          label={setAutocompleteLable()}
        />
        {isbookIdRequired && (
          <FormHelperText style={{ color: 'red' }}>Please select any {setAutocompleteLable()}</FormHelperText>
        )}
        {/* </FormControl> */}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomSelect
          label="Type"
          name="type"
          value={searchFields.type || ''}
          onChange={(event) => handleChange(event)}
          options={ChatgptConfig.filterByChats}
          // error="error"
          // helperText={true ? 'This field is required' : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomTextField
          label="User ID"
          value={searchFields.userId}
          onChange={(event) => handleChange(event)}
          name="userId"
          variant="outlined"
          // error={!!errors.username}
          // helperText={errors.username}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomDatePicker
          label="Start Date"
          dataKey="startDateTime"
          value={searchFields.startDateTime || null}
          handleDateChange={handleDateChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomDatePicker
          label="End Date"
          dataKey="endDateTime"
          value={searchFields.endDateTime || null}
          handleDateChange={handleDateChange}
          disabled={endDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <ButtonComponent classes={classes} handleClick={handleSearch} />
      </Grid>
    </Grid>
  );
}

ObserveSearchComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  searchFields: PropTypes.object.isRequired,
  endDisabled: PropTypes.bool.isRequired,
  bookList: PropTypes.array.isRequired,
  inputValue: PropTypes.string.isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  tenantName: PropTypes.string.isRequired,
  isbookIdRequired: PropTypes.string.isRequired
};

ObserveSearchComponent.defaultProps = {
  handleDateChange: () => {}
};

export default ObserveSearchComponent;

import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { PropTypes } from 'prop-types';
import React from 'react';

const MathJaxWrapper = ({ children }) => {
  const config = {
    loader: { load: ['[tex]/html', 'input/asciimath', 'input/mml', 'output/chtml'] },
    'fast-preview': {
      disabled: true
    },
    tex2jax: {
      inlineMath: [
        ['$', '$'],
        ['\\(', '\\)'],
        ['`', '`']
      ],
      displayMath: [
        ['$$', '$$'],
        ['\\[', '\\]'],
        ['`', '`']
      ]
    },
    messageStyle: 'none'
  };

  return (
    <MathJaxContext version={3} config={config}>
      <MathJax>{children}</MathJax>
    </MathJaxContext>
  );
};

MathJaxWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default MathJaxWrapper;

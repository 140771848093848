import { Box, CircularProgress, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

const AssessmentLoader = ({ tip }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="70vh"
      bgcolor="#f5f5f5"
      p={3}
    >
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" position="relative">
        <CircularProgress
          color="primary"
          size={50}
          sx={{
            animation: 'rotate 1.5s linear infinite',
            '@keyframes rotate': {
              '0%': { transform: 'rotate(0deg)' },
              '100%': { transform: 'rotate(360deg)' }
            },
            '& .MuiCircularProgress-svg': {
              filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))'
            }
          }}
        />
      </Box>
      <Box>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontWeight: 'bold',
            fontSize: '1.2rem'
          }}
        >
          {tip}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body1"
          color="primary"
          sx={{
            position: 'absolute',
            top: '32%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontWeight: 'bold',
            fontSize: '1.2rem'
          }}
        >
          AI is Generating Your items ...
        </Typography>
      </Box>
    </Box>
  );
};
AssessmentLoader.propTypes = {
  tip: PropTypes.string.isRequired
};

export default AssessmentLoader;

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import home from './home';
import error from './error';
import pdfviewer from './pdfviewer';
import books from './books';
import users from './users';
import entitlement from './entitlement';
import serverSideProcess from './serverSideProcess';
import dashboard from './dashboard';
import contentmanager from './contentManager';
import eTextConversion from './eTextConversion';
import queryBuilder from './queryBuilder';
import bulkUtility from './bulkUtility';
import chatUtility from './chatUtility';
import aiadmin from './aiadmin';
import serviceNow from './serviceNow';

export default [
  home,
  books,
  pdfviewer,
  users,
  error,
  entitlement,
  serverSideProcess,
  contentmanager,
  dashboard,
  eTextConversion,
  queryBuilder,
  bulkUtility,
  chatUtility,
  aiadmin,
  serviceNow
];

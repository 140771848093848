import React from 'react';
import ValidatePermissions from '../../../../users/ValidatePermissions';
import TrendingTopics from './TrendingTopics';

/* eslint-disable no-shadow */
function TrendingTopicsView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <TrendingTopics />
      </ValidatePermissions>
    </>
  );
}

TrendingTopicsView.propTypes = {};

TrendingTopicsView.defaultProps = {};

export default TrendingTopicsView;

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Box, Typography, Select, Slide, Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import Framework, { shapes } from '@greenville/framework';
import _ from 'lodash';
// import { toJS } from 'mobx';
import Roles from '../../models/ChatutilityRoles';
import UserDialogConfig from '../../../../common/config/users/UserDialogConfig';
import Users from '../../models/ChatutilityUsers';
// import ValidatePermissions from '../../ValidatePermissions';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import * as constants from '../../../../common/constants';
// import UsersPermissions from '../../models/UsersPermissions';

const styles = (theme) => ({
  posClass: {
    cursor: 'pointer',
    color: '#005d83',
    '&:hover': {
      fontWeight: 'bold',
      color: '#005d83',
      textDecoration: 'underline',
      textDecorationColor: '#005d83'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  display: {
    display: 'flex'
  },
  customDisplay: {
    position: 'relative',
    marginTop: '1%'
  },
  customText: {
    position: 'absolute',
    top: '10%',
    left: '80%',
    width: '50%'
  }
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
@inject('chatutilityusersState', 'userPermissions')
@observer
class ChatutilityEditUser extends React.Component {
  static propTypes = {
    chatutilityusersState: shapes.modelOf(Users).isRequired,
    classes: PropTypes.object.isRequired,
    showEditData: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    closeShowAddEdit: PropTypes.func.isRequired,
    showAddUserText: PropTypes.bool.isRequired,
    updateUser: PropTypes.func.isRequired,
    chatutilityuserRoles: shapes.modelOf(Roles).isRequired
    // userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);

    this.defaultSearch = {
      firstName: '',
      lastName: '',
      userLogin: '',
      email: ''
    };
    this.state = {
      openDialog: true,
      openUserPrivilegeDialog: false,
      userData: {
        firstName: null,
        lastName: null,
        userLogin: null,
        email: null,
        roleTypeId: null
      },
      editValue: {},
      error: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { showEditData } = props;
    const { editValue } = state;
    if (showEditData && Object.keys(editValue).length === 0) {
      return {
        editValue: showEditData
      };
    }
    return null;
  }

  componentDidMount() {
    const { showEditData, chatutilityuserRoles } = this.props;
    chatutilityuserRoles.data.forEach((x) => {
      if (x.role === showEditData.role) {
        this.setState({ userData: { roleTypeId: x.role_type_id } });
      }
    });
  }

  handleClose = () => {
    const { closeShowAddEdit } = this.props;
    closeShowAddEdit();
  };

  reset = () => {
    this.setState({
      editValue: this.defaultSearch
    });
  };

  handleUserPrivilege = () => {
    const { openUserPrivilegeDialog } = this.state;
    this.setState({
      openUserPrivilegeDialog: !openUserPrivilegeDialog
    });
  };

  closeUploadContent = () => {
    const { openUserPrivilegeDialog } = this.state;
    this.setState({
      openUserPrivilegeDialog: !openUserPrivilegeDialog
    });
  };

  handleSave = () => {
    const { updateUser, showEditData, chatutilityusersState } = this.props;
    const { userData, editValue } = this.state;
    if (showEditData !== editValue) {
      if (!editValue.roleTypeId) {
        const data = userData.roleTypeId;
        editValue.roleTypeId = data;
      }
      if (editValue) {
        Object.keys(editValue).map((key) => {
          if (_.isString(editValue[key])) {
            editValue[key] = editValue[key].trim();
          }
          return editValue;
        });
      }
      const isValidated = this.validateSave(UserDialogConfig.requiredFields);
      if (isValidated) {
        updateUser(editValue);
      } else {
        this.setState({ error: true });
      }
    }
    Framework.getEventManager().on(constants.SET_UPDATED_DATA, () => {
      chatutilityusersState.fetch();
    });
  };

  validateData = (value) => {
    const { showEditData } = this.props;
    const { editValue, userData } = this.state;
    if (Object.keys(showEditData).length > 0) {
      return _.isEmpty(editValue[value]) || _.isNil(editValue[value]);
    }
    return _.isEmpty(userData[value]) || _.isNil(userData[value]);
  };

  validateSave = (requiredArr) => {
    let bool = true;
    let required = false;
    requiredArr.forEach((value) => {
      required = this.validateData(value);
      if (required) {
        bool = false;
      }
    });
    return bool;
  };

  handleChange = (e) => {
    const { userData, error, editValue } = this.state;
    const { chatutilityuserRoles } = this.props;
    if (userData[e.target.name] === '0') {
      this.setState({
        error: !error
      });
    } else {
      this.setState({
        error: false
      });
    }
    let updatedObject = {};
    const intProperties = ['role_type_id'];
    let roleValue = {};
    if (intProperties.includes(e.target.name)) {
      const intValue = Number(e.target.value);
      if (intValue !== Number(editValue.roleId)) {
        chatutilityuserRoles.data.forEach((x) => {
          if (Number(x.role_type_id) === intValue) {
            roleValue = { role: x.role };
          }
        });
      }
      updatedObject = {
        [e.target.name]: intValue,
        ...roleValue
      };
    } else {
      updatedObject = {
        [e.target.name]: e.target.value
      };
    }
    this.setState({
      userData: { ...userData, ...updatedObject },
      editValue: { ...editValue, ...updatedObject }
    });
  };

  render() {
    const { classes, language, showAddUserText, chatutilityuserRoles } = this.props;
    // userData editValue
    // const permissionsList = toJS(userPermissions.permissionList.permissions);

    const { editValue, userData, openDialog } = this.state;
    return (
      <>
        <br />
        <Dialog
          fullWidth="true"
          maxWidth="lg"
          open={openDialog}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <Typography align="center" variant="h2">
            {showAddUserText ? 'Edit User' : 'Add User'}
          </Typography>
          <br />
          <br />
          <Paper elevation={3}>
            <Box pt={2}>
              <Grid container justify="center">
                <Grid item xs={2} className={classes.display} justify="flex-start" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="h5" color="textPrimary">
                      <FormattedMessage {...language.getText('useraction.FIRSTNAME')} />
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={this.validateData('firstName')}
                    className={classes.textFields}
                    margin="dense"
                    name="firstName"
                    id="firstName"
                    // placeholder="First Name"
                    style={{ width: '100%' }}
                    variant="outlined"
                    onChange={this.handleChange}
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    value={editValue && editValue.firstName}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={2} className={classes.display} justify="flex-start" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="h5" color="textPrimary">
                      <FormattedMessage {...language.getText('useraction.LASTNAME')} />
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textFields}
                    error={this.validateData('lastName')}
                    name="lastName"
                    id="lastName"
                    // placeholder="Last Name"
                    style={{ width: '100%' }}
                    onChange={this.handleChange}
                    margin="dense"
                    variant="outlined"
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    value={editValue && editValue.lastName}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={2} className={classes.display} justify="flex-start" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="h5" color="textPrimary">
                      <FormattedMessage {...language.getText('useraction.USERLOGIN')} />
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={this.validateData('userLogin')}
                    className={classes.textFields}
                    name="userLogin"
                    id="userLogin"
                    // placeholder="User ID"
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                    variant="outlined"
                    margin="dense"
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    value={editValue && editValue.userLogin}
                    disabled="true"
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={2} className={classes.display} justify="flex-start" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="h5" color="textPrimary">
                      <FormattedMessage {...language.getText('useraction.EMAIL')} />
                      <span style={{ color: 'red' }}> * </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={this.validateData('email')}
                    className={classes.textFields}
                    name="email"
                    id="email"
                    style={{ width: '100%' }}
                    // placeholder="Email"
                    onChange={this.handleChange}
                    variant="outlined"
                    margin="dense"
                    InputProps={{ classes: { input: classes.textfieldInput } }}
                    value={editValue && editValue.email}
                    disabled="true"
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={2} className={classes.display} justify="flex-start" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="h5" color="textPrimary">
                      <FormattedMessage {...language.getText('useraction.ROLE')} />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} className={classes.customDisplay}>
                  <Select
                    native
                    variant="outlined"
                    onChange={this.handleChange}
                    // InputProps={{ classes: { input: classes.selectfieldInput } }}
                    style={{ width: '100%' }}
                    name="roleTypeId"
                    margin="dense"
                    className={classes.selectfieldInput}
                    value={userData.roleTypeId}
                    defaultValue={editValue.role}
                  >
                    {chatutilityuserRoles.data.map((roleValue) => {
                      return <option value={roleValue.role_type_id}>{roleValue.role}</option>;
                    })}
                  </Select>
                </Grid>
              </Grid>
              <br />
              <br />

              <Grid container justify="center">
                <Grid item xs={2} className={classes.display} justify="center" alignItems="center">
                  <span>
                    <Button
                      id="saveBtn"
                      variant="outlined"
                      // disabled={!permissionsList.includes('user_can_edit')}
                      color="primary"
                      size="small"
                      onClick={this.handleSave}
                    >
                      Save
                    </Button>
                  </span>
                </Grid>
                <Grid item xs={2} className={classes.display} justify="center" alignItems="center" onClick={this.reset}>
                  <span>
                    <Button id="saveBtn" variant="outlined" color="primary" size="small">
                      Reset
                    </Button>
                  </span>
                </Grid>
                <Grid item xs={2} className={classes.display} justify="center" alignItems="center">
                  <TooltipCustom title={<FormattedMessage {...language.getText('user.CANCEL_EDITING_USER')} />}>
                    <span>
                      <Button id="cancelBtn" variant="outlined" color="primary" size="small" onClick={this.handleClose}>
                        Cancel
                      </Button>
                    </span>
                  </TooltipCustom>
                </Grid>
              </Grid>
            </Box>
            <br />
            <br />
          </Paper>
          <br />
          <br />
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(ChatutilityEditUser)));

import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../../../../common/Loader';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import AutocompleteComponent from '../../common/components/Autocomplete';
import SearchButtonComponent from '../../common/components/SearchButtonComponent';
import CommonSearchModel from '../../models/CommonSearchModel';
import McqSearch from '../../models/McqSearch';
import DynamicTrendsComponent from './DynamicTrendsComponent';
import DynamicDataGrid from './dataGrid/DynamicDataGrid';

const materialTheme = createTheme({
  overrides: {
    // MuiPickersBasePicker:{
    // },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const styles = (theme) => ({
  datePicker: {
    // width: '49%',
    marginTop: 'inherit'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  },
  searchBarClass: {
    width: '25vw'
  }
});

@inject('commonSearchUtility', 'commonSearchUtilityStatus', 'mcqSearch')
@observer
class DynamicComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    commonSearchUtility: shapes.modelOf(CommonSearchModel).isRequired,
    commonSearchUtilityStatus: shapes.state.isRequired,
    mcqSearch: shapes.modelOf(McqSearch).isRequired,
    history: PropTypes.object.isRequired,
    selectedTabValue: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    const { commonSearchUtility } = props;
    this.defaultSearch = {
      bookId: '',
      userId: '',
      email: '',
      startDateTime: '',
      endDateTime: '',
      type: '',
      startDate: '',
      endDate: ''
    };
    this.state = {
      newRequest: true,
      searchFields: {},
      searchedDataResponse: commonSearchUtility,
      endDisabled: true,
      loaderStatus: true,
      isbookIdRequired: false,
      bookList: [],
      inputValue: '',
      tokenValue: '',
      tenantId: '',
      tenantName: '',
      tenantTitle: '',
      showDateFields: false,
      showOutline: '1D',
      clusterState: 'cluster'
    };
  }

  componentDidMount = () => {
    const { commonSearchUtility } = this.props;
    this.setTenantDetails();
    // if (mcqSearch && mcqSearch.data.length > 0) {
    //   this.setState({
    //     bookList: mcqSearch.data
    //   });
    // }
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.GET_COMMON_SEARCH_UTILITY_RESPONSE, () => {
      const { selectedTabValue } = this.props;
      const { newRequest, searchedDataResponse, bookList, tenantName } = this.state;
      const { data } = commonSearchUtility;
      if (commonSearchUtility) {
        let combineData = [];
        if (selectedTabValue === 1) {
          const { summary, quiz, discuss } = commonSearchUtility;
          const summaryData = summary && summary.length > 0 ? summary : [];
          const quizData = quiz && quiz.length > 0 && quiz ? quiz : [];
          const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
          combineData = [...summaryData, ...quizData, ...discussData];
        } else {
          combineData = [...data];
        }
        bookList.forEach((item) => {
          const alteredValue = item.bookId;
          if (!alteredValue) return;
          combineData.forEach((dataValue, index) => {
            if (dataValue.bookId === alteredValue) {
              combineData[index].title = item.title;
            }
          });
        });
        let finalData;
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        // TODO: Below condition for POC Only
        if (selectedTabValue === 0 || selectedTabValue === 1) {
          if (tenantName === 'pvs') {
            finalData = combineDatatoJS.filter(
              (item) =>
                ['pvs-c9bc464d-bf64-44b7-9067-84644db44eea', 'pvs-22706eae-864a-4467-9df9-f803ff8170c4'].includes(
                  item.bookId
                ) && ['summary', 'answer', 'quiz_mcq'].includes(item.type)
            );
          } else if (tenantName === 'servicenow') {
            finalData = combineDatatoJS.filter(
              (item) => ['cf426e7b1bd209508f22337f034bcbe8'].includes(item.bookId) && ['answer'].includes(item.type)
            );
          } else {
            finalData = combineDatatoJS.filter(
              (item) =>
                ['rplus-60ff996c0e429e21ee07cce2'].includes(item.bookId) &&
                ['summary', 'answer', 'quiz_mcq'].includes(item.type)
            );
          }
        } else {
          finalData = combineDatatoJS;
        }
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = finalData.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState({ searchedDataResponse: sortedDate });
        } else {
          const combineDataGpt = [...searchedDataResponse, ...finalData];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState(() => ({ searchedDataResponse: sortedDate }));
        }
      }
    });
    /* eslint-enable camelcase */
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mcqSearch, selectedTabValue } = this.props;
    const { tokenValue } = this.state;
    if (prevState.tokenValue !== tokenValue && mcqSearch && mcqSearch.data.length === 0) {
      this.getBookData();
    }
    if (prevProps.selectedTabValue !== selectedTabValue) {
      this.handleReset();
    }
  };

  setTenantDetails = () => {
    const { history } = this.props;
    const selectedTabName = history.location.pathname.split('/');
    ChatgptConfig.tenantData.forEach((item) => {
      if (item.name === selectedTabName[2]) {
        this.setState({
          tenantId: item.tenantId,
          tenantName: item.name,
          bookList: item.titles,
          tenantTitle: item.title
        });
      }
    });
  };

  getBookData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('mcqSearch').setResponse(response.data);
      // this.setState({
      //   bookList: response.data.data
      // });
    }
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged },
      isbookIdRequired: false
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const { selectedTabValue } = this.props;
    const fieldChanged = {};
    fieldChanged[name] =
      selectedTabValue === 2
        ? moment(date).format(constants.YYYY_MM_DD_FORMAT)
        : moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDateTime || payload.startDate) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleReset = () => {
    const { commonSearchUtility } = this.props;
    this.setState({
      endDisabled: true,
      isbookIdRequired: false,
      searchFields: {
        ...this.defaultSearch
      },
      inputValue: '',
      showOutline: '1D',
      clusterState: 'cluster',
      searchedDataResponse: []
    });
    commonSearchUtility.resetStoreValues();
  };

  handleSearch = async (customPayload) => {
    const { searchFields, tenantId, tenantName, clusterState } = this.state;
    const { commonSearchUtility, selectedTabValue } = this.props;

    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }
    const payload = { ...searchFields, ...customPayload };
    if (selectedTabValue === 2) {
      let endDateChanged = new Date();
      endDateChanged = moment(endDateChanged).format(constants.YYYY_MM_DD_FORMAT);

      if ((payload && payload.startDate) || (payload && payload.bookId)) {
        if (!payload.endDate && payload.startDate) {
          payload.endDate = endDateChanged;
          this.setState({
            searchFields: { ...searchFields, endDate: endDateChanged }
          });
        }
      } else {
        payload.endDate = endDateChanged;
        payload.startDate = endDateChanged;
        this.setState({
          searchFields: { ...searchFields, startDate: endDateChanged, endDate: endDateChanged }
        });
      }
    }
    // TODO: Below condition for POC Only
    if (selectedTabValue === 0 || selectedTabValue === 1) {
      payload.startDateTime = '06/01/2024';
    }
    if (
      payload &&
      (payload.bookId ||
        payload.email ||
        payload.userId ||
        payload.type ||
        payload.startDate ||
        payload.endDate ||
        payload.manifestId ||
        payload.ingestionStatus)
    ) {
      payload.tenantId = tenantId;
      payload.tenantName = tenantName;
      payload.selectedTabValue = selectedTabValue;
      if (selectedTabValue === 2) payload.clusterState = clusterState;
      if (selectedTabValue === 3 && tenantName === 'pvs') {
        const { tokenValue } = this.state;
        const headers = {
          Authorization: `Bearer ${tokenValue}`
        };
        const response = await axios.post(
          `${env.EVERGREEN_API_BASE_URL}${constants.PVS_INGESTION_URL}`,
          {},
          { headers }
        );
        if (response && response.data && response.data) {
          // TODO fields mapping will change
          const pvsData = response.data.map((item) => ({
            manifestId: item.contentPublishMessage.payload.manifestId,
            courseId: item.contentPublishMessage.payload.courseId,
            correlationId: item.contentPublishMessage.payload.correlationId,
            messageTypeCode: item.contentPublishMessage.payload.messageTypeCode,
            status: item.status.code
          }));
          const responseObj = {
            status: 'success',
            data: pvsData
          };
          Framework.getStoreRegistry().getStore('commonSearchUtility').setResponse(responseObj);
        }
      } else {
        commonSearchUtility.fetch(payload);
      }
    } else {
      this.setState({ isbookIdRequired: true });
    }
  };

  handleAscending = (dir, value) => {
    const { searchedDataResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...searchedDataResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...searchedDataResponse,
        searchedDataResponse: sorted
      });
    } else {
      const sorted = [...searchedDataResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...searchedDataResponse,
        searchedDataResponse: sorted
      });
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        bookId: newValue !== null ? newValue.bookId : ''
      },
      isbookIdRequired: false
    });
  };

  handleSelectChange = (e) => {
    const { commonSearchUtility } = this.props;
    this.setState({
      clusterState: e.target.value
    });
    commonSearchUtility.resetStoreValues();
  };

  handleDateclicker = (event, value) => {
    const { searchFields } = this.state;
    const daysvalue = event.target.innerText;
    let startDate = new Date();
    let endDate = new Date();
    switch (daysvalue) {
      case '1d':
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '3d':
        startDate.setDate(startDate.getDate() - 2);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '1w':
        startDate.setDate(startDate.getDate() - 6);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      default:
    }
    startDate = moment(startDate).format(constants.YYYY_MM_DD_FORMAT);
    endDate = moment(endDate).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, startDate, endDate }
    });
  };

  trendsDataFetch = (value) => {
    const { showDateFields } = this.state;
    if (value === 'custom') {
      this.setState({
        showOutline: value,
        showDateFields: !showDateFields
      });
    }
  };

  setAutocompleteLable = () => {
    const { tenantName } = this.state;
    let lableVal = 'Book Id';
    if (tenantName === 'servicenow') {
      lableVal = 'Knowledge Based Group';
    }
    if (tenantName === 'pvs') {
      lableVal = 'Course Id';
    }
    return lableVal;
  };

  render() {
    const {
      classes,
      commonSearchUtilityStatus,
      selectedTabValue,
      commonSearchUtility: { isNextPageKey },
      history
    } = this.props;
    const {
      searchFields,
      endDisabled,
      searchedDataResponse,
      loaderStatus,
      bookList,
      isbookIdRequired,
      inputValue,
      tokenValue,
      showDateFields,
      clusterState,
      showOutline,
      tenantName,
      tenantTitle
    } = this.state;

    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }
    return (
      <>
        <Paper>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                {constants.CHAT_CRITERIA_TEXT}
              </Typography>
            </Box>
            {selectedTabValue === 2 ? (
              <DynamicTrendsComponent
                showDateFields={showDateFields}
                searchFields={searchFields}
                endDisabled={endDisabled}
                showOutline={showOutline}
                clusterState={clusterState}
                bookList={bookList}
                inputValue={inputValue}
                handleSelectChange={this.handleSelectChange}
                trendsDataFetch={this.trendsDataFetch}
                handleDateclicker={this.handleDateclicker}
                onInputChange={this.onInputChange}
                onAutoCompleteChange={this.onAutoCompleteChange}
                handleDateChange={this.handleDateChange}
                tenantName={tenantName}
              />
            ) : (
              <>
                {history.location.pathname.split('/')[3] !== 'ingestion' ? (
                  <>
                    <Grid container direction="row">
                      <Grid item xs={1} />
                      <Grid item xs={10}>
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}>
                          <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                            <AutocompleteComponent
                              options={bookList}
                              onAutoCompleteChange={this.onAutoCompleteChange}
                              onInputChange={this.onInputChange}
                              stateVal={inputValue}
                              errorVal={isbookIdRequired}
                              name="bookId"
                              label={this.setAutocompleteLable()}
                            />
                            {isbookIdRequired && (
                              <FormHelperText style={{ color: 'red' }}>
                                Please select any {this.setAutocompleteLable()}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <KeyboardDatePicker
                                className={classes.datePicker}
                                format={constants.MM_DD_YYYY_FORMAT}
                                autoOk
                                size="small"
                                variant="inline"
                                style={{ minWidth: '32%' }}
                                inputVariant="outlined"
                                disableFuture
                                value={searchFields.startDateTime || null}
                                label="Start Date"
                                onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                              />
                              <KeyboardDatePicker
                                className={classes.datePicker}
                                format={constants.MM_DD_YYYY_FORMAT}
                                autoOk
                                variant="inline"
                                size="small"
                                style={{ minWidth: '32%' }}
                                inputVariant="outlined"
                                disableFuture
                                value={searchFields.endDateTime || null}
                                label="End Date"
                                onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                                disabled={endDisabled}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <br />
                    <Grid container direction="row">
                      <Grid item xs={1} />
                      <Grid item xs={10} direction="row">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                          <TextField
                            label="User ID"
                            margin="dense"
                            name="userId"
                            onChange={(event) => this.handleChange(event)}
                            style={{ minWidth: '32%' }}
                            value={searchFields.userId}
                            variant="outlined"
                          />
                          <TextField
                            label="Email ID"
                            margin="dense"
                            type="email"
                            style={{ minWidth: '32%' }}
                            name="email"
                            onChange={(event) => this.handleChange(event)}
                            value={searchFields.email}
                            variant="outlined"
                          />
                          <FormControl sx={{ mt: 1, width: '32%' }} size="small">
                            <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                            <Select
                              label="Type"
                              name="type"
                              onChange={(event) => this.handleChange(event)}
                              value={searchFields.type || ''}
                            >
                              {tenantName === 'servicenow' ? (
                                <MenuItem value="answer">Explain</MenuItem>
                              ) : (
                                ChatgptConfig.filterByChats
                                  .filter((value, index) => index <= 2)
                                  .map((value, index) => (
                                    <MenuItem
                                      divider={index === 2}
                                      value={
                                        (index === 1 && (index === 1 ? 'answer' : value.name)) ||
                                        (index === 2 && (index === 2 ? 'quiz_mcq' : value.name)) ||
                                        value.name
                                      }
                                    >
                                      {value.label}
                                    </MenuItem>
                                  ))
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <br />
                  </>
                ) : (
                  <>
                    <Grid container direction="row">
                      <Grid item xs={1} />
                      <Grid item xs={10}>
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}>
                          <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                            <AutocompleteComponent
                              options={bookList}
                              onAutoCompleteChange={this.onAutoCompleteChange}
                              onInputChange={this.onInputChange}
                              stateVal={inputValue}
                              errorVal={isbookIdRequired}
                              name="bookId"
                              label={this.setAutocompleteLable()}
                            />
                            {isbookIdRequired && (
                              <FormHelperText style={{ color: 'red' }}>
                                Please select any {this.setAutocompleteLable()}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <TextField
                            label="Manifest ID"
                            margin="dense"
                            name="manifestId"
                            onChange={(event) => this.handleChange(event)}
                            style={{ minWidth: '32%', marginTop: '2px' }}
                            value={searchFields.manifestId}
                            variant="outlined"
                          />
                          <TextField
                            label="Ingestion Status"
                            margin="dense"
                            name="ingestionStatus"
                            onChange={(event) => this.handleChange(event)}
                            style={{ minWidth: '32%', marginTop: '2px' }}
                            value={searchFields.ingestionStatus}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <br />
                    <Grid container direction="row">
                      <Grid item xs={1} />
                      <Grid item xs={10} direction="row">
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: '0px 32px' }}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <KeyboardDatePicker
                                className={classes.datePicker}
                                format={constants.MM_DD_YYYY_FORMAT}
                                autoOk
                                size="small"
                                variant="inline"
                                style={{ minWidth: '32%', marginRight: '15px' }}
                                inputVariant="outlined"
                                disableFuture
                                value={searchFields.startDateTime || null}
                                label="Start Date"
                                onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                              />
                              <KeyboardDatePicker
                                className={classes.datePicker}
                                format={constants.MM_DD_YYYY_FORMAT}
                                autoOk
                                variant="inline"
                                size="small"
                                style={{ minWidth: '32%' }}
                                inputVariant="outlined"
                                disableFuture
                                value={searchFields.endDateTime || null}
                                label="End Date"
                                onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                                disabled={endDisabled}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <br />
                  </>
                )}
              </>
            )}
          </Box>
          <SearchButtonComponent classes={classes} handleSearch={this.handleSearch} handleReset={this.handleReset} />
        </Paper>
        <LoadingHandler
          loading={commonSearchUtilityStatus.isPending}
          isError={
            commonSearchUtilityStatus.isError &&
            commonSearchUtilityStatus.error &&
            commonSearchUtilityStatus.error.status === 401 &&
            commonSearchUtilityStatus.error.status === 500
          }
          content={
            searchedDataResponse && searchedDataResponse.length > 0 ? (
              <Box>
                <br />
                <DynamicDataGrid
                  selectedTab={selectedTabValue}
                  gridData={searchedDataResponse}
                  handleSearch={this.handleSearch}
                  handleAscending={this.handleAscending}
                  isNextPageKey={isNextPageKey}
                  clusterState={clusterState}
                  tenantName={tenantName}
                  tenantTitle={tenantTitle}
                />
              </Box>
            ) : (
              <>
                <br />
                <Paper>
                  <br />
                  <Box>
                    <br />
                    <Typography
                      variant="h4"
                      align="center"
                      gutterBottom
                      spacing={9}
                      className={classes.title}
                      style={{ p: 5, mt: 25 }}
                    >
                      {constants.CHAT_FETCH}
                    </Typography>
                  </Box>
                </Paper>
              </>
            )
          }
          loadingContent={<Loader loaderStatus={loaderStatus} />}
          errorContent={<StateErrorDisplay error={commonSearchUtilityStatus.error} showDetails />}
        />
      </>
    );
  }
}

export default withStyles(styles)(DynamicComponent);

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { shapes } from '@greenville/framework';
import { Box, CardContent, Grid, Typography } from '@mui/material';
// import { toJS } from 'mobx';
// import { observer } from 'mobx-react';
// import UsersPermissions from '../../users/models/UsersPermissions';
import { useTheme } from '@mui/material/styles';
import ChatgptConfig from '../config/ChatgptConfig';
// const commonCardData = [
//   {
//     id: 1,
//     cardTitle: 'Audit Logs',
//     cardDescription: 'End-to-end insights of user interaction with AI',
//     redirectUrl: '/',
//     toolTipTitle: 'chatUtility.AUDIT_LOG'
//   },
//   {
//     id: 2,
//     cardTitle: 'User Feedback',
//     cardDescription: 'Configure Berlin platform to suit your needs',
//     redirectUrl: '/feedback',
//     toolTipTitle: 'chatUtility.USER_FEEDBACK'
//   },
//   {
//     id: 3,
//     cardTitle: 'Trends & Insights',
//     cardDescription: 'Reports on Usage patterns and content safety',
//     redirectUrl: '/trends',
//     toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
//   }
// ];

// const commonAuditCardData = [
//   {
//     id: 1,
//     cardTitle: 'Observe',
//     cardDescription: 'End-to-end insights of user interaction with AI',
//     redirectUrl: '/observe',
//     toolTipTitle: 'chatUtility.AUDIT_LOG'
//   },
//   {
//     id: 2,
//     cardTitle: 'Trends & Insights',
//     cardDescription: 'Reports on Usage patterns and content safety',
//     redirectUrl: '/trends',
//     toolTipTitle: 'chatUtility.TRENDS_INSIGHTS'
//   },
//   {
//     id: 3,
//     cardTitle: 'Configurations',
//     cardDescription: 'Configure Berlin Platform to suit your needs',
//     redirectUrl: '',
//     toolTipTitle: 'chatUtility.CONFIGURATIONS'
//   }
// ];

const ChatUtilityDashboard = ({ tenantId }) => {
  const [tenantName, setTenantName] = useState();
  const [dashboardList, setDashboardList] = useState([]);
  const history = useHistory();
  const theme = useTheme(); // Hook to access the theme
  // const { userPermissions } = props;
  // let cardData = [];
  // let baseUrl = '';
  // const navigationPath = history.location.pathname;

  // const permissionsList = toJS(userPermissions?.permissionList?.permissions);

  // const renderChannelsTab = [...commonCardData];

  // if (permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit')) {
  //   renderChannelsTab.push({
  //     id: 4,
  //     cardTitle: 'Prompt Management',
  //     cardDescription: 'Full lifecycle, from versioning and updates to production rollouts.',
  //     redirectUrl: '/promptmgt',
  //     toolTipTitle: 'chatUtility.PROMPT_MANANGEMENT'
  //   });
  // }

  // const renderAuditTab = [...commonAuditCardData];

  // if (permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit')) {
  //   renderAuditTab.push({
  //     id: renderAuditTab?.length + 1,
  //     cardTitle: 'LLM Evaluation & Reports',
  //     cardDescription: 'Evaluate your LLM/RAG performance and metrics',
  //     redirectUrl: '/effectiveness',
  //     toolTipTitle: 'chatUtility.LLM_EVALUVATION'
  //   });
  // }

  // if (permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit')) {
  //   renderAuditTab.push({
  //     id: renderAuditTab?.length + 1,
  //     cardTitle: 'Prompt Management',
  //     cardDescription: 'Full lifecycle, from versioning and updates to production rollouts.',
  //     redirectUrl: '/promptmgt',
  //     toolTipTitle: 'chatUtility.PROMPT_MANANGEMENT'
  //   });
  // }

  // if (permissionsList.includes('mcq_can_view') || permissionsList.includes('mcq_can_edit')) {
  //   renderAuditTab.push({
  //     id: renderAuditTab?.length + 1,
  //     cardTitle: 'Content Management',
  //     cardDescription: 'Review and update pre-generated content (Summaries, Quizes)',
  //     redirectUrl: '/titlesearch',
  //     toolTipTitle: 'chatUtility.SUMMARY_QUIZ_MANANGEMENT'
  //   });
  // }

  // if (tenantName === 'aistudytools') {
  //   baseUrl = '/chatgptutility/aistudytools';
  // } else if(tenantName === 'aistudytools') {
  //   baseUrl = '/chatgptutility/channels';
  // }else {
  //   baseUrl = '/chatgptutility/channels';
  // }
  const baseUrl = `/aiadmin/${tenantName}/`;

  const handleNavigation = (path) => {
    history.push(path);
  };
  /* eslint-disable */
  useEffect(() => {
    const searchData = ChatgptConfig.tenantData.find((data) => data.tenantId === tenantId);
    if (searchData && searchData?.tabDetails) {
      setDashboardList(searchData?.tabDetails);
      setTenantName(searchData.name);
    } else {
      setDashboardList([]);
      setTenantName(searchData?.name || 'Default');
    }
  }, [tenantId]);

  return (
    <Box sx={{ mb: 3, padding: 4 }}>
      <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
        {dashboardList?.map((item, index) => (
          <Grid item onClick={() => handleNavigation(baseUrl + item.tabUrl)} xs={12} sm={4} md={4} lg={4} key={index}>
            <Box
              sx={{
                position: 'relative',
                // backgroundColor: '#081633',
                backgroundColor: theme.palette.background.darkBlue,
                width: '100%',
                maxWidth: '320px',
                height: '120px',
                borderRadius: '15px', // Adjust the radius as per your card design
                cursor: 'pointer',
                transition: 'transform 0.2s',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                textAlign: 'center',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.7)'
                },
                '&:hover::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: '15px', // Same as the card's borderRadius
                  padding: '2px', // Thickness of the outline
                  background: 'linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%)',
                  WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                  mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                  WebkitMaskComposite: 'xor',
                  maskComposite: 'exclude',
                  pointerEvents: 'none',
                  zIndex: 0
                }
              }}
            >
              <CardContent>
                <Typography color="textPrimary" sx={{ pb: 2 }} variant="h6">
                  {item.tabName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ChatUtilityDashboard.propTypes = {
  tenantId: PropTypes.string.isRequired
};

export default ChatUtilityDashboard;

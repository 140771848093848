import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class EffectivenessInitiateService extends BaseService {
  constructor() {
    super(constants.EFFECTIVENESS_INITIATE_DATA, 'InitiateEffectivenessDataStatus');
  }

  handleEvent(eventName, event) {
    return this.getClient('evergreen').post(`/chatutility/effectiveness/llm`, event.payload);
  }

  handleResponse(response) {
    const { data } = response;
    if (data.status === 'success' && data.data.success) {
      const notification = {
        open: true,
        type: 'success',
        message: 'Initiated successfully, please check after sometime for the results'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    } else if (error.message === 'timeout of 60000ms exceeded') {
      const notification = {
        open: true,
        message: error.message,
        type: 'error'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
    Framework.getStoreRegistry().getStore('effectivenessData').resetStoreValues();
  }
}

import Framework, { shapes } from '@greenville/framework';
import { Grid, Paper, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TablePagination from '@mui/material/TablePagination';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../../common/components/Table';
import ChatgptConfig from '../../../../../common/config/ChatgptConfig';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import utils from '../../../../../common/utils';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';
import CommonSearchModel from '../../../models/CommonSearchModel';
import ResponseModel from '../../../models/Response';
import DynamicDialog from './DynamicDialog';

const styles = () => ({
  tableColumnHeight: {
    maxHeight: '600px',
    overflow: 'hidden',
    marginBottom: '2%'
  },
  highlighted: {
    backgroundColor: '#8080802e'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600',
    textAlign: 'center'
  },
  tableLeftWidth: {
    paddingLeft: '12px'
  },
  tableLeftScroll: {
    maxHeight: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  },
  columnData: {
    color: '#6a7070',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  iconStyle: {
    display: 'flex',
    paddingLeft: '12px',
    cursor: 'pointer',
    alignItems: 'center'
  },
  fontStyle: {
    marginLeft: '10px'
  }
});

const DynamicListing = React.memo((props) => {
  const {
    classes,
    ChatgptUtilityIESUserData,
    commonSearchUtility,
    chatgptResponseUtility,
    listingData,
    tenantName,
    tenantTitle
  } = props;
  const { data } = commonSearchUtility;
  const feedback = false;
  const [highLight, setHighLght] = useState(-1);
  const [trendsList, setTrendsList] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [clusterTable, setClusterTable] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [rowDialogue, setRowDialogue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [openChat, openChatDialog] = useState(false);
  const [dataLength, setDatalength] = useState({});
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromValue, setFromValue] = useState(0);

  useEffect(() => {
    const combinedObjects = {};
    setshowTable(false);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });

    if (data.length > 0) {
      data.forEach((obj) => {
        const key = obj.clusterName && obj.clusterName.split('').sort().join('');
        if (combinedObjects[key]) {
          combinedObjects[key].count += 1;
        } else {
          combinedObjects[key] = { name: obj.clusterName, count: 1 };
        }
      });
      if (Object.values(combinedObjects).length > 0) {
        const pushToLast = Object.values(combinedObjects);
        const filteredItems = pushToLast.filter((item) => item.name !== 'Other topics');
        const itemToMove = pushToLast.find((item) => item.name === 'Other topics');
        const resultArray = filteredItems
          .sort((a, b) => b.count - a.count)
          .map(({ name, count }) => (count > 1 ? `${name} (${count})` : name));
        const updatedItems = !_.isEmpty(itemToMove)
          ? [...resultArray, `${itemToMove.name} (${itemToMove.count})`]
          : resultArray;
        setTrendsList(updatedItems);
      }
    }
    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const storeData = utils.processStoreValue(chatgptResponseUtility);
      if (storeData) {
        setRowDialogue(storeData);
      }
    });
  }, [commonSearchUtility]);

  function getObjectName(array, targetName) {
    const matchObjects = array.filter((obj) => obj.clusterName === targetName);
    return matchObjects.map(({ serverDateTime, userCommand, dateStamp, bookId, userId, title, type, requestId }) => ({
      serverDateTime,
      userCommand,
      dateStamp,
      bookId,
      userId,
      type,
      title,
      requestId
    }));
  }

  const handleList = (item, listIndex) => {
    setHighLght(listIndex);
    ChatgptConfig.chatData.forEach((value) => {
      const alteredValue = value.bookId;
      if (!alteredValue) return;
      listingData.forEach((dataValue, index) => {
        if (dataValue.bookId === alteredValue) {
          listingData[index].title = value.title;
        }
      });
    });
    const trimmedName = item.replace(/\(\d+\)/g, '').trim();
    setClusterName(trimmedName);
    setshowTable(true);
    const matchingNamesArray = getObjectName(listingData, trimmedName);
    const sortedArray = matchingNamesArray.sort((x, y) => new Date(y.dateStamp) - new Date(x.dateStamp));
    setPage(0);
    setClusterTable(sortedArray);
  };

  const handleEvent = (params, index) => {
    const { requestId, bookId, type } = params;
    setDatalength(clusterTable.length);
    openChatDialog(true);
    setModalIndex(fromValue + index);
    chatgptResponseUtility.fetch({ type, requestId, bookId });
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, bookId, type } = clusterTable[modalIndex - 1];
      setModalIndex(modalIndex - 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type });
    } else {
      const { requestId, bookId, type } = clusterTable[modalIndex + 1];
      setModalIndex(modalIndex + 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type });
    }
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...clusterTable].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setClusterTable(sorted);
    } else {
      const sorted = [...clusterTable].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setClusterTable(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const onColumnClick = (val) => {
    sorting(val);
  };
  const onRowClick = (val, index) => {
    handleEvent(val, index);
  };
  const onMouseEnter = (val) => {
    hanldeGETUserId(val);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    setFromValue(from - 1);
    return `${from}-${to} of ${count}`;
  };

  return (
    <>
      {trendsList.length > 0 && (
        <Grid container direction="row">
          {trendsList.length > 0 && (
            <Grid item xs={7}>
              <br />
              <Typography align="left" variant="h3">
                {constants.CLUSTER_LIST}
              </Typography>
              <br />
            </Grid>
          )}
          <Grid item xs={5}>
            <br />
            <Typography align="left" variant="h3">
              {showTable && clusterName}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={4} className={classes.tableColumnHeight}>
            {trendsList.length > 0 && (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  height: '100%',
                  border: '1px solid #0003',
                  overflowY: 'scroll',
                  borderRadius: '2px'
                }}
              >
                {trendsList.length > 0 &&
                  trendsList.map((item, index) => (
                    <div key={index} className={index === highLight ? classes.highlighted : ''}>
                      <div
                        className={classes.iconStyle}
                        onClick={() => handleList(item, index)}
                        onKeyDown={() => handleList(item)}
                        role="button"
                        tabIndex={0}
                      >
                        <LightbulbIcon sx={{ color: '#0003' }} />
                        <p className={classes.fontStyle}>{item}</p>
                      </div>
                    </div>
                  ))}
                <Divider />
              </Box>
            )}
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ width: '100%' }}>
              {showTable && (
                <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
                  <TableComponent
                    columns={ColumnConfig.CommonGridListingColumn({
                      bookId: tenantName !== 'Reader+' ? 'Course Id' : 'Book Id'
                    })}
                    data={clusterTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    onColumnClick={onColumnClick}
                    order={order}
                    onRowClick={onRowClick}
                    directionValue={directionValue}
                    userEmailDetails={userEmailDetails}
                    onMouseEnter={onMouseEnter}
                    isFromCommonDataGrid
                  />
                  <TablePagination
                    component="div"
                    count={clusterTable.length}
                    labelDisplayedRows={({ from, to, count }) =>
                      useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                    }
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ display: 'ruby-text' }}
                  />
                </Paper>
              )}
            </Box>
          </Grid>
          <br />
          <br />
          {openChat && (
            <DynamicDialog
              openChat={openChat}
              dataValue={rowDialogue}
              filterPromt={constants.FILTER_PROMPT}
              dataLength={dataLength}
              modalIndex={modalIndex}
              handleClose={handleClose}
              handleModalChange={handleModalChange}
              isTrendsInsightsList
              isFeedback={feedback}
              tenantTitle={tenantTitle}
            />
          )}
        </Grid>
      )}
    </>
  );
});

DynamicListing.propTypes = {
  listingData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  commonSearchUtility: shapes.modelOf(CommonSearchModel).isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  tenantName: PropTypes.string,
  tenantTitle: PropTypes.string
};

DynamicListing.defaultProps = {
  tenantName: '',
  tenantTitle: ''
};

export default withStyles(styles)(
  observer(inject('ChatgptUtilityIESUserData', 'commonSearchUtility', 'chatgptResponseUtility')(DynamicListing))
);

export const BuildingBlocksData = [
  {
    id: '1',
    task: 'Welcome Message'
  },
  {
    id: '2',
    task: 'Ask Question'
  },
  {
    id: '3',
    task: 'IF/THEN'
  },
  {
    id: '4',
    task: 'Custom Message'
  }
];

export const IntegrationLibraryData = [
  {
    id: '5',
    task: 'API Endpoint'
  },
  {
    id: '6',
    task: 'LLM Endpoint'
  },
  {
    id: '7',
    task: 'Webhook'
  },
  {
    id: '8',
    task: 'RAG Endpoint'
  },
  {
    id: '9',
    task: 'Email'
  },
  {
    id: '10',
    task: 'ServiceNow Plugin'
  },
  {
    id: '11',
    task: 'JIRA Plugin'
  },
  {
    id: '12',
    task: 'Salesforce Plugin'
  }
];

export const IntentLibraryData = [
  {
    id: '13',
    task: 'Explain'
  },
  {
    id: '14',
    task: 'Practice Quiz'
  },
  {
    id: '15',
    task: 'Problem Solve'
  },
  {
    id: '16',
    task: 'Saluation'
  },
  {
    id: '17',
    task: 'Summary'
  },
  {
    id: '18',
    task: 'Fetch Data'
  },
  {
    id: '19',
    task: 'Create Data'
  },
  {
    id: '20',
    task: 'Update Data'
  }
];

export const WorkFlowDetails = {
  Building_Blocks: {
    title: 'Building Blocks',
    items: BuildingBlocksData
  },
  Integration_Library: {
    title: 'Integration Library',
    items: IntegrationLibraryData
  },
  Intent_Library: {
    title: 'Intent Library',
    items: IntentLibraryData
  }
};

import { Grid, TextField, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  flexBorder: {
    border: '1px solid grey',
    margin: '75px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '80%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '60px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    marginLeft: '60px !important',
    marginTop: '5px !important',
    marginBottom: '5px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  }
});

const DynamicTestFields = () => {
  const history = useHistory();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState({
    tenantId: 'bfff63e6-3f6b-4aa9-acd8-fab7a71ee8ed',
    tenantKey: 'ff30d520-6137-46a2-b026-c5597793e3d8',
    baseUrl: 'https://berlin-api-pplus.etext-dev.pearsondev.tech/api/2.0',
    historyUrl: 'https://berlin-api-pplus.etext-dev.pearsondev.tech/api/1.0',
    headingName: 'WELCOME to NEW CHAT',
    expandMode: false
  });
  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleSave = () => {
    history.push('/configure/aistudytest', inputValue); // Pass the data via state
  };
  return (
    <>
      <div className={classes.flexBorder}>
        <Box>
          <Grid item xs={16}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              Configure AI Study Tools
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ width: '100%', top: '13%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label="Tenant ID"
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name="tenantId"
                    onChange={handleInputChange}
                    value={inputValue.tenantId}
                    variant="outlined"
                    required
                  />
                  <TextField
                    label="Tenant Key"
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name="tenantKey"
                    onChange={handleInputChange}
                    value={inputValue.tenantKey}
                    variant="outlined"
                    required
                  />
                  <TextField
                    label="Base URL"
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name="baseUrl"
                    onChange={handleInputChange}
                    value={inputValue.baseUrl}
                    variant="outlined"
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label="Heading"
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name="headingName"
                    onChange={handleInputChange}
                    value={inputValue.headingName}
                    variant="outlined"
                    required
                  />
                  <TextField
                    label="History URL"
                    style={{ minWidth: '32%' }}
                    margin="dense"
                    name="historyUrl"
                    onChange={handleInputChange}
                    value={inputValue.historyUrl}
                    variant="outlined"
                    required
                  />
                  {/* eslint-disable */}
                  <FormControl sx={{ mt: 1, width: '32%' }} size="small" required>
                    <InputLabel id="expandMode">Expand Mode</InputLabel>
                    <Select
                      label="Expand Mode"
                      name="expandMode"
                      onChange={handleInputChange}
                      value={inputValue.expandMode}
                    >
                      <MenuItem value={true}>TRUE</MenuItem>
                      <MenuItem value={false}>FALSE</MenuItem>
                    </Select>
                  </FormControl>
                  {/* eslint-disable */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <br />
        <div className={classes.flexContainerBottom}>
          <div className={classes.ButtonClass}>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="small" onClick={() => handleSave()} sx={{ background: '#005d83' }}>
                LAUNCH
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicTestFields;

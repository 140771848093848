/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography, Paper, Box } from '@material-ui/core';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import IdentifierSearch from '../common/IdentifierSearch';
import HistoryDialog from '../workflow/HistoryDialog';
import UploadContent from '../upload/UploadContent';
import eTextConfig from '../../../../common/config/eTextConfig';
import CreateAndValidate from '../../models/CreateAndValidate';
import CmMetadata from '../../models/CmMetadata';
import * as constants from '../../../../common/constants';
import Loader from '../../../../common/Loader';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/Confirmation';
import Upload from '../../models/Upload';
import UsersPermissions from '../../../users/models/UsersPermissions';
import Workflow from '../../models/Workflow';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '98%',
    padding: theme.spacing(0, 1)
  },
  paper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    borderRight: '1px solid #80808061'
  },
  paperEnd: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1)
  },
  bottomPadding: {
    margin: '0px 0px 8px 0px'
  },
  bottopPadding: {
    margin: '4px 0px 8px 0px'
  },
  metaradioRoot: {
    '& .MuiFormControlLabel-label': {
      //  fontSize: 13,
      fontSize: 13,
      textAlign: '-webkit-left',
      marginTop: '10px',
      whiteSpace: 'break-spaces'
    },
    '& .MuiFormControlLabel-root': {
      paddingTop: 0
    }
  },
  formControl: {
    '& .MuiFormControlLabel-root': {
      paddingTop: 0
    }
  },
  btnSpace: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '2%'
  },
  fontsize: {
    fontSize: 10
  },
  clickableLink: {
    cursor: 'pointer',
    color: '#0000EE',
    textAlign: 'center',
    fontSize: 12,
    textDecoration: 'underline'
  },
  btnClass: {
    position: 'absolute',
    bottom: '15%',
    right: '2%'
  },
  gridClass: {
    position: 'relative'
  }
});

// eslint-disable-next-line react/prefer-stateless-function
@inject(
  'createAndValidate',
  'getCreateStatus',
  'getValidateStatus',
  'workflowHistoryStatus',
  'workflow',
  'cmmetaData',
  'upload',
  'cmSubbusinessStatus',
  'cmDirectoryStatus',
  'getCreateBuketFoldersStatus',
  'userPermissions',
  'notificationState'
)
@observer
class StandardEbook extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    createAndValidate: shapes.modelOf(CreateAndValidate).isRequired,
    upload: shapes.modelOf(Upload).isRequired,
    workflowHistoryStatus: shapes.state.isRequired,
    match: PropTypes.object.isRequired,
    cmmetaData: shapes.modelOf(CmMetadata).isRequired,
    workflow: shapes.modelOf(Workflow).isRequired,
    cmSubbusinessStatus: shapes.state.isRequired,
    cmDirectoryStatus: shapes.state.isRequired,
    getCreateStatus: shapes.state.isRequired,
    getValidateStatus: shapes.state.isRequired,
    getCreateBuketFoldersStatus: shapes.state.isRequired,
    radioTitle: PropTypes.object,
    isCMScreen: PropTypes.bool,
    notificationState: shapes.state.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  static defaultProps = {
    radioTitle: {},
    isCMScreen: true
  };

  constructor(props) {
    super(props);
    this.state = {
      openUploadContent: false,
      checkboxPayload: {
        processPdfLowRes: false,
        processPdfScaling: false,
        processValidateXML: false
      },
      // open: false,
      rowData: {},
      tabUrl: '',
      uploadContentPath: '',
      openConfirmationDialog: false,
      eBookData: {
        contentPath: '',
        identifier: '',
        businessUnit: eTextConfig.businessUnit.find((data) => data.id === 1).value,
        subBusinessUnit: '',
        directory: '',
        titleType: '',
        workflow: constants.SIMPLE_EBOOK_WORKFLOW
      },
      businessUnitId: 1,
      titleTypeId: 1,
      subBusinessUnitId: null,
      directoryId: null
    };
    this.handleOpenUploadContent = this.handleOpenUploadContent.bind(this);
    this.closeUploadContent = this.closeUploadContent.bind(this);
    this.handleRadioButton = this.handleRadioButton.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    const { match, cmmetaData } = this.props;
    this.setState({
      tabUrl: match.path.split('/').pop()
    });

    if (cmmetaData.subBusiness.length === 0) {
      cmmetaData.fetchSubBusiness();
    }
    if (cmmetaData.cmdirectory.length === 0) {
      cmmetaData.fetchDirectory();
    }

    if (cmmetaData.subBusiness.length > 0 && cmmetaData.cmdirectory.length > 0) {
      this.radioButtonData();
    }
    Framework.getEventManager().on(constants.CM_INGEST_UNITS, () => {
      if (cmmetaData.subBusiness.length > 0 && cmmetaData.cmdirectory.length > 0) {
        this.radioButtonData();
      }
    });
  }

  radioButtonData = () => {
    const { radioTitle, cmmetaData } = this.props;
    const { eBookData } = this.state;
    const radioData = {};
    if (radioTitle.identifier) {
      if (cmmetaData.subBusiness.length > 0 && cmmetaData.cmdirectory.length > 0) {
        radioData.identifier = radioTitle.identifier;
        radioData.businessUnit = radioTitle.businessUnitId;
        const businessUnitDetail = cmmetaData.subBusiness.filter(
          (data) => data.businessUnitId === radioTitle.businessUnitId
        );
        radioData.subBusinessUnit = businessUnitDetail.find(
          (data) => data.subBusinessUnitName.toLowerCase() === radioTitle.subBusinessUnit.toLowerCase()
        ).subBusinessUnitId;
        const directoryDetail = cmmetaData.cmdirectory.filter(
          (data) => data.subBusinessUnitId === radioData.subBusinessUnit
        );
        radioData.directory = directoryDetail.find(
          (data) => data.directoryName.toLowerCase() === radioTitle.directory.toLowerCase()
        ).directoryId;
        radioTitle.titleType =
          radioTitle.workflowName === constants.EPUB_TITLE ? radioTitle.workflowName : constants.PDF_TITLE;
        radioData.titleType = eTextConfig.titleType.find(
          (data) => data.value.toLowerCase() === radioTitle.titleType.toLowerCase()
        ).id;
        radioData.workflow = radioTitle.workflowName;
        this.setState({
          eBookData: {
            ...eBookData,
            identifier: radioTitle.identifier,
            businessUnit: radioTitle.businessUnit.toLowerCase(),
            subBusinessUnit: radioTitle.subBusinessUnit.toLowerCase(),
            directory: radioTitle.directory.toLowerCase(),
            workflow: radioTitle.workflowName,
            titleType: radioTitle.titleType,
            contentPath: ''
          },
          directoryId: radioData.directory,
          subBusinessUnitId: radioData.subBusinessUnit,
          businessUnitId: radioData.businessUnit,
          titleTypeId: radioData.titleType
        });
      }
    }
  };

  createContentPath = (eBookData) => {
    let workflowFolder;
    if (
      eBookData.subBusinessUnit === constants.SUB_BUSINESS_UNIT_AUSTRALIA ||
      eBookData.subBusinessUnit === constants.SUB_BUSINESS_UNIT_ELL
    ) {
      workflowFolder = eBookData.titleType === 'epub' ? constants.STANDARD_EBOOK_FOLDER : constants.SIMPLE_EBOOK_FOLDER;
    } else {
      workflowFolder =
        eBookData.workflow === constants.SIMPLE_EBOOK_WORKFLOW && eBookData.titleType !== 'epub'
          ? constants.SIMPLE_EBOOK_FOLDER
          : constants.STANDARD_EBOOK_FOLDER;
    }
    let contentPath = `${workflowFolder}${constants.SEPARATOR}${eBookData.businessUnit}${constants.SEPARATOR}`;
    contentPath = `${contentPath}${eBookData.subBusinessUnit}${constants.SEPARATOR}${eBookData.directory}`;
    return contentPath.toLowerCase();
  };

  handleSearch = () => {
    const { eBookData } = this.state;
    // API call to get the data
    // Once Data is received. Do the following
    // const data = {
    //   requestNo: requestNumber,
    //   identifier: 'cm_s3_integration',
    //   businessUnit: 'curriculum',
    //   subBusinessUnit: 'hcl_test',
    //   directory: 'test1',
    //   workflow: constants.SIMPLE_EBOOK_WORKFLOW
    // };
    const data = eBookData;
    let uploadContentPathUrl = this.createContentPath(data);
    uploadContentPathUrl += `${constants.SEPARATOR}${data.identifier}${constants.SEPARATOR}`;
    this.setState({
      eBookData: {
        ...eBookData,
        contentPath: this.createContentPath(data)
        // identifier: data.identifier,
        // businessUnit: data.businessUnit,
        // subBusinessUnit: data.subBusinessUnit,
        // directory: data.directory,
        // workflow: data.workflow
      },
      uploadContentPath: uploadContentPathUrl
    });
  };

  handleCreate = (event) => {
    const { createAndValidate, notificationState } = this.props;
    const { checkboxPayload, eBookData } = this.state;
    const payload = this.titleDetails();
    if (event.currentTarget && event.currentTarget.innerText && event.currentTarget.innerText === 'Validate & Ingest') {
      payload.processValidateXML = true;
    }

    if (eBookData.titleType === 'epub') {
      payload.processPdfCropping = false;
      payload.processPdfOptimized = false;
      payload.processPdfTextExtraction = false;
      payload.processPdfUniversal = false;
      createAndValidate.fetchCreate(payload);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (eBookData.workflow === 'spdf') {
        const notification = {
          open: true,
          message: `sPDF Functionality coming soon`,
          type: 'error'
        };
        notificationState.set(notification);
      } else {
        createAndValidate.fetchCreate(payload);
      }
    }
    this.setState({ checkboxPayload: { ...checkboxPayload, ...payload } });
  };

  handleValidate = () => {
    const { createAndValidate, notificationState } = this.props;
    const { eBookData, checkboxPayload } = this.state;
    const payload = this.titleDetails();
    if (eBookData.workflow === 'spdf') {
      const notification = {
        open: true,
        message: `sPDF Functionality coming soon`,
        type: 'error'
      };
      notificationState.set(notification);
    } else {
      createAndValidate.fetchValidate(payload);
    }
    this.setState({ checkboxPayload: { ...checkboxPayload, ...payload } });
  };

  titleDetails = () => {
    const { checkboxPayload, eBookData } = this.state;
    const { createAndValidate } = this.props;
    const defaultDetails = { ...createAndValidate, ...checkboxPayload };
    const finalPayload = {
      ...defaultDetails,
      ...eBookData
    };
    return finalPayload;
  };

  handleCheckbox = (event, isInputChecked) => {
    const { checkboxPayload } = this.state;
    const updatedCheckBoxPayload = {
      [event.target.name]: isInputChecked
    };
    this.setState({ checkboxPayload: { ...checkboxPayload, ...updatedCheckBoxPayload } });
  };

  handleRadioButton = (selectedRadioButton) => {
    const { eBookData } = this.state;
    this.setState({ eBookData: { ...eBookData, workflow: selectedRadioButton } });
  };

  handleOpenUploadContent = () => {
    this.setState({ openUploadContent: true });
    Framework.getEventManager().on(constants.CM_BUCKET_CLOSE, () => {
      this.setState({ openUploadContent: false });
    });
    Framework.getEventManager().on(constants.CM_CREATE_NEW_BUCKET, () => {
      this.setState({ openUploadContent: false, openConfirmationDialog: true });
    });
  };

  closeUploadContent = () => {
    this.setState({ openUploadContent: false });
  };

  handleTitleHistory = () => {
    const { openhistory } = this.state;
    this.setState({
      openhistory: !openhistory
    });
  };

  handleHistoryClose = () => {
    const { openhistory } = this.state;
    this.setState({
      openhistory: !openhistory
    });
  };

  // setEBookData = () => {
  //   const { eBookData } = this.state;
  //   // const { cmmetaData } = this.props;
  //   const nonFormattedData = eBookData;
  //   // if (!nonFormattedData.businessUnit && !nonFormattedData.subBusinessUnit && !nonFormattedData.directory) {
  //   //   console.log('Didnttt  Rannnnn');
  //   //   return;
  //   // }
  //   console.log('Rannnnn');
  //   console.log(nonFormattedData.businessUnit);
  //   console.log(eTextConfig.businessUnit.filter((data) => data.id === nonFormattedData.businessUnit));
  //   this.setState({
  //     eBookData: {
  //       ...eBookData,
  //       businessUnit: eTextConfig.businessUnit.find((data) => data.id === nonFormattedData.businessUnit).value
  //       // subBusinessUnit: cmmetaData.subBusiness.find((data) => data.id === nonFormattedData.subBusinessUnit)
  //       //   .subBusinessUnitName,
  //       // directory: eTextConfig.cmdirectory.find((data) => data.id === nonFormattedData.directory).directoryName
  //     }
  //   });
  // };

  handleConfirm = (confirmResponse) => {
    const { upload } = this.props;
    const { uploadContentPath } = this.state;
    this.setState({ openConfirmationDialog: false });
    if (confirmResponse) {
      upload.createBucketFolders({ directory: uploadContentPath, isBulkUtility: false });
    }
  };

  handleSelectionChange = (event, value) => {
    const { eBookData } = this.state;
    const { cmmetaData } = this.props;
    if (value === 'businessUnit') {
      this.setState({
        eBookData: {
          ...eBookData,
          businessUnit: eTextConfig.businessUnit.find((data) => data.id === event.target.value).value.toLowerCase(),
          subBusinessUnit: '',
          directory: '',
          contentPath: ''
        },
        businessUnitId: event.target.value,
        subBusinessUnitId: null,
        directoryId: null
      });
    } else if (value === 'cmSubbusiness') {
      this.setState({
        eBookData: {
          ...eBookData,
          subBusinessUnit: cmmetaData.subBusiness
            .find((data) => data.subBusinessUnitId === event.target.value)
            .subBusinessUnitName.toLowerCase(),
          directory: '',
          contentPath: ''
        },
        subBusinessUnitId: event.target.value,
        directoryId: null
      });
    } else if (value === 'cmDirectory') {
      this.setState({
        eBookData: {
          ...eBookData,
          directory: cmmetaData.cmdirectory.find((data) => data.directoryId === event.target.value).directoryName,
          contentPath: ''
        },
        directoryId: event.target.value
      });
    } else if (value === 'titleType') {
      this.setState({
        eBookData: {
          ...eBookData,
          titleType: eTextConfig.titleType.find((data) => data.id === event.target.value).value.toLowerCase(),
          workflow: event.target.value === 2 ? constants.EPUB_TITLE : constants.SIMPLE_EBOOK_WORKFLOW
        },
        titleTypeId: event.target.value
      });
    } else {
      this.setState({
        eBookData: {
          ...eBookData,
          identifier: event.target.value,
          contentPath: ''
        }
      });
    }
  };

  render() {
    const {
      classes,
      language,
      workflowHistoryStatus,
      cmmetaData,
      workflow,
      getCreateStatus,
      getValidateStatus,
      cmSubbusinessStatus,
      cmDirectoryStatus,
      getCreateBuketFoldersStatus,
      isCMScreen,
      userPermissions
    } = this.props;
    const {
      openUploadContent,
      businessUnitId,
      subBusinessUnitId,
      directoryId,
      titleTypeId,
      openhistory,
      rowData,
      tabUrl,
      checkboxPayload,
      eBookData,
      openConfirmationDialog,
      uploadContentPath
    } = this.state;

    if (
      eBookData.identifier &&
      eBookData.businessUnit &&
      eBookData.subBusinessUnit &&
      eBookData.directory &&
      (!eBookData.contentPath || !uploadContentPath)
    ) {
      this.handleSearch();
    }

    const permissionsList = toJS(userPermissions.permissionList.permissions);

    return (
      <>
        {openConfirmationDialog && (
          <ConfirmationDialog
            message={{ ...language.getText('upload.CREATE_FOLDER_CONFIRMATION') }.defaultMessage}
            open
            callback={this.handleConfirm}
          />
        )}
        <LoadingHandler
          loading={
            (cmSubbusinessStatus.isPending && cmDirectoryStatus.isPending) ||
            getCreateStatus.isPending ||
            getValidateStatus.isPending ||
            getCreateBuketFoldersStatus.isPending
          }
          isError={
            (cmSubbusinessStatus.isError &&
              cmSubbusinessStatus.error &&
              cmSubbusinessStatus.error.status === 401 &&
              cmDirectoryStatus.isError &&
              cmDirectoryStatus.error &&
              cmDirectoryStatus.error.status === 401) ||
            (getCreateStatus.isError && getCreateStatus.error && getCreateStatus.error.status === 401) ||
            (getValidateStatus.isError && getValidateStatus.error && getValidateStatus.error.status === 401) ||
            (getCreateBuketFoldersStatus.isError &&
              getCreateBuketFoldersStatus.error &&
              getCreateBuketFoldersStatus.error.status === 401)
          }
          loadingContent={<Loader />}
          errorContent={
            <StateErrorDisplay
              error={
                (cmSubbusinessStatus.error && cmDirectoryStatus.error) ||
                getCreateStatus.error ||
                getValidateStatus.error ||
                getCreateBuketFoldersStatus.error
              }
              showDetails
            />
          }
          content={
            <>
              <Grid container>
                {isCMScreen && (
                  <Grid item xs={12}>
                    <br />
                    <br />
                    <Typography align="center" variant="h2">
                      {/* {workflow.fetchDbFlag.PEARSON_P2 ? (
                        <FormattedMessage {...language.getText('contentmanager.TITLE_INGESTION_EPUB')} />
                      ) : (
                        <FormattedMessage {...language.getText('contentmanager.TITLE_INGESTION')} />
                      )} */}
                      <FormattedMessage {...language.getText('contentmanager.TITLE_INGESTION')} />
                    </Typography>
                    <br />
                  </Grid>
                )}

                <Grid container justify="space-around">
                  <Grid item xs={12} className={classes.gridClass}>
                    <Paper elevation={4}>
                      <Box m={2} pt={1} pb={1}>
                        <IdentifierSearch
                          handleOpenUploadContent={() => this.handleOpenUploadContent}
                          handleRadioButton={this.handleRadioButton}
                          handleSearch={this.handleSearch}
                          handleCreate={this.handleCreate}
                          ingestionDeatils={eBookData}
                          data={tabUrl}
                          eTextConfig={eTextConfig}
                          cmSubbusiness={cmmetaData.subBusiness}
                          cmDirectory={cmmetaData.cmdirectory}
                          handleSelectionChange={($event, cmsub) => this.handleSelectionChange($event, cmsub)}
                          cmBusinessValue={businessUnitId}
                          cmSubBusinessUnit={subBusinessUnitId}
                          cmSubBusinessUnitName={eBookData.subBusinessUnit}
                          directoryId={directoryId}
                          cmTitleTypeId={titleTypeId}
                          cmIdentifier={eBookData.identifier}
                          cmSubbusinessStatus={cmSubbusinessStatus}
                          DBFlag={workflow.fetchDbFlag.PEARSON_P2}
                        />
                        {/* <Typography variant="body1" align="right" className={classes.btnClass}>
                          <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={this.handleTitleHistory}
                            aria-hidden="true"
                          >
                            <FormattedMessage {...language.getText('contentmanager.TITLEHISTORY')} />
                          </Button>
                        </Typography> */}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              {isCMScreen && !(eBookData.subBusinessUnit === 'australia') && !(eBookData.subBusinessUnit === 'ell') && (
                <Paper elevation={4}>
                  <Box m={2} pt={1} pb={1}>
                    <Grid item xs={12}>
                      {titleTypeId === 1 ? (
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={4} className={classes.paper}>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="processValidateXML"
                                      color="primary"
                                      checked={checkboxPayload.processValidateXML}
                                      onChange={this.handleCheckbox}
                                    />
                                  }
                                  label={<FormattedMessage {...language.getText('contentmanager.PROCESS_XML')} />}
                                  className={classes.metaradioRoot}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} className={classes.paper}>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="processPdfCropping"
                                      color="primary"
                                      disabled="true"
                                      defaultChecked="true"
                                      checked={checkboxPayload.processPdfCropping}
                                      onChange={this.handleCheckbox}
                                    />
                                  }
                                  label={<FormattedMessage {...language.getText('contentmanager.PDF')} />}
                                  className={classes.metaradioRoot}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="processPdfLowRes"
                                      color="primary"
                                      checked={checkboxPayload.processPdfLowRes}
                                      onChange={this.handleCheckbox}
                                    />
                                  }
                                  label={<FormattedMessage {...language.getText('contentmanager.LOW')} />}
                                  className={classes.metaradioRoot}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} className={classes.paper} style={{ height: '187' }}>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="processPdfScaling"
                                      color="primary"
                                      checked={checkboxPayload.processPdfScaling}
                                      onChange={this.handleCheckbox}
                                    />
                                  }
                                  label={<FormattedMessage {...language.getText('contentmanager.PERFORM')} />}
                                  className={classes.metaradioRoot}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="processPdfOptimized"
                                      color="primary"
                                      disabled="true"
                                      defaultChecked="true"
                                      checked={checkboxPayload.processPdfOptimized}
                                      onChange={this.handleCheckbox}
                                    />
                                  }
                                  label={<FormattedMessage {...language.getText('contentmanager.PROCESS_FROM')} />}
                                  className={classes.metaradioRoot}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid className={classes.btnSpace}>
                        <TooltipCustom
                          title={
                            !permissionsList.includes('ingestion_can_edit') ? (
                              <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                            ) : (
                              <FormattedMessage {...language.getText('contentmanager.CREATE_TITLE')} />
                            )
                          }
                        >
                          <span>
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              className={classes.margin}
                              onClick={this.handleCreate}
                              disabled={
                                eBookData.identifier === '' ||
                                eBookData.businessUnit === '' ||
                                eBookData.subBusinessUnit === '' ||
                                eBookData.directory === '' ||
                                !permissionsList.includes('ingestion_can_edit')
                              }
                            >
                              {!(eBookData.subBusinessUnit === 'australia') &&
                              !(eBookData.subBusinessUnit === 'ell') ? (
                                <FormattedMessage {...language.getText('contentmanager.CREATE_BTN')} />
                              ) : (
                                <FormattedMessage {...language.getText('contentmanager.CREATE_VALIDATE_BTN')} />
                              )}
                            </Button>
                          </span>
                        </TooltipCustom>
                        {titleTypeId === 1 && (
                          <TooltipCustom
                            title={<FormattedMessage {...language.getText('contentmanager.VALIDATE_TITLE')} />}
                          >
                            <span>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                className={classes.margin}
                                onClick={this.handleValidate}
                                disabled={
                                  eBookData.identifier === '' ||
                                  eBookData.businessUnit === '' ||
                                  eBookData.subBusinessUnit === '' ||
                                  eBookData.directory === ''
                                }
                              >
                                <FormattedMessage {...language.getText('contentmanager.VALIDATE_BTN')} />
                              </Button>
                            </span>
                          </TooltipCustom>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              )}

              {openUploadContent && (
                <UploadContent
                  openUploadContent={openUploadContent}
                  closeUploadContent={() => this.closeUploadContent}
                  uploadContentPath={uploadContentPath}
                />
              )}

              {isCMScreen && (
                <HistoryDialog
                  openhistory={openhistory}
                  close={() => this.handleHistoryClose}
                  rowData={rowData}
                  workflowHistory=""
                  workflowHistoryStatus={workflowHistoryStatus}
                />
              )}
            </>
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(StandardEbook)));

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import FolderIcon from '@mui/icons-material/Folder';
import SourceIcon from '@mui/icons-material/Source';
import { useHistory } from 'react-router-dom';
import ChatgptConfig from './config/ChatgptConfig';

const useStyles = makeStyles({
  listItem: {
    '&:hover': {
      backgroundColor: '#1A202C',
      color: '#fff !important'
    },
    '&:hover span': {
      color: '#fff !important'
    },
    '&:hover svg': {
      fill: '#fff !important'
    },
    '& svg': {
      fill: '#1A202C !important'
    }
  }
});

export default function ChatUtilityDrawerMenu({ tenantId }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [isOpened, setIsOpened] = React.useState(false);
  const [openIndex, setOpenIndex] = React.useState(-1);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpened(open);
    if (!open) {
      setOpenIndex(-1);
    }
  };

  const handleRedirection = (path) => {
    setIsOpened(false);
    setOpenIndex(-1);
    let baseUrl = '';
    const navigationPath = history.location.pathname;
    if (navigationPath.includes('/aiadmin/channels')) {
      baseUrl = '/aiadmin/channels/';
    } else {
      baseUrl = '/aiadmin/aistudytools/';
    }
    const redirectUrl = baseUrl + path;
    history.push(redirectUrl);
  };

  const handleClick = (index, path) => () => {
    if (index === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
    if (path) {
      handleRedirection(path);
    }
  };
  /* eslint-disable */
  // Fetch the menu list based on the tenantId
  const tenantTabs = ChatgptConfig.tenantData.find((tenant) => tenant.tenantId === tenantId)?.tabDetails || [];

  const list = () => (
    <Box sx={{ width: 250, marginTop: '55px' }} role="presentation">
      <List>
        {tenantTabs.map((item, index) => (
          <React.Fragment key={item.tabName}>
            {index < 1 && (
              <ListItem disablePadding onClick={() => history.push('/aiadmin')} className={`${classes.listItem}`}>
                <ListItemButton>
                  <Box sx={{ mr: 1 }}>
                    <HomeIcon />
                  </Box>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem disablePadding onClick={handleClick(index, item.tabUrl)} className={`${classes.listItem}`}>
              <ListItemButton>
                <Box sx={{ mr: 1 }}>
                  {item.toolTipTitle === 'chatUtility.AUDIT_LOG' && <SourceIcon />}
                  {item.toolTipTitle === 'chatUtility.TRENDS_INSIGHTS' && <TrendingUpIcon />}
                  {item.toolTipTitle === 'chatUtility.CONFIGURATIONS' && <PermDataSettingIcon />}
                  {item.toolTipTitle === 'chatUtility.SUMMARY_QUIZ_MANANGEMENT' && <FolderIcon />}
                  {item.toolTipTitle === 'chatUtility.LLM_EVALUVATION' && <TrendingUpIcon />}
                  {item.toolTipTitle === 'chatUtility.PROMPT_MANANGEMENT' && <SourceIcon />}
                </Box>
                <ListItemText primary={item.tabName} />
              </ListItemButton>
            </ListItem>
            {isOpened &&
              openIndex === index &&
              item.submenu &&
              item.submenu.length > 0 &&
              item.submenu.map((submenu) => (
                <ListItem
                  key={submenu.name}
                  sx={{ marginLeft: '20px' }}
                  onClick={() => handleRedirection(submenu.path)}
                >
                  <ListItemIcon sx={{ minWidth: '30px' }}>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary={submenu.name} primaryTypographyProps={{ fontSize: 12 }} />
                </ListItem>
              ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ marginLeft: '4px', position: 'absolute', top: '80px', left: 0 }} title="Drawer Menu">
        <IconButton
          onClick={toggleDrawer(true)}
          sx={{
            borderRadius: '50%',
            backgroundColor: theme.palette.background.darkBlue,
            color: theme.palette.text.primary,
            padding: 1,
            transition: 'transform 0.2s',
            '&:hover': {
              backgroundColor: theme.palette.background.darkBlue,
              transform: 'scale(1.2)',
              boxShadow: 3
            }
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer open={isOpened} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
}

ChatUtilityDrawerMenu.propTypes = {
  tenantId: PropTypes.string.isRequired
};

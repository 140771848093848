import { Box, Container } from '@material-ui/core';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { getDarkTheme } from './ChatGptTheme';
import ChatUtiltyRouter from './ChatUtilityRouter';
import ChatUtilityHeader from './ChatUtilityHeader';
import ChatUtilityDrawerMenu from './ChatUtilityDrawerMenu';

@inject(
  'chatgptUtility',
  'channelsUtility',
  'chatgptSummaryUtility',
  'chatgptAdminPrompts',
  'language',
  'userPermissions',
  'fileData',
  'fileDataService'
)
@observer
class ChatGptUtilityContainer extends React.Component {
  static propTypes = {
    // classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tenantId: '1' // default tenantId is 1 for aistudytools
      // tabState: '',
    };
  }

  componentDidUpdate() {
    const { userPermissions, history } = this.props;
    const { tabValue } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    const isShowPromptManagementTab =
      permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit');
    const isShowContentManagementTab =
      permissionsList.includes('mcq_can_view') || permissionsList.includes('mcq_can_edit');
    let tabNo;
    const length = 1;
    const defaultTabLength = 2;
    const pathName = history.location.pathname;
    if (pathName === '/aiadmin/aistudytools/promptmgt' && isShowPromptManagementTab) {
      tabNo = defaultTabLength + length;
    } else if (pathName === '/aiadmin/aistudytools/titlesearch' && isShowContentManagementTab) {
      tabNo = defaultTabLength + length;
      if (isShowPromptManagementTab) {
        tabNo += 1;
      }
    }
    if (tabNo && tabValue !== tabNo) {
      this.setState({ tabValue: tabNo });
    }
  }

  handleTenantChange = (tenantId) => {
    this.setState({ tenantId });
  };

  render() {
    // const { classes } = this.props;
    const { tabValue, tenantId } = this.state;
    const theme = getDarkTheme();
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: theme.palette.background.paper
          }}
        >
          {/* Header with tenant dropdown */}
          <ChatUtilityHeader onTenantChange={this.handleTenantChange} />
          <main id="mainContent" role="main" style={{ marginTop: '80px' }}>
            <Box sx={{ flexGrow: 1, paddingTop: 3 }}>
              {/* Drawer Menu */}
              {window.location.pathname !== '/aiadmin' && window.location.pathname !== '/aiadmin/' && (
                <ChatUtilityDrawerMenu tenantId={tenantId} />
              )}
              {/* Router for displaying tenant-specific pages */}
              <Container maxWidth="xl">
                <ChatUtiltyRouter {...this.props} tabValue={tabValue} tenantId={tenantId} />
              </Container>
            </Box>
          </main>
        </Box>
      </ThemeProvider>
    );
  }
}

export default ChatGptUtilityContainer;

import { Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const UserDetailComponent = ({ userDetails, handleToolTipAction }) => {
  const [isChecked, setIschecked] = useState(false);

  const handleCheckBoxChange = () => {
    setIschecked(!isChecked);
    handleToolTipAction(userDetails);
  };

  return (
    <>
      {userDetails
        ? Object.keys(userDetails).map((item) => {
            let poperValue = <></>;
            if (item === 'firstName') {
              poperValue = (
                <Typography gutterBottom>
                  <b>First Name : </b>
                  {userDetails[item]}
                </Typography>
              );
            } else if (item === 'lastName') {
              poperValue = (
                <Typography gutterBottom>
                  <b>Last Name : </b>
                  {userDetails[item]}
                </Typography>
              );
            } else if (item === 'email') {
              poperValue = (
                <Typography gutterBottom>
                  <b>Email : </b>
                  {userDetails[item]}
                </Typography>
              );
            } else if (item === 'userId') {
              poperValue = (
                <Typography gutterBottom>
                  <b>Filter results by this User Id : </b>
                  <Checkbox
                    defaultChecked
                    checked={isChecked}
                    onChange={() => handleCheckBoxChange(userDetails)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="default"
                    sx={{
                      color: 'blue',
                      '&.Mui-checked': {
                        color: 'blue'
                      }
                    }}
                  />
                </Typography>
              );
            }
            return poperValue;
          })
        : 'Loading Data ...'}
    </>
  );
};

export default UserDetailComponent;

UserDetailComponent.propTypes = {
  userDetails: PropTypes.object.isRequired,
  handleToolTipAction: PropTypes.func.isRequired
};

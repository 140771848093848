import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  ThemeProvider,
  TextField,
  Paper
} from '@mui/material';
import axios from 'axios';
import * as FormData from 'form-data';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomLoader from '../../common/components/CustomLoader';
import ColumnConfig from '../../config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import UsersPermissions from '../../../users/models/UsersPermissions';
import AddLLMTestSuite from '../../models/AddLLMTestSuite';
import EffectivenessData from '../../models/StudyToolEffectiveness';
import EffectivenessDialogComponent from './EffectivenessDialogComponent';
import CustomButton from '../../common/components/CustomButton';
import CustomDialog from '../../common/components/CustomDialog';
import CustomHiddenInput from '../../common/components/CustomHiddenInput';
import CustomTable, { getNestedValueFromObj } from '../../common/components/CustomTable';
import { AIADMIN_PRIMARY_COLOR } from '../../ChatGptTheme';

const styles = () => ({
  buttonStyle: {
    width: '150px',
    margin: '10px'
  },
  downloadLink: {
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontSize: '14px'
  },
  downloadBtn: {
    background: 'none',
    border: 'none',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '0',
    font: 'inherit'
  },
  fileUploadMessage: {
    textAlign: 'center',
    padding: '10px',
    fontSize: '14px',
    color: 'green',
    fontWeight: '600'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  cardwrapper: {
    padding: '16px',
    backgroundColor: '#f8f6fa',
    border: `2px solid`,
    borderColor: AIADMIN_PRIMARY_COLOR,
    display: 'flex',
    alignItems: 'center'
  }
});

/* eslint-disable */
const EffectivenessMainComponent = ({
  effectivenessData,
  EffectivenessMetaDataStatus,
  EffectivenessDataStatus,
  classes,
  userPermissions,
  language,
  llmEvaluationTestSuiteData
}) => {
  const history = useHistory();
  const [effectivenessDataValue, setEffectivenessData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [effectivenessRecords, setEffectivenessRecords] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataValue, setDataValue] = useState({});
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const [effectivenessColumnConfig, setEffectivenessColumnConfig] = useState(ColumnConfig.EffectivenessDataGridColumn);
  const [listOfUploadedFile, setListOfUploadedFile] = useState('');
  const [showUploadMessage, setShowUploadMessage] = useState(false);
  const [displayFileName, setDisplayFileName] = useState('');
  const [selectedTestName, setSelectedTestName] = useState([{ testId: '', testSuiteName: '', testFileName: '' }]);
  const [openDialog, setOpenDialog] = useState(false);
  const [testName, setTestName] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [selectedTestSuiteDetails, setSelectedTestSuiteDetails] = useState({
    selectedTestId: '',
    selectedTestName: '',
    selectedTestFileName: ''
  });
  const [tenantVal, setTenantVal] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);

  const fetchData = async (val) => {
    await llmEvaluationTestSuiteData.fetch({ tenantId: val });
    Framework.getEventManager().on(constants.SET_TEST_SUITE_DATA, () => {
      const result = toJS(llmEvaluationTestSuiteData.data);
      setSelectedTestName(result);
    });
    await effectivenessData.fetchMetadata({ tenantId: val });
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_METADATA, () => {
      const result = toJS(effectivenessData.data);
      setEffectivenessData(result);
    });
    setTenantVal(val);
  };

  useEffect(() => {
    let tenantId;
    if (history.location.pathname === '/aiadmin/channels/promptmgt/effectiveness') {
      tenantId = env.TENANT_ID.CHANNELS;
    }
    if (history.location.pathname === '/aiadmin/aistudytools/promptmgt/effectiveness') {
      tenantId = env.TENANT_ID.AI_STUDY_TOOLS;
    }

    fetchData(tenantId);
  }, []);

  const handleUploadFile = (event) => {
    const fileList = event?.target?.files;
    if (fileList?.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0]);
      effectivenessData.initiateFileUpload(formData);
      Framework.getEventManager().on(constants.EFFECTIVENESS_SET_FILE_UPLOAD_RESPONSE, () => {
        const { fileName } = effectivenessData;
        if (fileName) {
          setListOfUploadedFile(fileName);
          setShowUploadMessage(true);
        }
      });
    }
    event.preventDefault();
  };

  const toggleCollapse = (isExpanded) => {
    setIsExpanded(isExpanded);
  };

  const handleBackClick = () => {
    setShowResults(false);
    toggleCollapse(true);
  };

  const handleRunTest = () => {
    if (listOfUploadedFile || !_.isEmpty(selectedTestSuiteDetails)) {
      const payload = {
        fileName: listOfUploadedFile || selectedTestSuiteDetails.selectedTestFileName,
        tenantId: tenantVal,
        testId: selectedTestSuiteDetails.selectedTestId,
        testName: selectedTestSuiteDetails.selectedTestName
      };
      effectivenessData.initiate(payload);
      setListOfUploadedFile('');
      setShowUploadMessage(false);
    }
  };

  const format = (records) => {
    return records.map((record) => ({
      input: record.input,
      output: record.output.toString().replace(/\\r\\n/g, '<br >'),
      prompt: record.prompt,
      requestId: record.requestId,
      feedbacks: record.feedbacks
    }));
  };

  const handleShowResults = (data) => {
    const formattedData = format(data.scores);
    setEffectivenessRecords(formattedData);
    const fileName = data?.metadata?.testName;
    setDisplayFileName(fileName);
    const isGPTResults = formattedData?.some((item) => {
      const feedbackArr = item.feedbacks;
      return feedbackArr.some((obj) => Object.keys(obj).some((val) => val.includes('gpt') && obj[val] !== null));
    });
    if (isGPTResults) {
      setEffectivenessColumnConfig(ColumnConfig.EffectivenessLatestDataGridColumnNew);
    }
    setShowResults(true);
  };

  const onRowClick = (row) => {
    setIsDialogOpen(true);
    setDataValue(row);
    toggleCollapse(false);
  };

  const handleTemplateDownload = () => {
    const csvData = [
      [
        'User Command',
        'Prompt',
        'Textbook Content',
        'Content Filtering',
        'Chat Intent',
        'AI Response',
        'Explain Standalone',
        'Ground Truth',
        'RequestId',
        'ServerDateTime',
        'User Id'
      ]
    ];

    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'sample.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = async (filePath) => {
    const token = utils.getToken();
    const fileName = filePath.split('/').pop();
    if (fileName) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const responseData = await axios
        .post(
          `${env.EVERGREEN_API_BASE_URL}/chatutility/effectiveness/s3download`,
          { fileName },
          {
            headers,
            responseType: 'json'
          }
        )
        .catch((error) => {
          const { response } = error;
          if (response) {
            const { status, data } = response;
            if (status === 500 && data && data.error) {
              const notification = {
                open: true,
                message: data.error.message,
                type: 'error'
              };
              Framework.getStoreRegistry().getStore('notificationState').set(notification);
            }
          } else if (error.message === 'timeout of 60000ms exceeded') {
            const notification = {
              open: true,
              message: error.message,
              type: 'error'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        });
      if (responseData.data.status === 'success' && responseData.data.data) {
        const byteArray = new Uint8Array(responseData.data.data.data);
        const text = new TextDecoder().decode(byteArray);
        const blob = new Blob([text], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        const notification = {
          open: true,
          message: `${fileName} Downloaded Successfully`,
          type: 'success'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  };

  const handleAddClickOpen = (e) => {
    setOpenDialog(true);
    setIsEdit(false);
    setTestName('');
    setUploadedFileName('');
    e.stopPropagation();
  };

  const handleEditClick = (e, val) => {
    setOpenDialog(true);
    setIsEdit(true);
    setTestName(val.testSuiteName);
    setUploadedFileName(val.testFileName);
    e.stopPropagation();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSave = async () => {
    if (testName && !isEdit) {
      await llmEvaluationTestSuiteData.save({
        testSuiteName: testName,
        testFileName: listOfUploadedFile,
        tenantId: tenantVal
      });
      fetchData(tenantVal);
      setSelectedTestName([{ ...selectedTestName, testSuiteName: testName, testFileName: listOfUploadedFile }]);
      setTestName('');
      handleDialogClose();
    } else {
      const testId = selectedTestName.find((item) => item.testFileName === uploadedFileName).testId;
      if (testId) {
        const token = utils.getToken();
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const payload = {
          testSuiteName: testName,
          testFileName: listOfUploadedFile,
          testId,
          uploadedFileName
        };
        const response = await axios.post(
          `${env.EVERGREEN_API_BASE_URL}${constants.UPDATE_TEST_SUITE_DATA_URL}`,
          payload,
          {
            headers
          }
        );
        if (response && response.data && response.data.data) {
          if (response.data.data) {
            const notification = {
              open: true,
              type: 'success',
              message: 'Data Updated successfully'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          } else {
            const notification = {
              open: true,
              type: 'error',
              message: 'Data not Updated'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        }
        fetchData(tenantVal);
        setTestName('');
      } else {
        const notification = {
          open: true,
          type: 'error',
          message: 'Data not Updated'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
      handleDialogClose();
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    const selectedObj = selectedTestName.find((item) => (item.testFileName === value ? item : ''));

    setSelectedTestSuiteDetails({
      selectedTestId: selectedObj.testId,
      selectedTestName: selectedObj.testSuiteName,
      selectedTestFileName: value
    });
  };

  const showTooltipTitle = (colName) => {
    let tooltipVal = <></>;
    switch (colName) {
      case 'Relevance':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Directly on point
          </Typography>
        );
        break;
      case 'Coherence':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Logically flowing
          </Typography>
        );
        break;
      case 'Groundedness':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Factually anchored
          </Typography>
        );
        break;
      case 'Fluency':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Smooth and natural
          </Typography>
        );
        break;
      case 'Similarity':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Closely matched
          </Typography>
        );
        break;
      case 'F1 Score':
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            Balanced accuracy
          </Typography>
        );
        break;
      default:
        tooltipVal = (
          <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
            {colName}
          </Typography>
        );
    }
    return tooltipVal;
  };

  const handleCellClick = (row, columnId) => {
    if (columnId === 'metadata.testName') handleDownload(getNestedValueFromObj(row, 'metadata.s3FilePath'));
    if (columnId === 'showResult') handleShowResults(row);
  };

  const handleGetResults = async () => {
    await effectivenessData.fetchLLMResults({ tenantId: tenantVal, testId: selectedTestSuiteDetails.selectedTestId });
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_DATA, () => {
      const result = toJS(effectivenessData.data);
      setEffectivenessData(result);
    });
  };

  const theme = useTheme();

  // Use theme values to dynamically apply styles
  const selectStyles = {
    // backgroundColor: theme.palette.background.paper, // Use background color from the theme
    borderColor: theme.palette.primary.main, // Conditional border color
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    '&:hover': {
      borderColor: theme.palette.primary.dark // Border color on hover
    },
    '& .MuiInputBase-input': {
      padding: '4px' // Padding inside the input field
    },
    '& .MuiSelect-select': {
      color: theme.palette.primary.main // Use primary color for the select icon
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main // Use primary color for the select icon
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#bfbeba' // Default border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333' // Hover border color
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3d5b7a' // Focused border color
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ marginBottom: '16px' }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                LLM Evaluation
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Card className={classes.cardwrapper}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography variant="body2" align="center">
                Please select a test name to fetch the results.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ flexGrow: 1 }}>
                <FormControl sx={{ width: '100%', maxWidth: '100%' }} size="small" required>
                  <InputLabel id="select-test-name">Select Test Name</InputLabel>
                  <Select
                    labelId="select-test-name"
                    label="Select Test Name"
                    onChange={(e) => handleSelectChange(e)}
                    value={selectedTestSuiteDetails.selectedTestFileName}
                    required
                    sx={selectStyles}
                  >
                    {selectedTestName &&
                      selectedTestName.map((item, index) => (
                        <MenuItem key={index} value={item.testFileName}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Box>{item.testSuiteName}</Box>
                            <Box onClick={(e) => handleEditClick(e, item)}>
                              <EditIcon fontSize="small" />
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                    <MenuItem
                      sx={{
                        pointerEvents: 'none',
                        '&:hover': {
                          backgroundColor: '#ffffff'
                        }
                      }}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Button
                        onClick={(e) => handleAddClickOpen(e)}
                        className={classes.buttonStyle}
                        sx={{
                          pointerEvents: 'auto',
                          '&:hover': {
                            backgroundColor: AIADMIN_PRIMARY_COLOR
                          }
                        }}
                        variant="outlined"
                        size="small"
                        color="primary"
                      >
                        Add New Test
                      </Button>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box>
                <CustomButton
                  label="Run Test"
                  onClick={handleRunTest}
                  style={{ marginRight: '16px' }}
                  disabled={
                    !permissionsList.includes('admin_can_view') ||
                    (permissionsList.includes('admin_can_view') && !selectedTestSuiteDetails.selectedTestName)
                  }
                  toolTiptext={
                    !permissionsList.includes('admin_can_view') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('effectiveness.RUN_TEST')} />
                    )
                  }
                />
                <CustomButton
                  label="Get Results"
                  onClick={handleGetResults}
                  toolTiptext={
                    !permissionsList.includes('admin_can_view') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('effectiveness.GET_RESULTS')} />
                    )
                  }
                  disabled={
                    !permissionsList.includes('admin_can_view') ||
                    (permissionsList.includes('admin_can_view') && !selectedTestSuiteDetails.selectedTestName)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Card>

        <CustomDialog
          open={openDialog}
          size="lg"
          title={isEdit ? constants.EDIT_LLM_TEST_SUITE_TITLE : constants.ADD_LLM_TEST_SUITE_TITLE}
          content={
            <>
              <Grid container>
                <Grid item xs={9}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Test Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={testName}
                    onChange={(e) => setTestName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid sx={{ paddingLeft: '16px' }} item xs={3}>
                  {permissionsList.includes('admin_can_view') && (
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      size="small"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Select file
                      <CustomHiddenInput onChange={(event) => handleUploadFile(event)} />
                    </Button>
                  )}
                </Grid>
              </Grid>

              <div className={classes.downloadLink}>
                <button onClick={handleTemplateDownload} className={classes.downloadBtn}>
                  {constants.DOWNLOAD_TEMPLATE}
                </button>
              </div>

              {isEdit && <div className={classes.fileUploadMessage}>Uploaded FileName: {uploadedFileName}</div>}
              {showUploadMessage && <div className={classes.fileUploadMessage}>File Selected Successfully</div>}
            </>
          }
          onConfirm={handleSave}
          onCancel={handleDialogClose}
          confirmText="Save"
          cancelText="Cancel"
        />
      </Box>

      <LoadingHandler
        loading={EffectivenessMetaDataStatus.isPending || EffectivenessDataStatus.isPending}
        loadingContent={<CustomLoader />}
        content={
          !showResults && (
            <CustomTable
              columns={ColumnConfig.LLMEvaluationTableColumn()}
              data={effectivenessDataValue}
              onCellClick={handleCellClick}
              enableRoundedHeader
            />
          )
        }
      />

      {showResults && (
        <Box sx={{ marginBottom: '16px' }}>
          <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
            <Grid item xs={12} md={isExpanded ? 12 : 5} style={{ margin: 'initial' }}>
              <Paper
                style={{
                  overflowY: 'auto',
                  border: '2px solid',
                  borderColor: theme.palette.background.darkBlue,
                  paddingBottom: '50px',
                  height: '100%'
                }}
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.background.darkBlue,
                    color: theme.palette.text.primary,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '5px 5px 0 0',
                    borderBottom: '2px solid #fff'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleBackClick()}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <Typography color="#fff">{displayFileName}</Typography>
                  </Box>
                  <Box
                    onClick={() => toggleCollapse(!isExpanded)}
                    sx={{
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      mx: '16px'
                    }}
                  >
                    <IconButton color="#fff" title="Click Here to expand table">
                      {isExpanded ? <ArrowBack /> : <ArrowForward />}
                    </IconButton>
                    <Box>{isExpanded ? 'Collapse' : 'Expand'}</Box>
                  </Box>
                </Box>
                {showResults && (
                  <CustomTable
                    columns={effectivenessColumnConfig}
                    data={effectivenessRecords}
                    onRowClick={onRowClick}
                  />
                )}
              </Paper>
            </Grid>

            {!isExpanded && (
              <Grid item xs={12} md={7} style={{ margin: 'initial' }}>
                <Paper
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px solid',
                    borderColor: theme.palette.background.darkBlue,
                    padding: '8px',
                    height: '100%'
                  }}
                >
                  {isDialogOpen && <EffectivenessDialogComponent dataValue={dataValue} />}
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </ThemeProvider>
  );
};

EffectivenessMainComponent.propTypes = {
  effectivenessData: shapes.modelOf(EffectivenessData).isRequired,
  classes: PropTypes.object.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired,
  llmEvaluationTestSuiteData: shapes.modelOf(AddLLMTestSuite).isRequired
};

export default withStyles(styles)(
  inject(
    'effectivenessData',
    'EffectivenessDataStatus',
    'EffectivenessMetaDataStatus',
    'userPermissions',
    'language',
    'llmEvaluationTestSuiteData'
  )(observer(EffectivenessMainComponent))
);

import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Stack from '@mui/material/Stack';
import axios from 'axios';
// import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../../../../common/Loader';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import AutocompleteComponent from '../../common/components/Autocomplete';
import SearchButtonComponent from '../../common/components/SearchButtonComponent';
import McqSearch from '../../models/McqSearch';
import PopularPageModel from '../../models/PopularPage';
import PopularPagesGrid from './PopularPagesGrid';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const styles = (theme) => ({
  groupTitle: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    padding: '20px',
    width: '45%'
  },
  flexDisplay: {
    display: 'flex',
    border: '1px solid'
  },
  flexWidthDisplay: {
    display: 'flex',
    width: '50%'
  },
  itemTypes: {
    display: 'flex',
    margin: '40px auto'
  },
  indexValue: {
    display: 'flex',
    margin: '40px auto',
    fontSize: '20px'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '40%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});
@inject('mcqSearch', 'popularPages', 'PopularPagesService')
@observer
class PopularPageComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    mcqSearch: shapes.modelOf(McqSearch).isRequired,
    popularPages: shapes.modelOf(PopularPageModel).isRequired,
    PopularPagesService: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    this.defaultSearch = {
      bookId: '',
      startDate: '',
      endDate: ''
    };
    this.state = {
      searchFields: {
        intentType: 'explain_feature'
      },
      endDisabled: true,
      popularPageResponse: [],
      loaderStatus: true,
      showDateFields: false,
      showOutline: '1D',
      bookList: [],
      inputValue: '',
      tokenValue: ''
    };
  }

  componentDidMount = () => {
    const { mcqSearch, popularPages } = this.props;
    if (mcqSearch && mcqSearch.data.length > 0) {
      this.setState({
        bookList: mcqSearch.data
      });
    }

    Framework.getEventManager().on(constants.SET_POPULAR_PAGES_DATA, () => {
      const { data } = popularPages;
      this.setState({
        popularPageResponse: data
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mcqSearch } = this.props;
    const { tokenValue } = this.state;
    if (prevState.tokenValue !== tokenValue && mcqSearch && mcqSearch.data.length === 0) {
      this.getBookData();
    }
  };

  getBookData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('mcqSearch').setResponse(response.data);
      this.setState({
        bookList: response.data.data
      });
    }
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
  };

  handleReset = () => {
    this.setState({
      showOutline: '',
      searchFields: {
        ...this.defaultSearch
      },
      popularPageResponse: [],
      inputValue: ''
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDate) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleDateclicker = (event, value) => {
    const { searchFields } = this.state;
    const daysvalue = event.target.innerText;
    let startDate = new Date();
    let endDate = new Date();
    switch (daysvalue) {
      case '1d':
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '3d':
        startDate.setDate(startDate.getDate() - 2);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '1w':
        startDate.setDate(startDate.getDate() - 6);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      default:
    }
    startDate = moment(startDate).format(constants.YYYY_MM_DD_FORMAT);
    endDate = moment(endDate).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, startDate, endDate }
    });
  };

  trendsDataFetch = (value) => {
    const { showDateFields } = this.state;
    if (value === 'custom') {
      this.setState({
        showOutline: value,
        showDateFields: !showDateFields
      });
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        bookId: newValue !== null ? newValue.bookId : ''
      }
    });
  };

  handleSelectChange = (e) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        intentType: e.target.value
      }
    });
  };

  handleSearch = (customPayload) => {
    const { popularPages } = this.props;
    const { searchFields } = this.state;

    const payload = { ...searchFields, ...customPayload };

    let endDateChanged = new Date();
    endDateChanged = moment(endDateChanged).format(constants.YYYY_MM_DD_FORMAT);

    if ((payload && payload.startDate) || (payload && payload.bookId)) {
      if (!payload.endDate && payload.startDate) {
        payload.endDate = endDateChanged;
        this.setState({
          searchFields: { ...searchFields, endDate: endDateChanged }
        });
      }
    } else {
      payload.endDate = endDateChanged;
      payload.startDate = endDateChanged;
      this.setState({
        searchFields: { ...searchFields, startDate: endDateChanged, endDate: endDateChanged }
      });
    }

    popularPages.fetch(payload);
  };

  render() {
    const { classes, PopularPagesService } = this.props;
    const {
      searchFields,
      endDisabled,
      showDateFields,
      showOutline,
      bookList,
      inputValue,
      tokenValue,
      loaderStatus,
      popularPageResponse
    } = this.state;
    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }

    return (
      <>
        <Paper>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9} style={{ p: 5, mt: 5 }}>
                Popular Pages
              </Typography>
            </Box>
            <Grid container direction="row" style={{ width: '100%' }}>
              <Grid item style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1, display: 'flex', p: '0px 33px' }}>
                  <FormControl sx={{ minWidth: '30%', maxWidth: '90%', mr: 5 }} size="small">
                    <AutocompleteComponent
                      options={bookList}
                      onAutoCompleteChange={this.onAutoCompleteChange}
                      onInputChange={this.onInputChange}
                      stateVal={inputValue}
                      errorVal=""
                      name="bookId"
                      label="Book Id"
                    />
                  </FormControl>
                  <Box className={classes.marginBox} sx={{ '& button': { m: 31, p: 12 } }}>
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="small"
                        variant={showOutline === '1D' ? 'outlined' : 'text'}
                        style={{ color: '#0d0c0c8c' }}
                        onClick={(event) => this.handleDateclicker(event, '1D')}
                      >
                        1d
                      </Button>
                      <Button
                        size="small"
                        style={{ color: '#0d0c0c8c' }}
                        variant={showOutline === '3D' ? 'outlined' : 'text'}
                        onClick={(event) => this.handleDateclicker(event, '3D')}
                      >
                        3d
                      </Button>
                      <Button
                        size="small"
                        style={{ color: '#0d0c0c8c' }}
                        variant={showOutline === '1W' ? 'outlined' : 'text'}
                        onClick={(event) => this.handleDateclicker(event, '1W')}
                      >
                        1w
                      </Button>
                      <Button
                        size="small"
                        variant={showOutline === 'custom' ? 'outlined' : 'text'}
                        style={{ color: '#0d0c0c8c' }}
                        onClick={() => this.trendsDataFetch('custom')}
                      >
                        custom
                        <CalendarMonthIcon />
                      </Button>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Filter By
                      </InputLabel>
                      <NativeSelect
                        onChange={(e) => this.handleSelectChange(e)}
                        value={searchFields.intentType}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native'
                        }}
                      >
                        <option value="explain_feature">{constants.EXPLAIN}</option>
                        <option value="summarize_feature">{constants.SUMMARY}</option>
                        <option value="practice_questions">{constants.PRACTICE_QUESTIONS}</option>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <br />
            {showDateFields && (
              <Grid container direction="row">
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          format={constants.MM_DD_YYYY_FORMAT}
                          autoOk
                          size="small"
                          variant="inline"
                          inputVariant="outlined"
                          style={{ minWidth: '32%', marginRight: '6%' }}
                          disableFuture
                          value={searchFields.startDate || null}
                          label="Start Date"
                          onChange={(date) => this.handleDateChange(date, 'startDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          format={constants.MM_DD_YYYY_FORMAT}
                          autoOk
                          variant="inline"
                          size="small"
                          style={{ minWidth: '32%' }}
                          inputVariant="outlined"
                          disableFuture
                          value={searchFields.endDate || null}
                          label="End Date"
                          onChange={(date) => this.handleDateChange(date, 'endDate')}
                          disabled={endDisabled}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Box>
                  <br />
                </Grid>
                <Grid item xs={2} />
              </Grid>
            )}
          </Box>
          <br />
          <SearchButtonComponent classes={classes} handleSearch={this.handleSearch} handleReset={this.handleReset} />
        </Paper>
        <LoadingHandler
          loading={PopularPagesService.isPending}
          isError={
            PopularPagesService.isError &&
            PopularPagesService.error &&
            PopularPagesService.error === 401 &&
            PopularPagesService.error === 500
          }
          content={
            popularPageResponse && popularPageResponse.length > 0 ? (
              <Box>
                <br />
                <PopularPagesGrid gridData={popularPageResponse} />
              </Box>
            ) : (
              <>
                <br />
                <Paper>
                  <br />
                  <Box>
                    <br />
                    <Typography
                      variant="h4"
                      align="center"
                      gutterBottom
                      spacing={9}
                      className={classes.title}
                      style={{ p: 5, mt: 25 }}
                    >
                      {constants.CHAT_FETCH}
                    </Typography>
                  </Box>
                </Paper>
              </>
            )
          }
          loadingContent={<Loader loaderStatus={loaderStatus} />}
          errorContent={<StateErrorDisplay error={PopularPagesService.error} showDetails />}
        />
      </>
    );
  }
}

export default withStyles(styles)(PopularPageComponent);

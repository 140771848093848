import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import CustomButton from './CustomButton';

// Custom styles for the dialog
const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    padding: '20px'
  }
}));

function CustomDialog({
  open,
  onClose,
  title,
  content,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  size = 'sm'
}) {
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={size} fullWidth={size === 'lg'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content} {/* Render the body directly as it can be any valid React node */}
      </DialogContent>
      <DialogActions>
        <CustomButton label={cancelText} onClick={onCancel} />
        <CustomButton label={confirmText} onClick={onConfirm} />
      </DialogActions>
    </StyledDialog>
  );
}

// PropType validation
CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired, // Content can be any valid node (string, JSX, etc.)
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'lg'])
};

// Default props
CustomDialog.defaultProps = {
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  size: 'sm'
};

export default CustomDialog;

import React from 'react';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import * as constants from '../../../../common/constants';
import ExpandableCard from '../DynamicComponent/dataGrid/ExpandableCard';

const styles = () => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  }
});

// /* eslint-disable */
const EffectivenessDialogComponent = (props) => {
  const { dataValue } = props;

  const highlightRow = (item, key) => {
    let styleVal = { bgColor: 'transparent', textColor: '#6a7070' };
    if (item !== null) {
      if (item < 3 || (key === constants.F1_SCORE_NAME && item !== 1)) {
        styleVal = { bgColor: '#F8B0B0', textColor: 'black' };
      }
      if (item >= 3 && item < 5) {
        styleVal = { bgColor: '#FFEB99', textColor: 'black' };
      }
      if (item === 5 || (key === constants.F1_SCORE_NAME && item === 1)) {
        styleVal = { bgColor: '#A8E6A1', textColor: 'black' };
      }
    }
    return styleVal;
  };

  const returnFeedbackData = (data) => (
    <Grid
      container
      spacing={2}
      sx={{
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      {data.map((item) => {
        const filterValue = Object.fromEntries(
          Object.entries(item).filter(([key, value]) => value !== null && key !== null)
        );
        return (
          <>
            {Object.keys(filterValue).map((key) => {
              const backGroundStyle =
                typeof filterValue[key] === 'number'
                  ? { backgroundColor: highlightRow(filterValue[key], key).bgColor }
                  : {};
              return (
                <Grid item xs={12} sm={4}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                      style={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        justifyContent: 'center',
                        color: '#333'
                      }}
                    >
                      {key !== constants.F1_SCORE_NAME && `${key.includes('_') ? key.split('_')[1] : key} `}
                      {key === constants.F1_SCORE_NAME && `${key.split('_').join(' ')} `}
                    </div>
                    <div
                      style={{
                        ...backGroundStyle,
                        textAlign: 'center',
                        padding: '4px 16px'
                      }}
                    >
                      <div>
                        {typeof filterValue[key] === 'number' ? (
                          `  ${filterValue[key]}`
                        ) : (
                          /* eslint-disable */
                          <div dangerouslySetInnerHTML={{ __html: filterValue[key] }} />
                          /* eslint-disable */
                        )}
                      </div>
                    </div>
                  </Box>
                </Grid>
              );
            })}
          </>
        );
      })}
    </Grid>
  );

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Paper elevation={0}>
          <Grid container spacing={0} padding={0}>
            {dataValue && dataValue.feedbacks && (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {returnFeedbackData(dataValue.feedbacks)}
              </div>
            )}
          </Grid>
          <br />
          {dataValue && (
            <>
              <ExpandableCard
                header="User Input/Command"
                body={<Typography variant="body2">{dataValue.input}</Typography>}
              />

              <ExpandableCard
                header={constants.CHAT_PROMPT_CONTENT}
                body={<Typography variant="body2" dangerouslySetInnerHTML={{ __html: dataValue.prompt }} />}
              />

              <ExpandableCard
                header="AI Study Tool Response"
                body={<Typography variant="body2" dangerouslySetInnerHTML={{ __html: dataValue.output }} />}
              />
            </>
          )}
        </Paper>
      </Box>
    </>
  );
};

EffectivenessDialogComponent.defaultProps = {
  dataValue: {}
};

EffectivenessDialogComponent.propTypes = {
  dataValue: PropTypes.object
};

export default withStyles(styles)(observer(EffectivenessDialogComponent));
